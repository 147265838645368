import React from "react";
import {Box, Tooltip} from "@chakra-ui/react";
import Icon from "./Icon";

export default function Help({tip}) {
	if (!tip) return;
	return (
		<Box float={"right"} ml={"auto"} color={"gray.200"}>
			<Tooltip
				color={"white"}
				label={tip}
				shouldWrapChildren={true}
				placement="top-end"
				closeOnClick={false}
				hasArrow
				arrowSize={5}
				borderRadius={5}
				p={2}
				bgColor={"gray.800"}
				fontSize={"sm"}
				boxShadow={["0px 0px 900px 9999px rgba(0,0,0,.6)", "none"]}>
				<Box color={"gray.700"}>
					<Icon iconName={"question"} size="lg" />
				</Box>
			</Tooltip>
		</Box>
	);
}
