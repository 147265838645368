import {Box} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import "../../css/preview.css";
import "../../css/shadowBox.css";

export default function Preview({data, loaded, mobileShowPreview}) {
	const iframe = useRef(null);
	const [frameLoaded, setFrameLoaded] = useState(false);

	const [width, setWidth] = useState("0px");
	const [height, setHeight] = useState("0px");
	const dataRef = useRef(data);

	useEffect(() => {
		dataRef.current = data;
	}, [data]);
	useEffect(() => {
		if (!data) return;
		sendDataToIframe(dataRef.current);
	}, [data]);

	function calcPreviewDim() {
		let result = {w: "", h: ""};
		let padding = 50;
		if (window.innerWidth < 768) padding = 20;

		let maxW = iframe.current.parentElement.offsetWidth - padding * 2;
		let maxH = iframe.current.parentElement.offsetHeight - padding * 2;
		if (data.orientation === "portrait") {
			if (Math.ceil(maxW * 1.414) < Math.ceil(maxH)) {
				//Stößt oben an
				iframe.current.style.width = Math.ceil(maxW) + "px";
				iframe.current.style.height = Math.ceil(maxW * 1.414) + "px";
				setWidth(Math.ceil(maxW) + "px");
				setHeight(Math.ceil(maxW * 1.414) + "px");
			} else {
				iframe.current.style.width = Math.ceil(maxH / 1.414) + "px";
				iframe.current.style.height = Math.ceil(maxH) + "px";
				setWidth(Math.ceil(maxH / 1.414) + "px");
				setHeight(Math.ceil(maxH) + "px");
			}
		} else {
			if (maxH * 1.414 < maxW) {
				//Stößt oben an
				iframe.current.style.width = Math.ceil(maxH * 1.414) + "px";
				iframe.current.style.height = Math.ceil(maxH) + "px";
				setWidth(Math.ceil(maxH * 1.414) + "px");
				setHeight(Math.ceil(maxH) + "px");
			} else {
				//Stößt an den Seiten an
				iframe.current.style.width = Math.ceil(maxW) + "px";
				iframe.current.style.height = Math.ceil(maxW / 1.414) + "px";
				setWidth(Math.ceil(maxW) + "px");
				setHeight(Math.ceil(maxW / 1.414) + "px");
			}
		}
		return result;
	}
	useEffect(() => {
		window.addEventListener("resize", calcPreviewDim);
		calcPreviewDim();
		return () => {
			window.removeEventListener("resize", calcPreviewDim);
		};
	}, [data]);

	useEffect(() => {
		calcPreviewDim();
	}, [mobileShowPreview]);

	useEffect(() => {
		calcPreviewDim();

		function iframeLoaded(event) {
			if (event.data === "loaded") {
				sendDataToIframe(dataRef.current);
				setFrameLoaded(true);
			}
			return true;
		}
		window.addEventListener("message", iframeLoaded);
		return () => {
			window.removeEventListener("message", iframeLoaded);
		};
	}, []);

	function sendDataToIframe(data) {
		iframe.current.contentWindow.postMessage(JSON.stringify(data), "*");
	}

	return (
		<>
			<iframe
				ref={iframe}
				id="previewFrame"
				title="preview"
				src={process.env.REACT_APP_CREATOR_URL}
				sandbox="allow-scripts allow-same-origin"></iframe>
			{data.productSlug !== "message" && frameLoaded && (
				<Box
					pos={"absolute"}
					className="shadowBoxWrapper"
					width={width}
					height={height}>
					<Box className="shadowBox left"></Box>
					<Box className="shadowBox right"></Box>
				</Box>
			)}
		</>
	);
}
