import {Text} from "@chakra-ui/react";
import React from "react";
import Icon from "../elements/Icon";

export default function ClimateBadge({cents}) {
	const euro = (cents / 100) * 0.05;
	const areaPerEuro = 500 / 1432;
	const m2 = euro * areaPerEuro;
	const co2PerM2 = 1000 / 500;
	const kgCo2 = m2 * co2PerM2 * 10;

	return (
		<Text color={"green.500"}>
			<Text mr={2} as={"span"} mb={0}>
				<Icon iconName={"eco1"} color="green.400" iconStyle="regular" />
			</Text>
			Diese Bestellung schützt{" "}
			<strong>{m2.toFixed(1)}m² Tropenwald</strong> und bindet mindestens{" "}
			<strong>
				{kgCo2.toFixed(1)}kg CO<sub>2</sub>
			</strong>
			.
		</Text>
	);
}
