export function getFormData(formElement) {
	const formData = new FormData(formElement);
	const data = {};
	for (const [name, value] of formData.entries()) {
		if (formElement[name].type === "checkbox") {
			data[name] = formElement[name].checked;
		} else if (formElement[name].type === "radio") {
			if (formElement[name].checked) {
				data[name] = value;
			}
		} else {
			data[name] = value;
		}
	}
	return data;
}

export function fillForm(formElement, data) {
	for (const [name, value] of Object.entries(data)) {
		const inputElement = formElement.elements[name];
		if (inputElement) {
			inputElement.value = value;
		}
	}
}
