import {Heading, Text} from "@chakra-ui/react";
import React from "react";

export default function Error() {
	return (
		<>
			<Heading as={"h2"}>Oh nein...</Heading>
			<Text textAlign={"center"} mb={20}>
				Irgendetwas ist schief gelaufen, das tut uns leid! Aktualisiere
				die Seite. Sollte der Fehler weiterhin bestehen, kontaktiere den
				Support unter info@dearuniverse.de.
			</Text>
		</>
	);
}
