import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	Select,
	Input,
	Flex,
	RadioGroup,
	Box,
	Text,
	Radio,
} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {client} from "../..";
import {formatPrice} from "../../helpers";
import {
	CHECKOUT_SHIPPING,
	GET_SHIPPING_METHODES,
	SET_SHIPPING_METHOD,
} from "../../queries";
import {useStore} from "../../Store";
import AddressForm from "../checkout/AddressForm";
import NormalBox from "../elements/NormalBox";
import FormSection from "../productForms/FormSection";
import CheckoutFormWrapper, {useSetRadioValue} from "./CheckoutFormWrapper";
import ControlButtons from "./ControlButtons";
import {fillForm} from "./helper";

export function ShippingForm({onSubmit}) {
	function handleSubmit(event) {
		event.preventDefault();
	}
	const [shippingMethods, setShippingMethods] = useState([]);
	const [shippingMethodId, setShippingMethodId] = useState(null);
	const [loading, setLoading] = useState(false);
	const form = useRef();
	const cart = useStore(state => state.cart);

	useEffect(() => {
		async function getShippingMethodes() {
			let response = await client.query({
				query: GET_SHIPPING_METHODES,
				fetchPolicy: "no-cache",
			});
			console.log(response);
			setShippingMethods(response.data.eligibleShippingMethods);
		}
		getShippingMethodes();
	}, [cart]);

	return (
		<CheckoutFormWrapper
			setLoading={setLoading}
			form={form}
			mutation={
				!numberOfPhysicalAndDigitalLineItems(cart).physical
					? SET_SHIPPING_METHOD
					: CHECKOUT_SHIPPING
			}
			nextUrl={"/checkout/payment"}
			updateBillingOrShipping={"shipping"}>
			<FormSection title={"Lieferadresse"}>
				{!!numberOfPhysicalAndDigitalLineItems(cart).physical && (
					<AddressForm refreshOnCountryChange formRef={form} />
				)}
				{!!numberOfPhysicalAndDigitalLineItems(cart).digital && (
					<Text mb={4} fontSize={"sm"}>
						Digitale Produkte werden an{" "}
						<Text as={"span"} fontWeight={"bold"}>
							{cart.customer?.emailAddress}
						</Text>{" "}
						gesendet.
					</Text>
				)}
			</FormSection>
			<FormSection title={"Versandart"}>
				<FormControl isRequired>
					{cart && (
						<RadioGroup
							name="shippingMethodId"
							defaultValue={
								cart?.shippingLines[0]?.shippingMethod.id
							}>
							{shippingMethods.map((method, i) => {
								return (
									<NormalBox
										key={method}
										justifyContent={"space-between"}
										border={"1px #222222"}
										bgColor={"blue.50"}
										boxShadow={"sm"}
										p={3}
										borderTopRadius={
											i === 0 ? "lg" : "none"
										}
										borderBottomRadius={
											i + 1 === shippingMethods.length
												? "lg"
												: "none"
										}>
										<Radio value={method.id}>
											{method.name}
										</Radio>
										<Text as="span">
											{formatPrice(method.priceWithTax)}
										</Text>
									</NormalBox>
								);
							})}
						</RadioGroup>
					)}
				</FormControl>
			</FormSection>
			<ControlButtons
				prevStepName={"general"}
				nextButtonText={"Weiter zur Zahlung"}
				isLoading={loading}
			/>
		</CheckoutFormWrapper>
	);
}

export function numberOfPhysicalAndDigitalLineItems(cart) {
	let res = {physical: 0, digital: 0};
	if (!cart) return res;
	for (const line of cart.lines) {
		!line.productVariant.customFields.downloadable
			? res.physical++
			: res.digital++;
	}
	return res;
}
