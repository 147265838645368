import {gql} from "@apollo/client";
import create from "zustand";
import {client} from ".";
import {GET_CUSTOMER, GET_USER} from "./queries";

export const useStore = create(set => ({
	cart: undefined,
	setCart: cart => {
		// Sort Cart for related Items
		function sort(array) {
			let prevEl;
			let index = 0;

			for (let el of array) {
				const relatedLineId = el.customFields.relatedLine?.id;
				if (
					relatedLineId &&
					(!prevEl ||
						(prevEl.id !== relatedLineId &&
							prevEl.customFields.relatedLine?.id !==
								relatedLineId))
				) {
					let newIndex = array.indexOf(
						array.find(parent => parent.id === relatedLineId)
					);
					console.log(index, newIndex);
					return sort(array_move(array, index, newIndex));
				}
				prevEl = el;
				index++;
			}
			return array;
		}
		if (!cart) return set(state => ({cart: null}));
		let newCart = JSON.parse(JSON.stringify(cart.data?.result || cart));
		let sortedLines = sort(newCart.lines);
		newCart.lines = sortedLines;
		return set(state => ({cart: newCart}));
	},
	forceUpdate: null,
	setForceUpdate: forceUpdate => set(state => ({forceUpdate: Math.random()})),
	showCart: false,
	setShowCart: showCart => set(state => ({showCart})),
	user: false,
	setUser: user => set(state => ({user})),
	getUser: async () => {
		let res = await client.query({
			query: GET_USER,
			fetchPolicy: "no-cache",
		});
		let user = res.data.activeCustomer;
		return set(state => ({user}));
	},
	showUpsellOverlay: false,
	upsellOverlayProductSlug: null,
	setShowUpsellOverlay: showUpsellOverlay =>
		set(state => ({showUpsellOverlay})),
	decideUpsell: (baseProduct, cart) => {
		set(state => ({
			showUpsellOverlay: true,
			upsellOverlayProductSlug: "zertifikat",
		}));
		return false;
	},
	logout: async () => {
		let res = await client.mutate({
			mutation: gql`
				mutation {
					logout {
						success
					}
				}
			`,
			fetchPolicy: "no-cache",
		});
		return set(state => {
			return {user: null};
		});
	},
}));

function array_move(arr, old_index, new_index) {
	if (new_index >= arr.length) {
		var k = new_index - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	return arr; // for testing
}
