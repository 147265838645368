import {
	Box,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Text,
	Heading,
} from "@chakra-ui/react";

export function FAQSection({faqType}) {
	return (
		<Box maxW={"2xl"} w={"100%"} mx={"auto"}>
			<FAQCategory
				heading={"Fragen zum Funkspruch"}
				faqType={faqType}
				relevantTypes={["message", "necklace", "coupon"]}>
				<Accordion allowToggle>
					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Wie wird der Prozess des Sendens einer Nachricht ins Weltall genau
								durchgeführt?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Tolle Frage! Wir nutzen fünf leistungsstarke Richtantennen, um deine Nachricht ins
							All zu senden. Diese Antennen arbeiten auf einer Frequenz von 2,4 GHz, da diese
							Frequenz die Atmosphäre unserer Erde am besten durchdringt. Du musst nur etwa 30
							Minuten Vorlaufzeit einplanen, damit wir sicherstellen können, dass alle Antennen
							Deine Nachricht in der Sendeliste gespeichert haben. Nach rund 60 Minuten ist
							Deine Nachricht sicher auf ihrem Weg ins All und Du kannst sie in Deinem Dashboard
							verfolgen.
						</AccordionPanel>
					</AccordionItem>

					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Wie wird sichergestellt, dass meine persönlichen Daten und Erinnerungen sicher
								sind?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Deine Sicherheit und Privatsphäre sind uns äußerst wichtig. Nur Du kannst mit dem
							einzigartigen Code auf Deine Nachricht zugreifen. Stell Dir Deinen Code oder Dein
							Schmuckstück als Passwort vor – solange Du es nicht weitergibst, ist alles sicher.
						</AccordionPanel>
					</AccordionItem>

					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Was ist der wissenschaftliche Hintergrund dieses Prozesses?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Das ist ziemlich faszinierend! Deine Nachricht wird als Funkimpuls ausgesendet,
							der aus Photonen besteht. Photonen sind Lichtteilchen, die nie verloren gehen und
							sich immer mit Lichtgeschwindigkeit bewegen. Daher bleibt Deine Nachricht so lange
							erhalten, wie das Universum existiert. Nur wenn das Universum irgendwann
							verschwinden sollte, was Googol Jahren (eine 1 mit 100 Nullen!) dauern kann, würde
							auch Deine Nachricht aufhören zu existieren. Ziemlich cool, oder?
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</FAQCategory>
			<FAQCategory heading={"Fragen zur Ewigkeitskette"} faqType={faqType} relevantTypes={"necklace"}>
				<Accordion allowToggle>
					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Wie aktiviere ich die Ewigkeitskette und wie verfolge ich meine Nachricht?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Wir haben jede unserer Ewigkeitsketten mit einem einzigartigen Code ausgestattet.
							Wenn Du diesen Code mit Deinem Smartphone zum ersten Mal scannst, gelangst Du auf
							eine Seite, auf der Du Deine Nachricht verfassen kannst, oder Deine Kette mit
							einer bestehenden Nachricht verknüpfen kannst.
							<Br></Br>
							Wenn Du die Kette danach erneut scannst, gelangst Du in ein interaktives
							Web-Dashboard. Hier siehst Du zuerst einen Countdown, danach wird die
							zurückgelegte Entfernung Deiner Nachricht angezeigt, und Du kannst spannende
							Meilensteine auf ihrer Reise verfolgen. Schau Dir doch mal unsere{" "}
							<a
								href="https://live.dearuniverse.de/?code=demo"
								target="_blank"
								rel="noreferrer">
								Demo
							</a>{" "}
							an!
						</AccordionPanel>
					</AccordionItem>

					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Wie pflege ich meine Betonkette?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Deine Betonkette ist robust und hält Spritzwasser problemlos aus. Dennoch
							empfehlen wir, sie nicht direkt ins Wasser zu tauchen. Zum Reinigen der Kette
							kannst Du ein feines, trockenes Tuch verwenden. Vermeide aggressive
							Reinigungsmittel, um die Oberfläche nicht zu beschädigen.
						</AccordionPanel>
					</AccordionItem>

					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Meine Kette sieht nicht genau so aus, wie auf den Produktbildern!
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Das ist völlig normal und macht jede unserer Ewigkeitsketten einzigartig.
							Lufteinschlüsse im Beton sind beabsichtigt und verleihen der Kette ihren
							individuellen Charakter. Auch die Verteilung des Blattgoldes erfolgt zufällig,
							sodass jede Kette ein Unikat ist. Solltest Du dennoch unzufrieden sein, kannst Du
							Deine Kette innerhalb von 2 Wochen an uns zurücksenden.
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</FAQCategory>
			<FAQCategory
				heading={"Fragen zu den Postern"}
				faqType={faqType}
				relevantTypes={["starposter", "planetposter", "photoposter"]}>
				<Accordion allowToggle>
					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Wie wird die Sternkarte erstellt?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Wir benutzen die hochpräzisen Angaben aus dem HYG-Katalog, der die Kataloge
							Hipparcos, Yale Bright Star, and Gliese zusammenfasst. In ihm sind etwa 120.000
							Sterne verzeichnet. Mithilfe eines eigenen Projektionsalgorithmus und komplexen
							Berechnungen können wir so den Sternhimmel rekonstruieren (oder vorherbestimmen),
							wie er an einem ganz bestimmten Ort, zu einem ganz bestimmten Zeitpunkt aussah.
						</AccordionPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Wie wird eine Planetenkarte erstellt?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Hierzu benutzen wir historische Daten der Planetenpositionen und berechnen anhand
							der jeweiligen Umlaufgeschwindigkeit die Position zu einem bestimmten Zeitpunkt.
							Die Abstände zwischen den Planetenumlaufbahnen, sowie die Größe der Planeten sind
							jedoch nicht Maßstabsgetreu. Wenn wir eine Darstellung gewählt hätten, welche die
							Realität exakt widerspiegelt, wäre die Sonne nur ein kleiner Punkt in der Mitte
							des Posters, die Planeten könnte man wahrscheinlich gar nicht erkennen. Trotzdem
							der künstlerischen Freiheiten zeigt die Planetenkarte die genaue Position der
							jeweiligen Planeten auf ihrer Umlaufbahn an einem bestimmten Tag!
						</AccordionPanel>
					</AccordionItem>

					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Welche Auflösung hat ein digitales Poster?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Dein digitales Poster bekommt die größtmögliche Auflösung: 9933 x 7016 Pixel. Das
							sind insgesamt 69.689.928 Pixel. Deine Datei kommt per Mail und ist etwa 5 MB
							groß.
							<Br></Br>
							<Br></Br>So kannst Du Dein Poster theoretisch bei 300 ppi/dpi auf einem A1 Papier
							ausdrucken. Gigantisch!
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</FAQCategory>
			<FAQCategory heading={"Allgemeine Fragen"} faqType={faqType} relevantTypes={["all"]}>
				<Accordion allowToggle>
					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Wie lange dauert der Versand?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Ewigkeitsketten versenden wir meistens noch am selben Tag. Nach 1-3 Tagen sollten
							sie bei Dir sein.
							<Br></Br>
							Die Herstellung der Poster dauert etwas länger. Da es sich um ein personalisiertes
							Produkt handelt, kann der Versand bis zu 5 Werktage betragen. Im Regelfall sollte
							das Poster aber schon nach 3 Tagen da sein.
							<Br></Br>
							Digitale Produkte werden wenige Minuten nach Geldeingang an Deine Mailadresse
							versendet.
						</AccordionPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Kann ich meine Lieferung verfolgen?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Klar! Nach der Bestellung kannst Du ein Konto eröffnen und jederzeit den Status
							ansehen. Außerdem bekommst Du eine Trackingmail mit einem Link zur DHL/DPD
							Sendungsverfolgung (für Ewigkeitsketten-Bestellungen).
						</AccordionPanel>
					</AccordionItem>

					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								Kann ich alle Produkte zurückgeben?
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel py={5} ml={3}>
							Deine Ewigkeitskette kannst Du innerhalb von 14 Tagen an uns zurücksenden und
							bekommst den Kaufpreis nach Erhalt erstattet.
							<Br></Br>Lediglich digitale Produkte (digitale Poster, bereits versendete
							Nachrichten) und personalisierte Produkte (Sternkarten und andere Poster) können
							nicht umgetauscht oder erstattet werden.
							<Br />
							Das liegt daran, das wir diese Ware nicht erneut verkaufen können. Solltest Du
							jedoch unzufrieden mit Deinem Produkt sein, schreib uns einfach eine Nachricht und
							wir finden sicher eine zufriedenstellende Lösung!
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</FAQCategory>
			<Box>
				<Box flex="1" textAlign="left" fontWeight={"bold"}>
					Du hast noch weitere Fragen?
				</Box>
				Kein Problem! Du kannst uns jederzeit per E-Mail kontaktieren:{" "}
				<a href="mailto:info@dearuniverse.de">info@dearuniverse.de</a>. Wir helfen Dir gerne weiter!
			</Box>
		</Box>
	);
}

function FAQCategory({heading, children, faqType, relevantTypes}) {
	console.log(relevantTypes, faqType);
	if (relevantTypes[0] !== "all" && faqType !== "all" && relevantTypes.indexOf(faqType) === -1) return "";

	return (
		<Box mb={10}>
			<Heading fontSize={"lg"} as={"h3"}>
				{heading}
			</Heading>
			{children}
		</Box>
	);
}
function Br() {
	return <Box w={"100%"} h={"10px"}></Box>;
}
