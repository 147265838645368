import {Box, CircularProgress, Flex, Text} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Page from "../layout/Page";
import {client} from "../..";
import {VERIFY} from "../../queries";
import {useStore} from "../../Store";
import Icon from "../elements/Icon";

export default function Verify() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [token, setToken] = useState(null);
	const [error, setError] = useState(null);
	const getUser = useStore(state => state.getUser);
	const user = useStore(state => state.user);
	const navigate = useNavigate();

	useEffect(() => {
		let token = searchParams.get("token");
		setToken(token);
	}, [searchParams]);

	useEffect(() => {
		if (user) {
			navigate("/user");
		}
	}, [user]);

	useEffect(() => {
		// Verify Token
		async function verify() {
			let success;
			let res = await client.mutate({
				mutation: VERIFY,
				variables: {token},
			});
			console.log(res, token);
			if (res.data.verifyCustomerAccount.id) {
				getUser();
			} else {
				getUser();
				setError(
					res.data.verifyCustomerAccount.message ||
						"Ein Fehler ist aufgetreten, versuche die Registrierung erneut abzuschließen."
				);
			}
		}
		if (token) verify();
	}, [token]);

	return (
		<Page heading={"E-Mail bestätigen"} footer>
			<Flex justifyContent={"center"} textAlign={"center"}>
				<Flex
					justifyContent={"center"}
					w={"xl"}
					p={5}
					bgColor={"white"}
					boxShadow={"md"}
					borderRadius={"sm"}
					flexDir={"column"}
					alignItems={"center"}>
					<Box mb={5}>
						<Icon iconName={"letter"} size={"3xl"} />
					</Box>
					{token ? (
						<Box>
							{error || <CircularProgress isIndeterminate />}
						</Box>
					) : (
						<Text>
							<strong>
								Wir haben Dir eine Mail mit einem Link zur
								Bestätigung gesendet.
							</strong>
							<br></br> Schaue auch im Spam-Ordner nach. Nachdem
							Du Deine Registrierung mit einem Klick auf den Link
							bestätigt hast, kannst Du diese Seite schließen.
						</Text>
					)}
				</Flex>
			</Flex>
		</Page>
	);
}
