import {
	Box,
	Button,
	Flex,
	Image,
	Stack,
	Text,
	useColorModeValue as mode,
} from "@chakra-ui/react";
import React from "react";
import {useNavigate} from "react-router-dom";
import {formatPrice} from "../../helpers";
import Loading from "../feedback/Loading";
import Seperator from "../layout/Seperator";
import CartProductGrid from "./CartProductGrid";
import ClimateBadge from "../checkout/ClimateBadge";
import {bgGradientSoftGreen} from "../../theme/foundations/colors";
import {usePartnerStore} from "../../inStore/PartnerStoreProvider";
import CartQR from "../../inStore/CartQR";
import GiftCardInput from "../elements/GiftCardInput";
import GiftCardSummary from "../elements/GiftCardSummary";
import ShippingEstimator from "./ShippingEstimator";
import {numberOfPhysicalAndDigitalLineItems} from "../checkoutFlow/ShippingForm";
import {track} from "../tracking/Tracking";
import TrustBadges from "../elements/TrustBadges";
import Icon from "../elements/Icon";

export const OrderSummaryItem = ({label, value, children}) => {
	return (
		<Flex justify="space-between" fontSize="sm" alignItems={"center"}>
			<Text
				mr={"auto"}
				fontWeight="medium"
				color={mode("gray.600", "gray.400")}>
				{label}
			</Text>
			{value ? <Text fontWeight="medium">{value}</Text> : children}
		</Flex>
	);
};

export const CartOrderSummary = ({inCheckout, cart, confirmationPage}) => {
	const navigate = useNavigate();
	const partnerStore = usePartnerStore();

	if (!cart) {
		return <Loading />;
	}

	function getTax() {
		if (!cart || !cart.taxSummary) return 0;
		let total = 0;
		for (const summaryItem of cart.taxSummary) {
			total += summaryItem.taxTotal;
		}
		return total;
	}

	const total = cart?.totalWithTaxAfterGiftCard;
	const tax = getTax();

	return (
		<Box
			p={inCheckout ? "none" : [5, null, "10"]}
			minW={"min(420px , 100%)"}
			bgColor={inCheckout ? "none" : "white"}
			boxShadow={inCheckout ? "none" : "md"}
			height={"max-content"}
			pt={inCheckout ? "none" : "5"}>
			{partnerStore.inStoreDevice ? (
				<CartQR />
			) : (
				<>
					<Box width={"100%"} border={"1px #eeeeee"}>
						<Stack>
							{inCheckout && (
								<Box>
									<CartProductGrid cart={cart} minimal />
									{!confirmationPage && (
										<>
											<GiftCardInput />
											<Seperator />
										</>
									)}
								</Box>
							)}
							<Stack spacing="2">
								<OrderSummaryItem
									label="Summe"
									value={formatPrice(getTotalFromLines(cart))}
								/>
								<OrderSummaryItem label="Versand">
									{cart?.shippingLines.length > 0 ||
									numberOfPhysicalAndDigitalLineItems(cart)
										.physical === 0
										? formatPrice(cart?.shippingWithTax)
										: "wird an der Kasse berechnet"}
								</OrderSummaryItem>
								{!confirmationPage && (
									<ShippingEstimator cart={cart} />
								)}
								<GiftCardSummary
									giftcards={cart.giftCardsApplied}
									coupons={cart.couponCodes}
									cart={cart}
								/>
								{/* <OrderSummaryItem label="Coupon Code">
							<Text textDecor="underline">
								Gutschein hinzufügen
							</Text>
						</OrderSummaryItem> */}
								<Seperator />
								{inCheckout ? (
									<>
										<ClimateBadge cents={total} />
										<Seperator />
									</>
								) : (
									""
								)}
								<Flex justify="space-between">
									<Text fontSize="lg" fontWeight="semibold">
										Gesamt
										<br />
										<Text
											as={"span"}
											fontWeight={"light"}
											fontSize={"small"}>
											inkl. {formatPrice(tax)} MwSt.
										</Text>
									</Text>
									<Text fontSize="xl" fontWeight="extrabold">
										{formatPrice(total)}
									</Text>
								</Flex>
							</Stack>
						</Stack>
					</Box>
					{!inCheckout && (
						<>
							<Button
								onClick={() => {
									track("begin_checkout", cart);
									navigate("/checkout/general");
								}}
								bg={bgGradientSoftGreen}
								color={"green.800"}
								size="lg"
								className="grow-on-hover"
								fontSize="lg"
								mt={5}
								w={"100%"}
								rightIcon={<Icon iconName={"arrowforward"} />}>
								Jetzt sicher zur Kasse
							</Button>
							<Flex justifyContent={"center"} mt={7} mb={10}>
								<Image
									maxH={"30px"}
									src="/images/trustbadge-payment.jpg"
								/>
							</Flex>
						</>
					)}
					{inCheckout && (
						<Box display={["none", null, "block"]}>
							<TrustBadges />
						</Box>
					)}
				</>
			)}
		</Box>
	);
};

export function getTotalFromLines(cart) {
	return cart.lines.reduce((total, line) => total + line.linePriceWithTax, 0);
}
