import {Box, Flex, Heading, List, ListItem} from "@chakra-ui/react";
import React from "react";
import {Link, Route, Routes} from "react-router-dom";
import {useStore} from "../../Store";
import Page from "../layout/Page";
import UserMenu from "../user/UserMenu";
import UserOrderOverview from "../user/UserOrderOverview";
import {UserProfile} from "../user/UserProfile";

export default function User() {
	const user = useStore(state => state.user);

	return (
		<Page heading={"Dein Bereich"} footer>
			<Heading
				size={"lg"}
				fontFamily={"fancy"}
				textAlign={"center"}
				mb={5}>
				Hallo {user.firstName}!
			</Heading>
			<Flex
				bgColor={"white"}
				borderRadius={"sm"}
				flex={"1"}
				flexDir={["column", null, "row"]}
				w={"100%"}>
				<UserMenu />
				<Box p={6} w={"100%"}>
					<Routes>
						<Route
							path=""
							element={<UserProfile user={user} />}></Route>
						<Route
							path="orders"
							element={<UserOrderOverview />}></Route>
					</Routes>
				</Box>
			</Flex>
		</Page>
	);
}
