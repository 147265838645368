import {Box, Flex, Image} from "@chakra-ui/react";
import React from "react";
import {Link} from "react-router-dom";
import {usePartnerStore} from "../../inStore/PartnerStoreProvider";

export default function Logo({
	maxW = "min(240px , 100%)",
	horizontal,
	h = "100%",
}) {
	return (
		<Link to={"/"} title="Zurück zur Startseite">
			<Box width={horizontal ? "auto" : "100%"} height="100%">
				<Image
					alt="DearUniverse Logo"
					width="100%"
					height={h}
					objectFit="contain"
					src={
						!horizontal
							? "/images/logovertical.svg"
							: "/images/logohorizontal.svg"
					}
				/>
			</Box>
		</Link>
	);
}
