import {
	Button,
	Flex,
	HStack,
	Input,
	useNumberInput,
	Text,
	Box,
	useToast,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {mutateAndGetNewCart} from "../../helpers";
import {CHANGE_QUANTITY} from "../../queries";
import {useStore} from "../../Store";
import ConfirmableButton from "../feedback/ConfirmableButton";
import {deleteFile} from "../productForms/FileUpload";
import {isSomethingRelatedToLine} from "./AddCertBtn";
import Icon from "../elements/Icon";

export default function QuantityChooser({line}) {
	const [loading, setLoading] = useState(false);
	const [quantity, setQuantity] = useState(line.quantity);
	const cart = useStore(state => state.cart);
	const setCart = useStore(store => store.setCart);
	const toast = useToast();

	async function changeQuantity() {
		setLoading(true);
		try {
			const newCart = await mutateAndGetNewCart(CHANGE_QUANTITY, {
				orderLineId: line.id,
				quantity,
			});
			if (newCart.__typename === "InsufficientStockError") {
				toast({
					title: "Leider gibt es zu wenig Ware. Versuche es später noch einmal.",
					status: "warning",
					duration: 5000,
					isClosable: true,
				});
				setQuantity(line.quantity);
				setLoading(false);
				return;
			}
			setCart(newCart);
		} catch (error) {
			console.error(error);
			setQuantity(line.quantity);
		}
		setLoading(false);
	}

	function changeQuantityBy(amount) {
		setQuantity(quantity + amount);
	}

	useEffect(() => {
		if (quantity === "" || quantity === line.quantity) return;
		changeQuantity();
	}, [quantity]);

	const onlyOnePossibleSlugs = ["message", "giftcard"];

	const onlyOnePossible =
		onlyOnePossibleSlugs.indexOf(line.productVariant.product.slug) !== -1 ||
		line.productVariant.customFields.downloadable;

	return (
		<Flex
			gap={0}
			wrap={"nowrap"}
			border={"1px solid"}
			borderColor={"gray.200"}
			bgColor={"white"}
			borderRadius={"sm"}
			width={"max-content"}>
			<ConfirmableButton
				title={"Produkt entfernen"}
				body={
					"Willst Du dieses Produkt aus dem Warenkorb entfernen? Deine Personalisierungen gehen verloren."
				}
				confirmText={"Entfernen"}
				onConfirm={() => {
					let uploadedFile = JSON.parse(
						line.customFields.userInputs
					)?.message_fileName;
					if (uploadedFile) deleteFile(uploadedFile);
					changeQuantityBy(-1);
				}}
				enabled={
					quantity === 1 && !isSomethingRelatedToLine(cart, line.id)
				}>
				<Button
					variant={"ghost"}
					color={"gray.800"}
					width={"auto"}
					isDisabled={
						isSomethingRelatedToLine(cart, line.id) &&
						quantity === 1
					}
					title={
						isSomethingRelatedToLine(cart, line.id)
							? "Um diesen Artikel zu entfernen, entferne zunächst die ergänzenden Artikel darunter"
							: "Löschen"
					}>
					<Icon icon={quantity === 1 ? "trash" : "minus"} />
				</Button>
			</ConfirmableButton>
			<Input
				display={onlyOnePossible ? "none" : "block"}
				border={"none"}
				type={"number"}
				value={quantity}
				opacity={loading ? "0.5" : "1"}
				isDisabled={onlyOnePossible}
				width={"60px"}
				color={"gray.800"}
				height={"100%"}
				textAlign={"center"}
				onChange={e => {
					let val = e.target.value;
					setQuantity(!val ? "" : Number(e.target.value));
				}}
			/>
			<Button
				display={onlyOnePossible ? "none" : "block"}
				color={"gray.800"}
				variant={"ghost"}
				width={"auto"}
				onClick={() => {
					changeQuantityBy(1);
				}}
				isDisabled={onlyOnePossible}>
				<Icon icon={"plus"} />
			</Button>
		</Flex>
	);
}
