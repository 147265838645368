import { CircularProgress, Flex } from '@chakra-ui/react'
import React from 'react'

export default function Loading() {
  return (
    <Flex w={"100%"} alignItems={"center"} justifyContent={"center"}>
      <CircularProgress color={"black"} isIndeterminate thickness={"4px"}/>
    </Flex>
  )
}
