import "./App.css";
import Header from "./components/layout/Header";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import Home from "./components/pages/Home";
import Cart from "./components/pages/Cart";
import Checkout from "./components/pages/Checkout";
import {Box, Fade, Flex} from "@chakra-ui/react";
import Product from "./components/pages/Product";
import {useStore} from "./Store";
import {useEffect, useRef} from "react";
import {GET_CART} from "./queries";
import "./css/forms.css";
import {ProtectedRoute} from "./components/auth/ProtectedRoute";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import Verify from "./components/pages/Verify";
import User from "./components/pages/User";
import {ShippingForm} from "./components/checkoutFlow/ShippingForm";
import GeneralForm from "./components/checkoutFlow/GeneralForm";
import PaymentForm from "./components/checkoutFlow/PaymentForm";
import OrderConfirmation from "./components/checkoutFlow/OrderConfirmation";
import SetInStoreDeviceFlag from "./inStore/SetInStoreDeviceFlag";
import PasswordReset from "./components/pages/PasswordReset";
import Loading from "./components/feedback/Loading";
import {useQuery} from "@apollo/client";
import AllProducts from "./components/pages/AllProducts";
import Configurator from "./components/pages/Configurator";
import ScrollToTop from "./components/elements/ScrollToTop";

export const GLOBAL_BORDER_WIDTH = "1px";
export const BORDER = `${GLOBAL_BORDER_WIDTH} solid white`;
export const BORDER_BLACK = `${GLOBAL_BORDER_WIDTH} solid #bbbbbb`;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
function App() {
	const setCart = useStore(state => state.setCart);
	const getUser = useStore(state => state.getUser);

	const {loading, error, data} = useQuery(GET_CART, {
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		getUser();
	}, []);

	useEffect(() => {
		if (data) setCart(data.result);
	}, [data]);

	//if (loading) return <Loading />;
	if (error)
		return (
			<p>
				Leider ist der Shop kurzzeitig nicht verfügbar. Versuche es in
				ein paar Minuten noch einmal oder wende Dich an den Support
				unter info@dearuniverse.de
			</p>
		);

	return (
		<Router>
			<ScrollToTop />

			<Flex flexDir={"column"}>
				<Routes>
					<Route path="/cart" element={<Cart />}></Route>
					<Route path="/cart/:orderCode" element={<Cart />}></Route>
					<Route
						path="/user/*"
						element={
							<ProtectedRoute>
								<User />
							</ProtectedRoute>
						}></Route>
					<Route path="/login" element={<Login />}></Route>
					<Route path="/register" element={<Register />}></Route>
					<Route path="/verify" element={<Verify />}></Route>
					<Route
						path="/password-reset/:token"
						element={<PasswordReset />}></Route>
					<Route
						path="/password-reset"
						element={<PasswordReset />}></Route>
					<Route path="checkout" element={<Checkout />}>
						<Route
							path="/checkout"
							element={<Navigate to={"/checkout/general"} />}
						/>
						<Route path="general" element={<GeneralForm />} />
						<Route path="shipping" element={<ShippingForm />} />

						<Route path="payment/" element={<PaymentForm />} />
						<Route
							path="payment/:redirectOrderState"
							element={<PaymentForm />}
						/>
						<Route
							path="confirmation/:orderCode"
							element={<OrderConfirmation />}
						/>
					</Route>
					<Route path="/product/:slug/" element={<Product />}></Route>
					<Route
						path="/product/:slug/:relatedLineId/"
						element={<Product />}></Route>
					{/* <Route
							path="/instoresetup"
							element={<SetInStoreDeviceFlag />}></Route> */}
					<Route path="/products/" element={<AllProducts />}></Route>

					<Route
						path="/product/configurator/:slug/"
						element={<Configurator />}></Route>
					<Route
						path="/"
						element={
							<Flex flexDir={"column"} flex={1} width={"100%"}>
								<Home />
							</Flex>
						}></Route>
				</Routes>
				{/* <Fade in={showCart} style={{zIndex: 10}}>
							<CartOverlay />
						</Fade> */}
				{/* <Slide
							direction={"right"}
							in={showCart}
							style={{zIndex: 11, maxWidth: "500px"}}>
							<CartSidebar />
						</Slide> */}
				{/* <Fade in={showUpsellOverlay} style={{zIndex: 10}}>
							<UpsellOverlay />
						</Fade> */}
			</Flex>
		</Router>
	);
}

export default App;
