import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Text,
} from "@chakra-ui/react";
import React from "react";
import QRCode from "react-qr-code";
import Seperator from "../components/layout/Seperator";
import FormSection from "../components/productForms/FormSection";
import {useStore} from "../Store";

export default function CartQR({}) {
	const cart = useStore(state => state.cart);

	if (!cart) return null;

	return (
		<Flex
			maxW={"500px"}
			flexDir={"column"}
			justifyContent={"center"}
			alignItems={"center"}
			gap={3}
			textAlign={"center"}>
			<Heading as={"h2"} size={"md"}>
				Bezahlen in 2 einfachen Schritten:
			</Heading>
			<Seperator />

			<Text>
				<strong>1.</strong>
				<br></br>
				Scanne den Code oder sende Dir einen Link zum Bezahlen nach
				Hause. So bleiben keine Zahlungsdaten auf diesem Gerät.
			</Text>
			<Seperator />
			<Flex
				width={"100%"}
				gap={3}
				justifyContent={"center"}
				alignItems={"center"}>
				<Box maxW={"120px"}>
					<QRCode
						value={"https://shop.dearuniverse.de/cart/" + cart.code}
						size={256}
						style={{
							height: "auto",
							maxWidth: "100%",
							width: "100%",
						}}
						viewBox={`0 0 256 256`}
					/>
				</Box>
				<Box fontSize={"xs"}>oder</Box>
				<Flex gap={3} as={"form"} flexDir={"column"} flex={"1"}>
					<FormControl isRequired>
						<FormLabel>E-Mail</FormLabel>
						<Input type={"email"}></Input>
					</FormControl>
					<Button>Sende mir den Link zu</Button>
				</Flex>
			</Flex>
			<Seperator />
			<Text>
				<strong>2.</strong>
				<br></br>
				Sobald Dein Warenkorb auf dem Handy ist, oder Du den Link per
				Mail versendet hast, kannst Du den Warenkorb von diesem iPad
				lösen, damit niemand anderes darauf zugreifen kann.
			</Text>
			<Seperator />
			<Button>Warenkorb zurücksetzen</Button>
		</Flex>
	);
}
