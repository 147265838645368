import {useQuery} from "@apollo/client";
import {
	Checkbox,
	CheckboxGroup,
	Flex,
	Input,
	Text,
	useCheckbox,
} from "@chakra-ui/react";
import React from "react";
import {useRef} from "react";
import {useEffect} from "react";
import {useState} from "react";
import {formatPrice} from "../../helpers";
import Loading from "../feedback/Loading";
import {GET_PRODUCTS} from "../../queries";

export default function GiftCardConfigurator({changeUserInputs, userInputs}) {
	const {loading, error, data} = useQuery(GET_PRODUCTS);
	const formRef = useRef(null);
	const [selectedVariants, setSelectedVariants] = useState([]);

	useEffect(() => {
		changeUserInputs("value", getTotal());
	}, [selectedVariants]);

	function getTotal() {
		let total = 0;
		selectedVariants.forEach(variant => {
			total += variant.priceWithTax;
		});
		return Math.ceil(total / 100) * 100;
	}

	if (!data) return <Loading />;

	return (
		<Flex flexDir={"column"} ref={formRef} as={"form"}>
			<CheckboxGroup value={selectedVariants.map(v => v.sku)}>
				{data.products.items.map(item => {
					if (item.slug === "giftcard") return "";
					return (
						<Flex flexDir={"column"}>
							<Text fontWeight={"bold"}>{item.name}</Text>
							{item.variants.map(variant => {
								const {sku} = variant;
								const isChecked =
									selectedVariants.findIndex(
										v => v.sku === sku
									) !== -1;
								return (
									<Flex
										pl={2}
										borderBottom={"1px solid #eee"}>
										<Checkbox
											value={sku}
											checked={isChecked}
											onChange={e => {
												if (e.target.checked) {
													setSelectedVariants([
														...selectedVariants,
														variant,
													]);
												} else {
													setSelectedVariants(
														selectedVariants.filter(
															v => v.sku !== sku
														)
													);
												}
											}}>
											<Flex flex={1} width={"100%"}>
												<Text fontSize={"md"}>
													{variant.name}
													{variant.sku === "msg" &&
														" (einmalig)"}
												</Text>
											</Flex>
										</Checkbox>
										<Text ml={"auto"}>
											{formatPrice(variant.priceWithTax)}
										</Text>
									</Flex>
								);
							})}
						</Flex>
					);
				})}
			</CheckboxGroup>
		</Flex>
	);
}
