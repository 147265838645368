import {
	Box,
	Heading,
	Text,
	FormControl,
	FormLabel,
	Input,
	Button,
	Flex,
} from "@chakra-ui/react";
import {useState} from "react";

function UserInfo({user}) {
	return (
		<Box>
			<Heading as="h2" size="lg">
				User Information
			</Heading>
			<Box mt={4}>
				<Text>
					Name: {user.firstName} {user.lastName}
				</Text>
			</Box>
			<Box mt={2}>
				<Text>Email: {user.emailAddress}</Text>
			</Box>
			<Box mt={2}>
				<Text>Telefon: {user.telephoneNumber || "-"}</Text>
			</Box>
			{user.addresses.map(address => (
				<Box mt={2}>
					<Text>
						{address.street}, {address.city}, {address.country}
					</Text>
				</Box>
			))}
		</Box>
	);
}

function EditUserForm({user, onSubmit}) {
	const [name, setName] = useState(user.firstName);
	const [email, setEmail] = useState(user.emailAddress);
	const [addresses, setAddresses] = useState(user.addresses);

	return (
		<form onSubmit={onSubmit}>
			<FormControl>
				<FormLabel htmlFor="name">Name</FormLabel>
				<Input
					type="text"
					id="name"
					value={name}
					onChange={e => setName(e.target.value)}
				/>
			</FormControl>
			<FormControl mt={4}>
				<FormLabel htmlFor="email">Email</FormLabel>
				<Input
					type="email"
					id="email"
					value={email}
					onChange={e => setEmail(e.target.value)}
				/>
			</FormControl>
			{addresses.map((address, index) => (
				<Box mt={4} key={index}>
					<FormControl>
						<FormLabel htmlFor={`street-${index}`}>
							Street
						</FormLabel>
						<Input
							type="text"
							id={`street-${index}`}
							value={address.street}
							onChange={e =>
								setAddresses(prevAddresses =>
									prevAddresses.map((a, i) =>
										i === index
											? {...a, street: e.target.value}
											: a
									)
								)
							}
						/>
					</FormControl>
					<FormControl mt={4}>
						<FormLabel htmlFor={`city-${index}`}>City</FormLabel>
						<Input
							type="text"
							id={`city-${index}`}
							value={address.city}
							onChange={e =>
								setAddresses(prevAddresses =>
									prevAddresses.map((a, i) =>
										i === index
											? {...a, city: e.target.value}
											: a
									)
								)
							}
						/>
					</FormControl>
					<FormControl mt={4}>
						<FormLabel htmlFor={`country-${index}`}>
							Country
						</FormLabel>
						<Input
							type="text"
							id={`country-${index}`}
							value={address.country}
							onChange={e =>
								setAddresses(prevAddresses =>
									prevAddresses.map((a, i) =>
										i === index
											? {...a, country: e.target.value}
											: a
									)
								)
							}
						/>
					</FormControl>
				</Box>
			))}
			<Button mt={4} type="submit">
				Save Changes
			</Button>
		</form>
	);
}

export function UserProfile({user, onSubmit}) {
	return (
		<Flex gap={5} flexDir={"column"}>
			<UserInfo user={user} />
			<Text>
				Um Deine Daten zu ändern, setze dich bitte mit dem
				DearUniverse-Team in Verbindung, wir freuen uns, Dir zu helfen!
			</Text>
			{/* <EditUserForm user={user} onSubmit={onSubmit} /> */}
		</Flex>
	);
}
