import {Flex, Image} from "@chakra-ui/react";
import React from "react";
import {NFCFeaturesCheckout} from "../Explainer/NFC-Features";
import {useStore} from "../../Store";
import Seperator from "../layout/Seperator";

export default function TrustBadges() {
	const cart = useStore(store => store.cart);
	if (!cart) return "";

	const hasNFCLine = !!cart.lines.find(
		line => line.productVariant.product.slug === "necklace"
	);

	return (
		<>
			{hasNFCLine && (
				<>
					<NFCFeaturesCheckout />
				</>
			)}
			<Seperator />
			<Flex justifyContent={"center"} mt={3} mb={10}>
				<Image maxH={"30px"} src="/images/trustbadge-payment.jpg" />
			</Flex>
		</>
	);
}
