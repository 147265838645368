import colors from "../foundations/colors";

const Text = {
	baseStyle: {
		color: colors.blue[900],
		lineHeight: "1.7em",
		mb: "0.5em",
	},
};

export default Text;
