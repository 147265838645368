import {Box, Flex, Image} from "@chakra-ui/react";
import SVG from "react-inlinesvg";

const Icon = ({
	iconName,
	icon,
	iconStyle = "light",
	size = "s",
	color = "currentColor",
	rotation = "0deg",
	children,
}) => {
	const iconSize = {
		s: "12px",
		m: "16px",
		l: "48px",
		xl: "64px",
		inherit: "inherit",
	};
	const iconFileName = iconName || icon || "minus";

	const iconNameFormatted = iconFileName.replace(" ", "").toLowerCase();
	const svgURL =
		`/images/icons/shopicons_${iconStyle}_${iconNameFormatted}.svg`.toLowerCase();

	if (!children) {
		return (
			<Flex
				as={"span"}
				h={"100%"}
				align="center"
				fontSize={size}
				display="inline-flex"
				fill={color}
				width="1.2em">
				<SVG src={svgURL} width="100%" height="100%" />
			</Flex>
		);
	}

	return (
		<>
			<Flex as="span" align="center" gap={2}>
				<Box
					as="span"
					fontSize={size}
					display="inline-flex"
					fill={color}
					width="1.2em"
					height="1.2em">
					<SVG src={svgURL} width="100%" height="100%" />
				</Box>
				{children}
			</Flex>
		</>
	);
};

export default Icon;
