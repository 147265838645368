import {
	AlertDialog as ChakraAlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from "@chakra-ui/react";
import React from "react";

export default function AlertDialog({
	title,
	body,
	confirmText,
	cancelText,
	isOpen,
	onClose,
	onConfirm,
	onCancel,
	confirmColorScheme = "red",
}) {
	const cancelRef = React.useRef();

	return (
		<ChakraAlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{title}
					</AlertDialogHeader>

					<AlertDialogBody>{body}</AlertDialogBody>

					<AlertDialogFooter w={"100%"}>
						<Button
							w={"50%"}
							ref={cancelRef}
							colorScheme="gray"
							variant={"ghost"}
							onClick={() => {
								onCancel?.();
								onClose?.();
							}}>
							{cancelText || "Abbrechen"}
						</Button>
						<Button
							w={"50%"}
							colorScheme={confirmColorScheme}
							variant={"solid"}
							onClick={onConfirm}
							onC
							ml={3}>
							{confirmText}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</ChakraAlertDialog>
	);
}
