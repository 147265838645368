import {Box, Button, Flex, Heading, Text} from "@chakra-ui/react";
import React from "react";
import {Link} from "react-router-dom";
import NormalBox from "../elements/NormalBox";
import Icon from "../elements/Icon";

export default function AddressInfoBox({cart, type}) {
	if (!cart) return "";

	const address =
		type === "shipping" ? cart.shippingAddress : cart.billingAddress;

	console.log(address);

	return (
		<NormalBox flexDir={"column"} mb={3} bgColor={"gray.50"}>
			<Flex alignItems={"center"}>
				<Box flex={1}>
					<Heading as={"h3"} size={"sm"}>
						Deine{" "}
						{type === "shipping"
							? "Lieferadresse"
							: "Rechnungsadresse"}
						:
					</Heading>
					<Text fontSize={"sm"}>
						{!address.streetLine1 ? (
							cart.customer.emailAddress
						) : (
							<>
								{address.fullName}, {address.streetLine1}
								{!!address.steetLine2 &&
									", " + address.steetLine2}
								, {address.postalCode} {address.city}
							</>
						)}
					</Text>
				</Box>
				<Box height={"min-content"}>
					<Link
						to={
							"/checkout/" +
							(type === "shipping" ? "shipping" : "general")
						}>
						<Icon iconName={"edit"} />
					</Link>
				</Box>
			</Flex>
		</NormalBox>
	);
}
