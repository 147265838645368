import {AspectRatio, Flex, Image} from "@chakra-ui/react";
import React from "react";
import {boxShadow, boxShadowSmall} from "../../theme/foundations/shadow";

export default function SimpleVariantSelector({
	setSelectedVariant,
	variants,
	selectedVariant,
}) {
	return (
		<Flex flexDir={"row"} gap={4} mr={4} flexWrap={"wrap"}>
			{variants.map((variant, index) => (
				<AspectRatio
					width={"70px"}
					ratio={1}
					key={index}
					onClick={() => {
						if (variant !== selectedVariant) {
							window.scrollTo({behavior: "smooth", top: 0});
							setSelectedVariant(variant);
						}
					}}>
					<Image
						borderRadius={"full"}
						boxShadow={boxShadowSmall}
						objectFit="cover"
						src={variant.assets[0]?.source || ""}
						alt={`Product image ${index + 1}`}
						cursor="pointer"
					/>
				</AspectRatio>
			))}
		</Flex>
	);
}
