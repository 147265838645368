import React from "react";
import {
	Button,
	Checkbox,
	Flex,
	FormControl,
	Image,
	Link,
	Radio,
	RadioGroup,
	Text,
	useToast,
} from "@chakra-ui/react";
import {useState} from "react";
import {useStore} from "../../Store";
import {useEffect} from "react";
import {mutateAndGetNewCart} from "../../helpers";
import {
	CREATE_MOLLIE_PAYMENT_INTENT,
	GET_MOLLIE_METHODS,
	SET_ORDER_CUSTOM_FIELDS,
} from "../../queries";
import {useForm} from "react-hook-form";
import NormalBox from "../elements/NormalBox";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {client} from "../..";
import FormContainer from "../productForms/FormContainer";
import FormSection from "../productForms/FormSection";
import ControlButtons from "./ControlButtons";
import AddressForm from "../checkout/AddressForm";
import CheckoutFormWrapper from "./CheckoutFormWrapper";
import {useRef} from "react";
import AddressInfoBox from "../checkout/AddressInfoBox";
import Seperator from "../layout/Seperator";
import {logEvent} from "../tracking/ga4";
import Icon from "../elements/Icon";

export default function PaymentForm() {
	const [mollieMethod, setMollieMethod] = useState();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const cart = useStore(state => state.cart);
	const setCart = useStore(state => state.setCart);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const toast = useToast();
	const form = useRef();
	const {redirectOrderState} = useParams();

	useEffect(() => {
		if (!cart) return;
		setMollieMethod(cart.customFields.mollieMethod);
	}, [cart]);

	useEffect(() => {
		if (redirectOrderState) {
			let title =
				"Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es noch einmal.";
			switch (redirectOrderState) {
				case "AddingItems":
					title =
						"Die Zahlung wurde abgebrochen. Versuche es bitte noch einmal.";
					break;
				case "ArrangingPayment":
					title =
						"Es gab einen Fehler bei der Bezahlung. Versuche es bitte noch einmal oder wende Dich an den Support.";
					break;
				default:
					break;
			}
			toast({
				title,
			});
		}
	}, []);

	useEffect(() => {
		if (!cart) return;
		async function updateMolliemethod() {
			let newCart = await mutateAndGetNewCart(SET_ORDER_CUSTOM_FIELDS, {
				mollieMethod,
			});
			let combinedCart = {...cart};
			combinedCart.customFields.mollieMethod =
				newCart.customFields.mollieMethod;
			setCart(combinedCart);
		}
		updateMolliemethod();
	}, [mollieMethod]);

	useEffect(() => {
		async function getMollieMethods() {
			let res = await client.query({query: GET_MOLLIE_METHODS});
			setPaymentMethods(res.data.molliePaymentMethods);
		}
		getMollieMethods();
	}, []);

	return (
		<CheckoutFormWrapper
			form={form}
			setLoading={setLoading}
			updateBillingOrShipping={"billing"}
			startOrderOnSubmit={true}>
			{cart.totalWithTaxAfterGiftCard > 0 ? (
				<FormControl isRequired>
					<RadioGroup
						display={"flex"}
						flexDir={"column"}
						gap={3}
						mb={4}
						onChange={setMollieMethod}
						value={mollieMethod}>
						{paymentMethods.map(method => {
							const code = method.code;
							return (
								<React.Fragment key={"option" + code}>
									<NormalBox
										as={"label"}
										bgColor={
											code === mollieMethod
												? "blue.100"
												: "gray.50"
										}
										htmlFor={code}
										zIndex={"1"}
										cursor={"pointer"}>
										<PaymentOption method={method} />
									</NormalBox>
									{code === mollieMethod && (
										<Flex
											flexDir={"column"}
											px={10}
											py={5}
											bgColor={"gray.50"}
											mt={-4}
											zIndex={"0"}
											textAlign={"center"}
											alignItems={"center"}>
											<Icon
												iconName={"lockclosed"}
												size={"2xl"}
											/>
											<Text mt={3}>
												Du wirst nach dem Klick auf
												"Jetzt kaufen" an unseren
												sicheren Zahlungsdienstleister
												weitergeleitet.
											</Text>

											<NormalBox
												alignItems={"center"}
												mt={2}>
												<Icon iconName={"info"} />
												<Text fontSize={"xs"} mb={0}>
													{alternativeLabels[code]
														?.longInfo
														? alternativeLabels[
																code
														  ].longInfo
														: "Die Bearbeitung Deiner Zahlung sollte nur wenige Minuten dauern."}
												</Text>
											</NormalBox>
										</Flex>
									)}
								</React.Fragment>
							);
						})}
					</RadioGroup>
				</FormControl>
			) : (
				<Text>
					Der Warenkorbswert wird von Deinem Gutschein gedeckt. Es ist
					keine weitere Zahlung nötig. Klicke unten auf "Jetzt
					kaufen", um die Bestellung abzuschließen.
				</Text>
			)}
			<Seperator />
			<AddressInfoBox cart={cart} type={"shipping"} />
			<AddressInfoBox cart={cart} type={"billing"} />
			<Seperator />
			<FormControl isRequired>
				<Checkbox name="terms" gap={2} size={"lg"}>
					Mit Deiner Bestellung erklärst du dich mit unseren{" "}
					<Link
						href="https://www.dearuniverse.de/agb"
						rel="noreferrer"
						target="_blank">
						AGB
					</Link>
					,{" "}
					<Link
						href="https://www.dearuniverse.de/widerrufsbelehrung"
						rel="noreferrer"
						target="_blank">
						Widerrufsbestimmungen
					</Link>{" "}
					und{" "}
					<Link
						href="https://www.dearuniverse.de/datenschutz"
						rel="noreferrer"
						target="_blank">
						Datenschutzbestimmungen
					</Link>{" "}
					einverstanden.
				</Checkbox>
			</FormControl>
			<ControlButtons
				nextButtonText={"Jetzt kaufen"}
				prevStepName={"shipping"}
				startOrderOnSubmit={true}
				isLoading={loading}
			/>
		</CheckoutFormWrapper>
	);
}

let alternativeLabels = {
	creditcard: {label: "Kreditkarte"},
	banktransfer: {
		label: "Überweisung",
		info: "Bearbeitung: 3-5 Werktage",
		longInfo:
			"Deine Artikel können erst nach 3-5 Tagen versendet werden, sobald Deine Zahlung eingegangen ist.",
	},
};
function PaymentOption({method}) {
	return (
		<>
			<Radio value={method.code} id={method.code} hidden>
				<Flex flexDir={"row"} alignItems={"center"}>
					<Image src={method.image.size2x} />
					<Text ml={5} fontSize={"lg"}>
						{alternativeLabels[method.code]?.label ||
							method.description}
						<Text as={"span"} fontSize={"sm"}>
							<br />
							{alternativeLabels[method.code]?.info || ""}
						</Text>
					</Text>
				</Flex>
			</Radio>
		</>
	);
}
