import React, {useState} from "react";
import {useEffect} from "react";
import DateTimePicker from "../elements/DateTimePicker";
import FileUpload from "./FileUpload";
import FormContainer from "./FormContainer";
import FormField from "./FormField";
import FormSection from "./FormSection";
import InputError from "./InputError";

export default function Message({
	priceWithTax,
	changeOption,
	changeUserInputs,
	userInputs,
	setUserInputs,
	options,
	optionGroups,
	product,
	showInputErrors,
	inputErrors,
}) {
	const [file, setFile] = useState(null);

	useEffect(() => {
		function getInitTime() {
			let time = new Date();
			time.setHours(time.getHours() + 25);
			time.setMinutes(0);
			time.setMilliseconds(0);
			time.setSeconds(0);
			return time.toISOString();
		}
		if (!userInputs.message_date) {
			changeUserInputs("message_date", getInitTime());
		}
	}, []);

	return (
		<FormContainer>
			<FormSection title={"Was willst Du ins All senden?"}>
				<FormField
					type={"textarea"}
					title={"Deine Nachricht"}
					placeholder={"Dear Universe,..."}
					fieldName={"message_text"}
					required
					changeUserInputs={changeUserInputs}
					value={userInputs.message_text || ""}
				/>
				<FileUpload
					setUserInputs={setUserInputs}
					userInputs={userInputs}
					file={file}
					setFile={setFile}
				/>
				<DateTimePicker
					title={"Sendedatum"}
					date={userInputs.message_date}
					keyName={"message_date"}
					change={changeUserInputs}
					help={"Wann soll Deine Nachricht ins All gesendet werden?"}
				/>
				<InputError
					show={showInputErrors && inputErrors.dateInvalid}
					text={
						"Bitte wähle ein Datum, das mind. 1 Stunde in der Zukunft liegt."
					}
				/>
			</FormSection>
		</FormContainer>
	);
}
