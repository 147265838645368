import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Text,
	useToast,
} from "@chakra-ui/react";
import React from "react";
import {useState} from "react";
import {useEffect} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {client} from "../..";
import {GET_ORDER_BY_CODE, TRANSITION_ORDER} from "../../queries";
import {useStore} from "../../Store";
import Loading from "../feedback/Loading";
import AwinTracking from "./AwinTracking";
import OneClickRegistration from "./OneClickRegistration";
import {track} from "../tracking/Tracking";

export default function OrderConfirmation({}) {
	const [order, setOrder] = useState();
	const {orderCode} = useParams();
	const cart = useStore(state => state.cart);
	const navigate = useNavigate();

	useEffect(() => {
		if (!order) return;
		if (order.state === "PaymentSettled") {
			track("purchase", order);
		}
	}, [order]);

	useEffect(() => {
		async function getOrder() {
			if (!orderCode) return;
			let result;
			try {
				result = await client.query({
					query: GET_ORDER_BY_CODE,
					variables: {code: orderCode},
				});
			} catch (error) {
				console.error(error);
				await client.mutate({
					mutation: TRANSITION_ORDER,
					variables: {state: "AddingItems"},
				});
				navigate("/checkout/payment/AddingItems");
				return;
			}
			setOrder(await result.data.result);
		}
		getOrder();
	}, [orderCode]);

	useEffect(() => {
		if (cart?.state && !order) {
			setOrder(cart);
			console.log("CART", cart);
		}
	}, [cart, order]);

	const stateTexts = {
		PaymentSettled: {
			heading: "Vielen Dank für Deine Bestellung!",
			text: `Wir haben Deine Zahlung erhalten. In wenigen Minuten erhältst Du eine Bestellbestätigung, sowie Deine digitalen Produkte per Mail. Schaue auch im Spam-Ordner nach. Alles weitere bereiten wir nun vor.`,
			status: "success",
		},
		PaymentAuthorized: {
			heading: "Vielen Dank für Deine Bestellung!",
			text: `Deine Zahlung wurde authorisiert, sie ist jedoch noch nicht angekommen. Das dauert je nach Zahlungsart
			wenige Minuten bis wenige Tage. Sobald wir die Zahlung erhalten haben, beginnen wir mit der Bearbeitung Deiner Bestellung.`,
			status: "success",
		},
		Shipped: {
			heading: "Wir haben Deine Bestellung bearbeitet!",
			text: `Wir haben Deine Zahlung erhalten. 
            Digitale Produkte bekommst Du in den nächsten Minuten, alles weitere bereiten wir nun vor.`,
			status: "success",
		},
		ArrangingPayment: {
			heading: "Wir haben noch keine Zahlung erhalten.",
			text: `Entweder, Du hast den Vorgang abgebrochen oder Du hast ein Zahlungsmittel mit längerer Bearbeitungszeit gewählt.
            Solltest Du den Vorgang abgebrochen haben, gelangst du hier zurück zur Zahlung:`,
			status: "warning",
			showBackToPayment: true,
			text2: "Wenn Du unsicher bist, kontaktiere einfach den Support!",
		},
	};

	if (!order) return <Loading />;

	let text = stateTexts[order.state];

	if (!text) return <Navigate to={"/checkout/payment/" + order?.state} />;

	console.log(order, orderCode);

	return (
		<>
			<Alert
				status={text.status}
				variant="subtle"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				py={"16"}>
				<AlertIcon boxSize="40px" mr={0} />
				<AlertTitle mt={4} mb={1} fontSize="lg">
					{text.heading}
				</AlertTitle>
				<Text mb={3}>Bestellung #{order.code}</Text>
				<AlertDescription mb={3} maxWidth="md">
					{text.text}
				</AlertDescription>
				{text.showBackToPayment && (
					<Box>
						<Button
							mb={3}
							colorScheme={"blackAlpha"}
							onClick={async () => {
								await client.mutate({
									mutation: TRANSITION_ORDER,
									variables: {state: "AddingItems"},
								});
								navigate("/checkout/payment");
							}}>
							Zurück zur Zahlung
						</Button>
					</Box>
				)}
				{text.text2 && <Text>{text.text2}</Text>}
			</Alert>
			<OneClickRegistration order={order} />
			<iframe
				height="1300px"
				title="Umfrage"
				id="umfrageIFrame"
				src="https://docs.google.com/forms/d/e/1FAIpQLSc7OloLRzL6Gd_rEeLV7a7mKNNImMDZI3N9vWJP_BuFJrM6IA/viewform?embedded=true"
				frameBorder="0"
				marginHeight="0"
				marginWidth="0">
				Wird geladen…
			</iframe>
			<AwinTracking order={order} />
		</>
	);
}
