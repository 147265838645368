import {Box, Button, Flex, List, ListItem, Text} from "@chakra-ui/react";
import React from "react";
import {Link} from "react-router-dom";
import {BORDER_BLACK} from "../../App";
import {useStore} from "../../Store";
import {bgGradientSoft} from "../../theme/foundations/colors";
import Icon from "../elements/Icon";

export default function UserMenu() {
	const logout = useStore(state => state.logout);
	return (
		<Flex
			flexDir={["row", null, "column"]}
			minW={"max-content"}
			p={3}
			pr={5}
			justifyContent={["space-between", null, "flex-start"]}
			borderRight={["none", null, BORDER_BLACK]}>
			<UserMenuItem to={"/user"} text={"Deine Daten"} icon={"account"} />
			<UserMenuItem to={"orders"} text={"Bestellungen"} icon={"cart1"} />
			<UserMenuItem
				text={"Logout"}
				icon={"logout"}
				onClick={logout}
				mt={"auto"}
			/>
		</Flex>
	);
}

function UserMenuItem({to, text, icon, onClick, ...props}) {
	return (
		<Link to={to || "#"}>
			<Flex
				onClick={onClick}
				w={"100%"}
				py={2}
				flexDir={["column", null, "row"]}
				justifyContent={["center", null, "flex-start"]}
				alignItems={["center", null, "flex-start"]}
				{...props}>
				<Flex
					display={"inline-flex"}
					w={"20px"}
					mr={[0, 0, 3]}
					overflow={"hidden"}
					justifyContent={"center"}
					alignItems={"center"}>
					<Icon icon={icon} />
				</Flex>
				<Text>{text}</Text>
			</Flex>
		</Link>
	);
}
