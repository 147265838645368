import {
	FormControl,
	FormHelperText,
	FormLabel,
	Input,
	Text,
	Box,
} from "@chakra-ui/react";
import React, {useState, useRef} from "react";
import {useId} from "react";

export function FormField({
	label,
	help,
	inputProps,
	errors,
	value,
	onChange,
	prefix,
}) {
	const isRequired = inputProps.required;
	const id = useId();
	const inputRef = useRef();

	const [emailWarning, setEmailWarning] = useState(false);

	const handleInputChange = e => {
		onChange?.(e);

		if (inputProps.type !== "email") return;
		const value = e.target.value;
		if (value.includes("@t-online") || value.includes("@magenta")) {
			setEmailWarning(true);
		} else {
			setEmailWarning(false);
		}
	};

	return (
		<FormControl variant="floating" isRequired={inputProps.required}>
			<Input
				ref={inputRef}
				id={id}
				onChange={handleInputChange}
				placeholder={" "}
				bgColor={"white"}
				{...inputProps}
			/>
			<FormLabel htmlFor={id} fontWeight={"normal"}>
				{label}
				{isRequired ? (
					""
				) : (
					<Text as={"span"} opacity={"0.7"}>
						{" "}
						(optional)
					</Text>
				)}
			</FormLabel>
			<FormHelperText fontSize={"x-small"} ml={"2"}>
				{help}
			</FormHelperText>
			{emailWarning && (
				<Box mt={2} fontSize="sm" color="red.500">
					Achtung: Telekom hat kürzlich einige wichtige E-Mails
					blockiert. Bitte verwenden Sie nach Möglichkeit eine andere
					E-Mail-Adresse.
				</Box>
			)}
		</FormControl>
	);
}
