import React, {useRef} from "react";
import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormErrorMessage,
	Heading,
	Select,
	SelectField,
	Text,
	useToast,
} from "@chakra-ui/react";
import {useEffect} from "react";
import {useState} from "react";
import {useStore} from "../../Store";
import {Link, useNavigate} from "react-router-dom";
import {fillForm, getFormData} from "./helper";
import {mutateAndGetNewCart} from "../../helpers";
import {
	ADD_GIFTCARD_PAYMENT,
	CREATE_MOLLIE_PAYMENT_INTENT,
	TRANSITION_ORDER,
} from "../../queries";
import {client} from "../..";
import TrustBadges from "../elements/TrustBadges";

export default function CheckoutFormWrapper({
	form,
	setLoading,
	mutation,
	nextUrl,
	updateBillingOrShipping,
	children,
	startOrderOnSubmit,
}) {
	const cart = useStore(state => state.cart);
	const setCart = useStore(state => state.setCart);
	const navigate = useNavigate();
	const toast = useToast();

	async function startPayment() {
		/* Transition Order State */
		await client.mutate({
			mutation: TRANSITION_ORDER,
			variables: {state: "ArrangingPayment"},
		});
		for (const giftcard of cart.giftCardsApplied) {
			await client.mutate({
				mutation: ADD_GIFTCARD_PAYMENT,
				variables: {code: giftcard.code},
			});
		}
		if (cart.totalWithTaxAfterGiftCard === 0) {
			setTimeout(() => {
				window.location.href = "/checkout/confirmation/" + cart.code;
			}, 2000);
			return;
		}
		let res = await client.mutate({
			mutation: CREATE_MOLLIE_PAYMENT_INTENT,
			variables: {mollieMethod: cart.customFields.mollieMethod},
		});
		let url = res.data?.createMolliePaymentIntent.url;
		if (url) {
			window.location.href = url;
		} else {
			throw new Error("No Redirect Url");
		}
	}

	async function handleSubmit(event) {
		setLoading(true);
		try {
			event.preventDefault();
			const data = getFormData(event.target);

			console.log("Formdata: ", data);
			if (startOrderOnSubmit) {
				startPayment();
				return;
			}
			if (data.shippingMethodId) {
				data.shippingMethodId = [data.shippingMethodId];
			}
			console.log(data);
			let result = await client.mutate({
				mutation,
				variables: data,
				fetchPolicy: "no-cache",
			});
			let error = findErrorMessage(result);
			if (error) {
				toast({
					title: error,
					status: "error",
					duration: 7000,
					isClosable: true,
					position: "top-right",
				});
				setLoading(false);
				return;
			}
			const newCart = result.data.result;
			await setCart(newCart);
			navigate(nextUrl);
		} catch (error) {
			toast({
				title: "Leider ist etwas schief gelaufen...",
				description:
					"Lade die Seite neu, versuche es später noch einmal oder wende Dich an den Support.",
				status: "error",
				duration: 7000,
				isClosable: true,
			});
			await client.mutate({
				mutation: TRANSITION_ORDER,
				variables: {state: "AddingItems"},
			});
		}
		setLoading(false);
	}

	useEffect(() => {
		if (!form.current || !cart) return;
		let prefix = updateBillingOrShipping;
		if (prefix === "shipping" && !cart.shippingAddress.streetLine1) {
			/* Prefill shipping form with billing data, if no address is set yet */
			prefix = "billing";
		}
		fillForm(form.current, {
			emailAddress: cart.customer?.emailAddress || "",
			firstName: cart.customer?.firstName || "",
			lastName: cart.customer?.lastName || "",
			phoneNumber: cart.customer?.phoneNumber || "",
			fullName: cart[prefix + "Address"].fullName || "",
			city: cart[prefix + "Address"].city || "",
			streetLine1: cart[prefix + "Address"].streetLine1 || "",
			streetLine2: cart[prefix + "Address"].streetLine2 || "",
			postalCode: cart[prefix + "Address"].postalCode || "",
			countryCode: cart[prefix + "Address"].countryCode || "",
		});
	}, [cart, updateBillingOrShipping, form]);

	return (
		<>
			<Flex
				as={"form"}
				flexDir={"column"}
				gap={3}
				onSubmit={handleSubmit}
				ref={form}>
				{children}
			</Flex>
			<Box display={["block", null, "none"]}>
				<TrustBadges />
			</Box>
		</>
	);
}

export function useSetRadioValue(formRef, name, value) {
	const radioRef = useRef();

	useEffect(() => {
		console.log(formRef.current.elements, [name]);
		if (formRef.current && formRef.current.elements[name]) {
			radioRef.current = formRef.current.elements[name];
		}
	}, [formRef, name]);

	useEffect(() => {
		if (radioRef.current) {
			radioRef.current.isChecked = radioRef.current.value === value;
			console.log(radioRef.current.isChecked);
		}
	}, [value]);
}

function findErrorMessage(obj, depth = 0) {
	for (const key in obj) {
		if (key === "message") {
			return obj[key];
		} else if (typeof obj[key] === "object") {
			if (depth < 2) {
				const result = findErrorMessage(obj[key], depth + 1);
				if (result) {
					return result;
				}
			}
			return null;
		}
	}
	return null;
}
