import React, {useEffect, useRef, useState} from "react";
import {Flex, Box, Image, Text} from "@chakra-ui/react";
import {client} from "../..";
import {GIFTCARD_DESIGNS} from "../../queries";
import {formatPrice} from "../../helpers";

export default function NormalProductGallery({product, slug, userInputs}) {
	const [mainImage, setMainImage] = useState("");
	const mainImageRef = useRef();
	const [mainImageLoadedFlag, setMainImageLoadedFlag] = useState(false);
	const [mainImageDim, setMainImageDim] = useState({w: "0px", h: "0px"});
	const [giftCardTemplates, setGiftCardTemplates] = useState([]);

	useEffect(() => {
		async function getDesigns() {
			if (slug !== "giftcard") return;
			let response = await client.query({query: GIFTCARD_DESIGNS});
			setGiftCardTemplates(response.data.giftCardTemplates);
		}
		getDesigns();
	}, [slug]);

	useEffect(() => {
		function resize() {
			let newMainImageDim = {};
			newMainImageDim.w = mainImageRef.current.offsetWidth;
			newMainImageDim.h = mainImageRef.current.offsetHeight;
			setMainImageDim(newMainImageDim);
		}
		resize();
		window.addEventListener("resize", resize);
		return () => {
			window.removeEventListener("resize", resize);
		};
	}, [mainImageRef, mainImageLoadedFlag]);

	useEffect(() => {
		if (userInputs.templateId && giftCardTemplates.length > 0) {
			setMainImage(
				giftCardTemplates.find(
					template => template.id === userInputs.templateId
				).preview
			);
		}
	}, [userInputs, giftCardTemplates]);

	return (
		<Flex
			w="100%"
			h="100%"
			justifyContent={"center"}
			alignItems={"center"}
			p={10}
			position={"relative"}>
			<Image
				ref={mainImageRef}
				src={mainImage}
				onLoad={() => {
					setMainImageLoadedFlag(!mainImageLoadedFlag);
				}}
				objectFit={"contain"}
				maxHeight={"100%"}
				boxShadow={"lg"}
			/>
			{slug === "giftcard" && mainImageRef.current && (
				<Flex
					justifyContent={"center"}
					alignItems={"center"}
					position={"absolute"}
					flexDir={"column"}
					w={mainImageDim.w + "px"}
					h={mainImageDim.h + "px"}>
					<Box
						color={"gray.600"}
						fontSize={`${mainImageDim.w / 25}px`}
						textAlign={"center"}
						transform={`translate(-${mainImageDim.w * 0.14}px , ${
							mainImageDim.h * 0.066
						}px)`}>
						<Text as={"span"} fontWeight={"bold"}>
							DE1N-C0DE
						</Text>
						<Text>{formatPrice(userInputs.value)}</Text>
					</Box>
					<Text
						fontSize={`${mainImageDim.w / 4}px`}
						opacity={"0.3"}
						fontFamily={"Merriweather"}
						transform={"rotate(-50deg)"}
						mb={-1}>
						Muster
					</Text>
				</Flex>
			)}
		</Flex>
	);
}
