import React from "react";
import ProductGrid from "../product/ProductGrid";
import Page from "../layout/Page";
import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import QuickLinks from "../elements/QuickLinks";
import Seperator from "../layout/Seperator";
import {Link} from "react-router-dom";

export default function AllProducts() {
	return (
		<Page
			footer={true}
			heading={"Unsere unendlichen Produkte"}
			title={"Alle Produkte"}>
			<Heading as={"h3"} className="decorative" textAlign={"center"}>
				Ewigkeit zum Anfassen
			</Heading>
			<Flex justifyContent={"center"}>
				<Box maxW={"420px"} textAlign={"center"}>
					<Text>
						Hier findest Du unsere ewigen Produkte. Du liebst
						Schmuck? Dann sind die{" "}
						<Link to={"/product/necklace"}>Ewigkeitsketten</Link>{" "}
						genau das Richtige für Dich.
					</Text>
					<Text>
						Oder Du willst einfach nur eine Nachricht ins Weltall
						senden und einen Fußabdruck hinterlassen? Dann schau
						beim <Link to={"/product/message"}>Funkspruch</Link>{" "}
						vorbei!
					</Text>
				</Box>
			</Flex>
			<QuickLinks />
			<Seperator />
			<ProductGrid />
		</Page>
	);
}
