import {defaultUserInputs} from "./defaultUserInputs";

export default function validateForms(userInputs, slug) {
	let result = {
		valid: true,
		starposter: {
			locationMissing: false,
			dateInvalid: false,
		},
		message: {
			dateInvalid: false,
		},
		certificate: {
			noMessageSelected: false,
		},
	};

	function check(key, checkFunctionOrValue) {
		/* If key was already tested and error has to be displayed */
		if (result[slug][key]) return;

		let failed;
		if (typeof checkFunction === "function") {
			failed = checkFunctionOrValue();
		} else {
			failed = checkFunctionOrValue;
		}

		if (failed) result.valid = false;
		result[slug][key] = failed;
	}

	const def = defaultUserInputs[slug];

	if (slug === "starposter") {
		check("locationMissing", userInputs.poster_lat === def.poster_lat);
		check("locationMissing", userInputs.poster_long === def.poster_long);
		check("dateInvalid", !isValidDate(new Date(userInputs.poster_date)));
	} else if (slug === "planetposter") {
	} else if (slug === "message") {
		check("dateInvalid", !checkMessageDate(userInputs.message_date));
	} else if (slug === "certificate") {
		check(
			"noMessageSelected",
			!userInputs.relatedUserInputs ||
				!userInputs.relatedUserInputs.message_date
		);
	}

	return result;
}

export function isValidDate(d) {
	return d instanceof Date && !isNaN(d);
}

export function scrollToEl(el) {}

export function checkMessageDate(messageDate) {
	return new Date(messageDate) - new Date() > 60 * 60 * 1000;
}
