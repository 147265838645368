import {
	Box,
	chakra,
	Container,
	Stack,
	Text,
	Image,
	Flex,
	VStack,
	Button,
	Heading,
	SimpleGrid,
	StackDivider,
	useColorModeValue,
	VisuallyHidden,
	List,
	ListItem,
	Tabs,
	TabList,
	Tab,
	TabIndicator,
	TabPanels,
	TabPanel,
} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import SimpleProductGallery from "../elements/SimpleProductGallery";
import AddToCartBtn from "../product/AddToCartBtn";
import {formatPrice} from "../../helpers";
import ProductReviews from "../reviews/ProductReviews";
import {NFCFeatures} from "../Explainer/NFC-Features";
import SimpleVariantSelector from "../elements/SimpleVariantSelector";
import {FAQSection} from "../Explainer/FAQSection";
import StepByStepProcess from "../Explainer/StepByStepProcess";
import StockIndicator from "../elements/StockIndicator";
import Seperator from "../layout/Seperator";
import {maxPageWidth} from "../layout/Page";
import Icon from "../elements/Icon";
import ImageAndText from "../elements/ImageAndText";
import {boxShadow} from "../../theme/foundations/shadow";
import ReviewBadge from "../reviews/ReviewBadge";
import {MessageFeatures} from "../Explainer/MessageFeatures";
import {Link} from "react-router-dom";
import {PosterFeatures} from "../Explainer/PosterFeatures";

export default function ProductPage({product}) {
	const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	const reviewRef = useRef(null);

	const hasConfigurator = !product.customFields.standardProductPage;

	const findLowestPrice = variants => {
		return Math.min(...variants.map(variant => variant.priceWithTax));
	};

	const lowestPrice = findLowestPrice(product.variants);

	function getFeatureList() {
		switch (product.slug) {
			case "necklace":
				return <NFCFeatures />;
			case "message":
				return <MessageFeatures />;
			case "photoposter":
				return <PosterFeatures />;
			default:
				break;
		}
		return "";
	}

	return (
		<Box maxW={maxPageWidth}>
			<SimpleGrid
				columns={{base: 1, lg: 2}}
				spacing={{base: 8, md: 10}}
				py={{base: 18, md: 24}}>
				<Flex>
					<SimpleProductGallery
						assets={
							hasConfigurator
								? product.assets
								: selectedVariant.assets
						}
						selectedIndex={selectedImageIndex}
						setSelectedImageIndex={setSelectedImageIndex}
					/>
				</Flex>
				<Stack spacing={{base: 6, md: 6}}>
					<Box as={"header"}>
						<Heading
							lineHeight={1.1}
							fontWeight={600}
							fontSize={{base: "2xl", sm: "3xl", lg: "4xl"}}>
							{hasConfigurator
								? product.name
								: selectedVariant.name}
						</Heading>
						{product.slug === "necklace" && (
							<Text fontSize={"sm"} opacity={0.7}>
								inkl. Funkspruch ins All
							</Text>
						)}
						{product.slug === "message" && (
							<Text fontSize={"sm"} opacity={0.7}>
								inkl. Dashboard zum Nachverfolgen
							</Text>
						)}
						<ReviewBadge
							reviews={product.reviews}
							oneline={true}
							link={() => {
								if (reviewRef && reviewRef.current) {
									reviewRef.current.click();
									reviewRef.current.scrollIntoView({
										behavior: "smooth",
									});
								}
							}}
						/>
						<Flex
							justifyContent={"space-between"}
							alignItems={"center"}
							gap={3}
							wrap={"wrap-reverse"}>
							<Text
								m={0}
								color={useColorModeValue(
									"gray.900",
									"gray.400"
								)}
								fontWeight={300}
								fontSize={"2xl"}>
								{!hasConfigurator
									? formatPrice(selectedVariant.priceWithTax)
									: `ab ${formatPrice(lowestPrice)}`}
							</Text>

							{!hasConfigurator && (
								<StockIndicator
									stockOnHand={selectedVariant.stockOnHand}
								/>
							)}
						</Flex>
						<Seperator />
						<Text>{product.customFields.shortDescription}</Text>
					</Box>
					{!hasConfigurator ? (
						<>
							<Box mb={[5, null, 3]}>
								<Text fontWeight={"bold"}>
									Wähle Deine Lieblingsfarbe und -form!
								</Text>
								<SimpleVariantSelector
									selectedVariant={selectedVariant}
									setSelectedVariant={variant => {
										setSelectedVariant(variant);
										setSelectedImageIndex(0);
									}}
									variants={product.variants}
								/>
							</Box>

							<AddToCartBtn
								variantId={selectedVariant.id}
								product={product}
							/>
						</>
					) : (
						<Link to={`/product/configurator/${product.slug}`}>
							<Button>Jetzt personalisieren!</Button>
						</Link>
					)}
					<Box py={[5, null, 2]}>{getFeatureList()}</Box>
				</Stack>
			</SimpleGrid>
			<Tabs position="relative" variant="unstyled">
				<TabList borderBottom={"1px solid"} borderColor={"lightBlue"}>
					{product.slug === "necklace" && (
						<Tab px={[2, null, 4]} fontSize={["xs", "sm", "md"]}>
							So funktioniert's!
						</Tab>
					)}
					<Tab px={[2, null, 4]} fontSize={["xs", "sm", "md"]}>
						Beschreibung
					</Tab>
					<Tab
						px={[2, null, 4]}
						fontSize={["xs", "sm", "md"]}
						ref={reviewRef}>
						Reviews
					</Tab>
					<Tab px={[2, null, 4]} fontSize={["xs", "sm", "md"]}>
						FAQ
					</Tab>
				</TabList>
				<TabIndicator
					mt="-3px"
					height="2px"
					bg="blue.500"
					borderRadius="1px"
				/>
				<TabPanels pt={5}>
					{product.slug === "necklace" && (
						<TabPanel>
							<StepByStepProcess />
						</TabPanel>
					)}
					<TabPanel>
						<Text
							className="product-description"
							dangerouslySetInnerHTML={{
								__html: product.description,
							}}></Text>
					</TabPanel>
					<TabPanel>
						<ProductReviews reviews={product.reviews} />
					</TabPanel>
					<TabPanel>
						<FAQSection faqType={product.slug} />
					</TabPanel>
				</TabPanels>
			</Tabs>
			<Seperator />
			<Box mt={10} boxShadow={boxShadow}>
				<ImageAndText
					imageSrc={"/images/kolja.jpg"}
					alt={"Gründer von DearUniverse Kolja Martens"}>
					<Heading as="h3">Inhabergeführtes Startup</Heading>
					<Text>
						2020 habe ich DearUniverse gegründet, 2023 habe ich mich
						damit selbstständig gemacht. Wir haben bereits tausende
						Erinnerungen im Universum verewigt! Solltest Du
						irgendwelche Fragen haben, bin ich mit meinem Team
						jederzeit für Dich da!
					</Text>
				</ImageAndText>
				{hasConfigurator ? (
					<>
						<ImageAndText
							imageSrc={"/images/milkyway.jpg"}
							alt={"Person unter Nachthimmel"}
							switchSide={true}>
							<Heading as="h3">Das Universum hört Dich!</Heading>
							<Text>
								Deine Nachricht wird die Menschheit überdauern
								und auch in Trillionen von Jahren noch dort
								draußen sein. Eine Nachricht ins Weltall zu
								senden, ist sozusagen eine "moderne
								Flaschenpost" – Du weißt nie, wo und wann sie
								vielleicht mal ankommt...
							</Text>
						</ImageAndText>
					</>
				) : (
					<>
						<ImageAndText
							imageSrc={"/images/notiz.jpg"}
							alt={"Mitarbeiter schreibt Zettel"}
							switchSide={true}>
							<Heading as="h3">Persönliche Notiz</Heading>
							<Text>
								In jedes Päckchen legen wir ein kleines,
								handgeschriebenes Dankeschön. Bei uns steht
								nicht die Profit-Maximierug im Vordergrund,
								sondern ein achtsamer Umgang mit unserer Arbeit
								und den Menschen, mit denen wir zu tun haben.{" "}
								<br></br>Und pssst: Ein kleines Geschenk legen
								wir auch in die Box. Lass Dich überraschen!
							</Text>
						</ImageAndText>
						<ImageAndText
							imageSrc={"/images/receive.jpg"}
							alt={"Gründer von DearUniverse Kolja Martens"}>
							<Heading as="h3">
								Erst wenn Du zufrieden bist,...
							</Heading>
							<Text>
								... sind wir es auch. Deshalb kannst Du Deine
								Kette bis zu <b>2 Wochen Probetragen</b>. Sollte
								Dir etwas nicht gefallen, kannst Du sie einfach
								zurückgeben oder umtauschen. <br></br>
								Sollte später noch einmal etwas mit der Kette
								sein, hast Du ganze <b>2 Jahre Garantie</b>, in
								denen wir Dir Deine Kette ersetzen. Klingt gut?{" "}
								<Text
									color={"lightBlue"}
									cursor={"pointer"}
									onClick={() => {
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
									}}
									as={"span"}>
									Dann sichere Dir jetzt Deine Ewigkeitskette!
								</Text>
							</Text>
						</ImageAndText>
					</>
				)}
			</Box>
			<Button
				onClick={() => {
					window.scrollTo({top: 0, behavior: "smooth"});
				}}
				mt={5}
				w={"100%"}
				display={["flex", null, "none"]}
				variant={"outline"}
				leftIcon={<Icon iconName="backtotop" />}>
				Wieder nach oben
			</Button>
		</Box>
	);
}
