import {Button, Image, Text, useToast} from "@chakra-ui/react";
import React, {useState} from "react";
import {client} from "../..";
import {
	ADD_GIFTCARD_TO_CART,
	ADD_ITEM_TO_CART,
	CHANGE_CUSTOM_FIELDS,
	CHANGE_QUANTITY,
	GET_CART,
} from "../../queries";
import {useStore} from "../../Store";
import axios from "axios";
import {productsWithPreview} from "../../helpers";
import {useNavigate} from "react-router-dom";
import AlertDialog from "../feedback/AlertDialog";
import {track} from "../tracking/Tracking";
import Icon from "../elements/Icon";

export default function AddToCartBtn({
	variantId,
	userInputs,
	editMode,
	orderLineId,
	setEditMode,
	quantity,
	setSearchParams,
	relatedLineId,
	currentOrderLine,
	slug,
	setProductSelection,
	product,
	iconOnly,
}) {
	const cart = useStore(state => state.cart);
	const [loading, setLoading] = useState(false);
	const setForceUpdate = useStore(state => state.setForceUpdate);
	const [showUpsellAlert, setShowUpsellAlert] = useState(false);

	const setCart = useStore(state => state.setCart);
	const navigate = useNavigate();
	const toast = useToast();

	async function addToCart(noNavigate) {
		setLoading(true);
		let updatedOrderLines;
		if (slug === "giftcard") {
			let uinputs = JSON.parse(userInputs);
			uinputs.productSlug = undefined;
			if (uinputs.deliveryDate) {
				uinputs.deliveryDate = new Date(
					uinputs.deliveryDate
				).toISOString();
			}
			let variables = {
				input: {...uinputs},
			};
			console.log(variables);
			updatedOrderLines = await client.mutate({
				mutation: ADD_GIFTCARD_TO_CART,
				variables,
			});
		} else {
			updatedOrderLines = await client.mutate({
				mutation: ADD_ITEM_TO_CART,
				variables: {userInputs, variantId, relatedLineId},
			});
		}
		if (
			updatedOrderLines.data.result.__typename ===
			"InsufficientStockError"
		) {
			toast({
				title: "Leider gibt es zu wenig Ware. Versuche es später noch einmal.",
				status: "warning",
				duration: 5000,
				isClosable: true,
			});
			setLoading(false);
			return;
		}
		updatedOrderLines = updatedOrderLines.data.result.lines;
		let lineId = updatedOrderLines[updatedOrderLines.length - 1].id;
		makePreview(lineId);

		const newCart = await getAndSetNewCart();

		/* Zurück-Button führt zu Edit-Seite */
		setEditParam(newCart.lines[newCart.lines.length - 1].id);
		setLoading(false);
		await setEditMode?.(true);

		track("add_to_cart", cart, product, variantId);

		if (!noNavigate) {
			navigate("/cart");
		}
	}

	async function makePreview(lineId) {
		if (productsWithPreview.indexOf(slug) === -1) return;

		await axios({
			method: "post",
			url: process.env.REACT_APP_DU_API + "/poster/preview",
			data: {
				userInputs,
				orderLineId: lineId || orderLineId,
			},
		});
		setForceUpdate();
	}

	async function getAndSetNewCart() {
		let newCart = await client.query({
			query: GET_CART,
			fetchPolicy: "no-cache",
		});
		newCart = newCart.data.result;
		setCart(newCart);
		return newCart;
	}

	async function changeVariant() {
		console.log(currentOrderLine.productVariant.id, variantId);
		const oldLine = currentOrderLine;
		let newLine = await client.mutate({
			mutation: ADD_ITEM_TO_CART,
			variables: {userInputs, variantId, relatedLineId},
		});
		newLine = newLine.data.result;
		newLine = newLine.lines[newLine.lines.length - 1];
		console.log("newline:", newLine);
		makePreview(newLine.id);
		//Check all old Lines if one was related to the edited one and update
		// their relatedLineId
		for (let line of cart.lines) {
			if (line.customFields.relatedLine?.id === oldLine.id) {
				console.log(line);
				await client.mutate({
					mutation: CHANGE_CUSTOM_FIELDS,
					variables: {
						orderLineId: line.id,
						quantity: line.quantity,
						customFields: {
							userInputs: line.customFields.userInputs,
							relatedLineId: newLine.id,
						},
					},
				});
			}
		}
		await client.mutate({
			mutation: CHANGE_QUANTITY,
			variables: {orderLineId: oldLine.id, quantity: 0},
		});
		let newCart = await getAndSetNewCart();
		setEditParam(newCart.lines[newCart.lines.length - 1].id);
	}

	function setEditParam(id) {
		setSearchParams?.("editid=" + id, {replace: true});
	}

	async function editLine() {
		setLoading(true);
		console.log(currentOrderLine);
		if (slug === "giftcard") {
			await client.mutate({
				mutation: CHANGE_QUANTITY,
				variables: {quantity: 0, orderLineId: currentOrderLine.id},
			});
			addToCart();
			return;
		}
		if (currentOrderLine.productVariant.id !== variantId) {
			await changeVariant();
			navigate("/cart");
			setLoading(false);
			return;
		}
		makePreview();
		await client.mutate({
			mutation: CHANGE_CUSTOM_FIELDS,
			variables: {
				orderLineId,
				quantity: quantity || 1,
				customFields: {userInputs},
			},
		});
		await getAndSetNewCart();
		navigate("/cart");
		setLoading(false);
	}

	function getButtonText() {
		if (editMode) return "Änderungen übernehmen";
		if (relatedLineId) return "Zur Nachricht hinzufügen";
		return "In den Warenkorb";
	}

	function handleClick() {
		if (editMode) {
			editLine();
			return;
		}
		if (slug === "message") {
			setShowUpsellAlert(true);
		} else {
			addToCart();
		}
	}

	async function handleUpsellConfirm() {
		await addToCart();
		setProductSelection(false);
		setEditMode?.(false);
		setTimeout(() => {
			navigate("/product/certificate/");
		}, 500);
	}

	function handleUpsellCancel() {
		addToCart();
	}

	return (
		<>
			<Button
				width={"100%"}
				isLoading={loading}
				onClick={handleClick}
				leftIcon={
					<Icon
						iconStyle="regular"
						iconName={editMode ? "edit" : "cart4"}
					/>
				}
				rightIcon={
					!iconOnly && (
						<Icon iconStyle="regular" iconName={"arrowforward"} />
					)
				}>
				{!iconOnly && getButtonText()}
			</Button>

			<AlertDialog
				cancelText={"Später vielleicht"}
				isOpen={showUpsellAlert}
				onClose={() => setShowUpsellAlert(false)}
				onConfirm={handleUpsellConfirm}
				title="Möchtest du ein Zertifikat hinzufügen?"
				body={
					<>
						<Image
							src={
								process.env.REACT_APP_VENDURE_ASSETS +
								"/preview/45/echtheitszertifikat__preview.jpg?preset=medium"
							}></Image>
						<Text>
							🌟 Offizielle Bestätigung der gesendeten Nachricht
						</Text>

						<Text>
							📄 Wählbar als digitale Version oder hochwertiger
							A4-Print
						</Text>
					</>
				}
				confirmColorScheme="brand"
				confirmText="Zum Zertifikat"
				onCancel={handleUpsellCancel}
			/>
		</>
	);
}
