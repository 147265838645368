import {Flex, FormLabel, Radio, RadioGroup, Stack} from "@chakra-ui/react";
import React from "react";
import Icon from "./Icon";

export default function ColorPicker({colors, change, color, title}) {
	return (
		<RadioGroup
			value={color}
			onChange={val => {
				change("poster_color", val);
			}}>
			<FormLabel>{title || "Farbe auswählen"}</FormLabel>
			<Flex direction="row" wrap={"wrap"} gap={3}>
				{colors.map(c => {
					const colorHex = c.val;
					return (
						<Radio value={colorHex} key={colorHex} hidden={true}>
							<ColorLabel
								hex={colorHex}
								checked={color === colorHex}
							/>
						</Radio>
					);
				})}
			</Flex>
		</RadioGroup>
	);
}

function ColorLabel({checked, hex}) {
	return (
		<Flex
			w={"50px"}
			h={"50px"}
			bgColor={hex}
			justifyContent={"center"}
			alignItems={"center"}
			color={"white"}
			borderRadius={"sm"}
			border={checked ? "1px solid white" : "none"}
			boxShadow={checked ? "0px 0px 0px 2px " + hex : "none"}>
			{checked && <Icon iconName={"check"} />}
		</Flex>
	);
}
