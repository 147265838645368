import React, {useEffect, useState} from "react";
import {
	Box,
	Image,
	SimpleGrid,
	Flex,
	Button,
	HStack,
	Fade,
	AspectRatio,
	Center,
	Spinner,
} from "@chakra-ui/react";
import Icon from "./Icon";
import {BORDER_BLACK} from "../../App";
import {
	boxShadow,
	boxShadowMedium,
	boxShadowSmall,
} from "../../theme/foundations/shadow";

export default function SimpleProductGallery({
	selectedIndex,
	assets,
	setSelectedImageIndex,
}) {
	const [startX, setStartX] = useState(null);
	const [isZooming, setIsZooming] = useState(false); // State to track if user is zooming
	const [isLoaded, setIsLoaded] = useState(false);

	const handleImageLoad = () => {
		setIsLoaded(true);
	};
	const handlePrevious = () => {
		const newIndex =
			selectedIndex === 0 ? assets.length - 1 : selectedIndex - 1;
		setSelectedImageIndex(newIndex);
	};

	const handleNext = () => {
		const newIndex =
			selectedIndex === assets.length - 1 ? 0 : selectedIndex + 1;
		setSelectedImageIndex(newIndex);
	};

	const handleTouchStart = e => {
		if (e.touches.length > 1) {
			// Two or more fingers indicate a zoom gesture
			setIsZooming(true);
			return;
		}

		setStartX(e.touches[0].clientX);
	};

	const handleTouchEnd = e => {
		if (isZooming) {
			// Reset the zooming state and exit
			setIsZooming(false);
			return;
		}

		const endX = e.changedTouches[0].clientX;
		const difference = startX - endX;

		// Adjust this threshold as needed
		const swipeThreshold = 30;

		if (difference > swipeThreshold) {
			handleNext();
		} else if (difference < -swipeThreshold) {
			handlePrevious();
		}
	};

	if (assets.length === 0) {
		return "Keine Bilder verfügbar...";
	}
	const previewImageSizing = ["45px", "55px", "70px"];
	return (
		<Flex
			flexDirection={["column-reverse", null, "row"]}
			gap={3}
			w={"100%"}>
			<Flex
				flexDir={["row", null, "column"]}
				gap={4}
				mr={[0, null, 4]}
				width={["auto", null, "70px"]}
				minH={previewImageSizing}>
				{assets.map((image, index) => (
					<Image
						key={index}
						boxShadow={
							index === selectedIndex
								? boxShadowMedium
								: boxShadowSmall
						}
						onClick={() => setSelectedImageIndex(index)}
						height={previewImageSizing}
						width={previewImageSizing}
						borderRadius={"full"}
						objectFit="cover"
						src={image.source}
						alt={`Product image ${index + 1}`}
						cursor="pointer"
					/>
				))}
			</Flex>
			<Box flex="1" h="max-content" p={2} bgColor="blue.50">
				<AspectRatio
					ratio={1}
					w="100%"
					onTouchStart={handleTouchStart}
					onTouchEnd={handleTouchEnd}>
					<Box position="relative" width="100%" height="100%">
						{!isLoaded && (
							<Center
								position="absolute"
								top="0"
								left="0"
								right="0"
								bottom="0">
								<Spinner />
							</Center>
						)}
						<Image
							boxSize="100%"
							objectFit="cover"
							src={assets[selectedIndex]?.source}
							alt="Selected product image"
							onLoad={handleImageLoad}
							style={{opacity: isLoaded ? 1 : 0}}
						/>
					</Box>
				</AspectRatio>

				<Flex justifyContent="space-between" alignItems="center" mt={2}>
					<Box onClick={handlePrevious} cursor={"pointer"}>
						<Icon iconName={"ArrowBack"} />
					</Box>
					<HStack spacing={2}>
						{assets.map((_, index) => (
							<Box
								key={index}
								w="0.4em"
								h="0.4em"
								borderRadius="50%"
								bg={
									index === selectedIndex
										? "black"
										: "gray.300"
								}
								cursor="pointer"
								onClick={() => setSelectedImageIndex(index)}
							/>
						))}
					</HStack>
					<Box onClick={handleNext} cursor={"pointer"}>
						<Icon iconName={"ArrowForward"} />
					</Box>
				</Flex>
			</Box>
		</Flex>
	);
}
