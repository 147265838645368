import {
	Box,
	Flex,
	Heading,
	IconButton,
	Image,
	Stack,
	Text,
} from "@chakra-ui/react";
import {debounce} from "lodash";

import React, {useState, useEffect, useRef} from "react";
import BinaryTriangle from "./BinaryTriangle";

function convertToBinary(text) {
	return text
		.split("")
		.map(char => char.charCodeAt(0).toString(2).padStart(8, "0"))
		.join(" ");
}

export default function MessagePreview({userInputs}) {
	const [binaryMessage, setBinaryMessage] = useState("");
	const [previewHeight, setPreviewHeight] = useState(0);
	const previewContainer = useRef(null);

	useEffect(() => {
		setBinaryMessage(convertToBinary(userInputs.message_text));
	}, [userInputs]);

	useEffect(() => {
		const resize = debounce(() => {
			console.log(previewContainer.current?.offsetHeight);
			setPreviewHeight(previewContainer.current?.offsetHeight);
		}, 250);
		resize();
		window.addEventListener("resize", resize);

		return () => {
			window.removeEventListener("resize", resize);
		};
	}, [previewContainer]);

	return (
		<Flex
			flexDir={"column"}
			alignItems={"center"}
			bgColor={"black"}
			pt={"10"}
			w={"100%"}
			h={"100%"}
			position={"relative"}
			overflow={"hidden"}
			ref={previewContainer}>
			<Box
				textAlign={"center"}
				mb={4}
				color={"white"}
				position={"absolute"}
				zIndex={5}
				bottom={5}>
				<Heading as={"h2"} size={"sm"}>
					So sieht Dein Funkspruch ins Weltall aus!
				</Heading>
			</Box>
			<Box zIndex={0} opacity={0.7} pos={"absolute"} top={0}>
				<BinaryTriangle
					totalHeight={previewHeight * 0.83 || 0}
					binaryString={binaryMessage.split(" ").join("")}
				/>
			</Box>
			<Box
				pos={"absolute"}
				w={"100%"}
				h={"100%"}
				top={0}
				bottom={0}
				zIndex={1}
				bg={
					"linear-gradient(0deg, rgba(29,30,41,0) 13%, rgba(0,0,0,0.5) 100%)"
				}></Box>
			<Box
				w={"100%"}
				h={"100%"}
				bg={"url('/images/twinklestars_black.svg')"}
				bgSize={"cover"}
				pos={"absolute"}
				zIndex={2}></Box>
			<Image
				pos={"absolute"}
				bottom={0}
				zIndex={3}
				h={"30%"}
				src={"/images/earth.png"}></Image>
		</Flex>
	);
}
