import {useQuery} from "@apollo/client";
import {
	Button,
	Checkbox,
	Flex,
	FormErrorMessage,
	Heading,
	Select,
	SelectField,
	Text,
} from "@chakra-ui/react";
import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import {client} from "../..";
import {mutateAndGetNewCart, setFormValues} from "../../helpers";
import {
	GET_CUSTOMER,
	SET_ADDRESS,
	SET_CUSTOMER,
	SET_SHIPPING_IS_BILLING,
} from "../../queries";
import {useStore} from "../../Store";
import {Link, useNavigate} from "react-router-dom";
import NormalBox from "../elements/NormalBox";
import Seperator from "../layout/Seperator";
import {FormField} from "./FormField";
import AddressForm from "./AddressForm";

export default function CustomerDataForm({
	errors,
	checkoutUserData,
	updateCheckoutUserData,
}) {
	const cart = useStore(state => state.cart);
	const user = useStore(state => state.user);
	const setCart = useStore(state => state.setCart);
	const navigate = useNavigate();

	async function updateCustomer(data) {
		let newCart = cart;
		if (!user) {
			/* Update User */
			newCart = await mutateAndGetNewCart(SET_CUSTOMER, {
				...data,
			});
			console.log(newCart);
		} else {
		}
		await setCart(newCart);
		navigate("/kasse/versand");
	}

	if (user) {
		return (
			<Flex flexDir={"column"} textAlign={"center"}>
				<H2>Hey {user.firstName}</H2>
				<Text>Deine E-Mail-Adresse lautet: {user.emailAddress}</Text>
				<Text>
					Deine Daten kannst Du jederzeit in Deinem Profil ändern.
				</Text>
				<Link to={"/login"}>Daten ändern</Link>
			</Flex>
		);
	}

	return (
		<Flex flexDir={"column"} gap={3}>
			<NormalBox bgColor={"blue.50"} color={"blue.600"}>
				Hast Du bereits einen Account?
				<Link to={"/login"}>Login!</Link>
			</NormalBox>
			<Seperator />
			<H2>Kontaktdaten</H2>
			<MultiFieldRow>
				<FormField
					errors={errors}
					onChange={updateCheckoutUserData}
					value={checkoutUserData.firstName}
					label={"Vorname"}
					inputProps={inputProps["firstName"]}
				/>
				<FormField
					errors={errors}
					onChange={updateCheckoutUserData}
					value={checkoutUserData.lastName}
					label={"Nachname"}
					inputProps={inputProps["lastName"]}
				/>
			</MultiFieldRow>
			<FormField
				errors={errors}
				onChange={updateCheckoutUserData}
				value={checkoutUserData.emailAddress}
				label={"E-Mail Adresse"}
				inputProps={inputProps["emailAddress"]}
			/>
			<FormField
				errors={errors}
				onChange={updateCheckoutUserData}
				value={checkoutUserData.phoneNumber}
				label={"Telefon"}
				inputProps={inputProps["phoneNumber"]}
				help={"Für Rückfragen zu Deiner Bestellung"}
			/>
			<AddressForm
				errors={errors}
				type={"billing"}
				data={checkoutUserData}
				changeData={updateCheckoutUserData}
				cart={cart}
			/>
		</Flex>
	);
}

export const inputProps = {
	emailAddress: {
		name: "emailAddress",
		type: "email",
		required: true,
		autoComplete: "email",
	},
	phoneNumber: {
		name: "phoneNumber",
		type: "text",
		autoComplete: "tel",
	},
	firstName: {
		name: "firstName",
		type: "text",
		autoComplete: "given-name",
		required: true,
	},
	lastName: {
		name: "lastName",
		type: "text",
		autoComplete: "family-name",
		required: true,
	},
	streetLine1: {
		name: "streetLine1",
		type: "text",
		autoComplete: "address-line1",
		required: true,
	},
	streetLine2: {
		name: "streetLine2",
		type: "text",
		autoComplete: "address-line2",
	},
	postalCode: {
		name: "postalCode",
		type: "text",
		autoComplete: "postal-code",
		required: true,
	},
	city: {
		name: "city",
		required: true,

		type: "text",
	},
	countryCode: {
		name: "countryCode",
		required: true,
	},
	fullName: {
		name: "fullName",
		type: "text",
		autoComplete: "name",
		required: true,
	},
};
export function MultiFieldRow({children}) {
	return <Flex gap={3} flexWrap={["wrap",null,"nowrap"]}>{children}</Flex>;
}

export function H2({children}) {
	return (
		<Heading as={"h2"} size={"sm"} mb={5}>
			{children}
		</Heading>
	);
}
