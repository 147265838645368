export async function makeCall(endpoint, method, body, raw) {
	let res = await fetch(process.env.REACT_APP_DU_API + endpoint, {
		method: method || "GET",
		headers: raw ? undefined : {"Content-Type": "application/json"},
		body: raw ? body : JSON.stringify(body),
	});

	let json;
	try {
		json = await res.json();
	} catch (error) {
		json = res;
	}
	return json;
}
