import {FormControl, FormLabel, Switch} from "@chakra-ui/react";
import React from "react";
import {useId} from "react";

export default function SlideSwitch({last, label, isChecked, change, keyName}) {
	const id = useId();
	return (
		<FormControl
			onChange={val => {
                change(keyName,val.target.checked)
			}}
			display={"flex"}
			justifyContent={"space-between"}
            alignItems={"center"}
			border={"1px solid"}
            
			p={3}
			borderColor={"gray.300"}
			borderBottom={!last ? "none" : ""}
            >
			<FormLabel m={0} htmlFor={id} fontWeight={"normal!important"}>
				{label}
			</FormLabel>
			<Switch id={id} isChecked={isChecked} size={"lg"} />
		</FormControl>
	);
}
