import {Box, Flex, Heading} from "@chakra-ui/react";
import React from "react";
import {useEffect} from "react";
import Footer from "./Footer";
import {useLocation} from "react-router-dom";
import {trackPageview} from "../tracking/Tracking";
import Header from "./Header";

export const maxPageWidth = "1100px";
export default function Page({
	children,
	heading,
	fullsize,
	footer,
	description,
	title,
	header = true,
}) {
	const location = useLocation();

	useEffect(() => {
		trackPageview();
	}, [location]);

	useEffect(() => {
		document.title =
			(title || heading) +
			" | Weltall-Schmuck mit Bedeutung von DearUniverse";
		setDescription(
			description ||
				(title || heading) + " - Eine Seite im Shop von DearUniverse"
		);
	}, [heading]);

	function setDescription(_desc) {
		document
			.querySelector('meta[name="description"]')
			.setAttribute("content", _desc);
	}

	if (fullsize)
		return (
			<>
				{header && <Header />}
				{children}
				{footer && <Footer />}
			</>
		);

	return (
		<>
			<Header />
			<Flex as={"main"} flex={1} w={"100%"}>
				<Flex
					className="page"
					flex={1}
					flexDir={"column"}
					minH={"100vh"}
					alignItems={"center"}>
					<Flex
						maxW={maxPageWidth}
						w={"100%"}
						className="main"
						flexDir={"column"}
						py={fullsize ? "0" : [4, 10]}
						px={fullsize ? "0" : [2, null, 5]}
						flex={1}>
						{!!heading && (
							<Heading as="h1" textAlign={"center"} mb={3}>
								{heading}
							</Heading>
						)}
						{children}
					</Flex>
					{footer && <Footer />}
				</Flex>
			</Flex>
		</>
	);
}
