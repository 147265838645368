import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightAddon,
	useToast,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {client} from "../..";
import {REQUEST_PASSWORD_RESET, RESET_PASSWORD} from "../../queries";
import NormalBox from "../elements/NormalBox";
import Page from "../layout/Page";
import Icon from "../elements/Icon";

export default function PasswordReset() {
	const [loading, setLoading] = useState(false);
	const [pwVisible, setPwVisible] = useState(false);
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");

	const {token} = useParams();
	const toast = useToast();
	const navigate = useNavigate();

	async function resetPw() {
		setLoading(true);
		if (token) {
			let res = await client.mutate({
				mutation: RESET_PASSWORD,
				variables: {token, password},
			});
			if (res.data) {
				toast({
					title: "Das hat geklappt!",
					description:
						"Du kannst Dich jetzt mit Deinem neuen Passwort anmelden.",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
				navigate("/user");
			} else {
				toast({
					title: "Da ist etwas schief gelaufen...",
					description:
						"Bitte starte den Vorgang erneut, oder wende Dich an den Support.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				navigate("/password-reset");
			}
		} else {
			let res = await client.mutate({
				mutation: REQUEST_PASSWORD_RESET,
				variables: {emailAddress: email},
			});
			if (
				res.data &&
				res.data.requestPasswordReset.__typename === "Success"
			) {
				toast({
					title: "Schaue in Deine Mails",
					description:
						"Wenn wir ein Kundenkonto mit dieser Mailadresse haben, haben wir Dir einen Link zum zurücksetzen Deines Passworts gesendet",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Da ist etwas schief gelaufen...",
					description:
						"Bitte starte den Vorgang erneut, oder wende Dich an den Support.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				navigate("/password-reset");
			}
		}
		setLoading(false);
	}

	return (
		<Page title={"Passwort zurücksetzen"} heading={"Passwort zurücksetzen"}>
			<Flex justifyContent={"center"}>
				<NormalBox width={"lg"} flexDir={"column"}>
					{token ? (
						<FormControl id="password">
							<FormLabel>Neues Password</FormLabel>
							<InputGroup>
								<Input
									type={pwVisible ? "text" : "password"}
									value={password}
									onChange={e => {
										setPassword(e.target.value);
									}}
								/>
								<InputRightAddon
									onClick={() => {
										setPwVisible(!pwVisible);
									}}>
									<Icon
										iconName={pwVisible ? "hide" : "show"}
									/>
								</InputRightAddon>
							</InputGroup>
						</FormControl>
					) : (
						<FormControl id="email">
							<FormLabel>Deine E-Mail Adresse</FormLabel>
							<Input
								type="email"
								value={email}
								onChange={e => {
									setEmail(e.target.value);
								}}
							/>
						</FormControl>
					)}
					<Button mt={5} isLoading={loading} onClick={resetPw}>
						Passwort zurücksetzen
					</Button>
				</NormalBox>
			</Flex>
		</Page>
	);
}
