import {Select} from "@chakra-ui/react";
import React from "react";
import {inputProps, MultiFieldRow} from "./CustomerDataForm";
import {FormField} from "./FormField";
import {client} from "../..";
import {getFormData} from "../checkoutFlow/helper";
import {useStore} from "../../Store";
import {SET_SHIPPING_ADDRESS} from "../../queries";

export default function AddressForm({refreshOnCountryChange, formRef}) {
	const setCart = useStore(state => state.setCart);
	return (
		<>
			<FormField
				label={"Vollständiger Name"}
				inputProps={inputProps["fullName"]}
			/>
			<MultiFieldRow>
				<FormField
					label={"Straße + Hausnummer"}
					inputProps={inputProps["streetLine1"]}
				/>
				<FormField
					label={"Zusätzliche Adressangaben"}
					inputProps={inputProps["streetLine2"]}
				/>
			</MultiFieldRow>
			<MultiFieldRow>
				<FormField
					label={"Postleitzahl"}
					inputProps={inputProps["postalCode"]}
				/>
				<FormField label={"Stadt"} inputProps={inputProps["city"]} />
			</MultiFieldRow>
			<Select
				onChange={async () => {
					if (refreshOnCountryChange) {
						const data = getFormData(formRef.current);
						let result = await client.mutate({
							mutation: SET_SHIPPING_ADDRESS,
							variables: data,
							fetchPolicy: "no-cache",
						});
						const newCart = result.data.result;
						await setCart(newCart);
					}
				}}
				placeholder="Wähle ein Land *"
				size={"sm"}
				{...inputProps.countryCode}
				bgColor={"white"}>
				<option value={"DE"}>Deutschland</option>
				<option value={"AT"}>Österreich</option>
			</Select>
		</>
	);
}
