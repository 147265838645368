import {Box, Button, Flex, Heading, Stack, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import ReviewCards from "./ReviewCards";
import ReviewOverview from "./ReviewOverview";
import Icon from "../elements/Icon";

export default function ProductReviews({reviews}) {
	const reviewsPerPage = 5;
	const [minIndex, setMinIndex] = useState(0);
	const [maxIndex, setMaxIndex] = useState(reviewsPerPage);

	function changePage(dir) {
		if (dir === -1 && minIndex === 0) return;
		if (dir === 1 && maxIndex >= reviews.items.length) return;
		setMinIndex(minIndex + reviewsPerPage * dir);
		setMaxIndex(maxIndex + reviewsPerPage * dir);
	}

	return (
		<Box>
			<Heading
				as="h2"
				size="lg"
				mb={5}
				textAlign={"center"}
				id={"reviews"}>
				Reviews
			</Heading>
			<ReviewOverview reviews={reviews} />
			<Stack spacing={4}>
				<ReviewCards
					reviews={reviews}
					minIndex={minIndex}
					maxIndex={maxIndex}
				/>
				<Flex
					justifyContent={"space-between"}
					alignItems={"center"}
					mt={5}>
					<Button
						width={"min-content"}
						isDisabled={minIndex === 0}
						onClick={() => {
							changePage(-1);
						}}>
						<Icon icon={"minus"} />
					</Button>
					<Text>
						Seite {minIndex / reviewsPerPage + 1} von{" "}
						{Math.ceil(reviews.items.length / reviewsPerPage)}
					</Text>
					<Button
						width={"min-content"}
						isDisabled={maxIndex >= reviews.items.length}
						onClick={() => {
							changePage(1);
						}}>
						<Icon icon={"plus"} />
					</Button>
				</Flex>
			</Stack>
		</Box>
	);
}
