import {
	Flex,
	Box,
	Image,
	Heading,
	Text,
	Divider,
	SimpleGrid,
	Button,
} from "@chakra-ui/react";
import React, {useEffect} from "react";
import StarButton from "../elements/StarButton";
import StepByStepProcess from "./StepByStepProcess";
import StepsElement from "../elements/StepsElement";
import {maxPageWidth} from "../layout/Page";
import {useRef} from "react";
import {Link, Navigate, useLocation} from "react-router-dom";
import ImageAndText from "../elements/ImageAndText";
import {boxShadow} from "../../theme/foundations/shadow";
import Icon from "../elements/Icon";
import QuickLinks from "../elements/QuickLinks";
import {quickLinks} from "../pages/AllProducts";

export default function HomeExplain() {
	const headingRef = useRef(null);
	const location = useLocation();
	useEffect(() => {
		if (window.location.hash === "#explain") {
			headingRef.current.scrollIntoView({behavior: "smooth"});
		}
	}, [location]);

	return (
		<>
			<Flex
				direction="column"
				align="center"
				justify="center"
				m={4}
				maxW={maxPageWidth}
				pt={10}>
				<Heading ref={headingRef} id="explain" as="h2">
					Versende eine Nachricht ins Weltall
				</Heading>
				<Heading as="h3" size="md" my={2} className="decorative">
					Schmuck mit persönlicher Bedeutung
				</Heading>

				<Flex
					direction={{base: "column", md: "row"}}
					my={4}
					gap={3}
					alignItems={"center"}
					justifyContent={"center"}>
					<Flex flexDir={"row"} justifyContent={"right"}>
						<Image
							src="/images/explain.jpg"
							alt="Frau trägt Kette von DearUniverse"
							maxH={["200px", "300px", null, "400px"]}
						/>
						<Image
							ml={[-10, null, -20]}
							zIndex={1}
							src="/images/dashboard.webp"
							alt="Mockup vom DearUniverse Dashboard"
							// filter={"drop-shadow(0px 5px 4px #000000bb)"}
							maxH={["200px", "300px", "400px"]}
						/>
					</Flex>
					<Box flex="0.5" p={4}>
						<Text>
							Verewige ein Bild, ein Video, Wünsche und
							Erinnerungen in einem Funkspruch, den wir ins
							Weltall schicken.{" "}
							<b>
								Der Funkspruch wird für immer mit
								Lichtgeschwindigkeit durchs Weltall fliegen.
							</b>
						</Text>
						<Text>
							<b>
								Scanne Deine Ewigkeitskette mit Deinem
								Smartphone,{" "}
							</b>
							so siehst Du in Echtzeit, wo sich Deine Nachricht
							auf ihrer unendlichen Reise befindet.
						</Text>
						<Flex
							justifyContent={["flex-start", null, "center"]}
							mt={5}>
							<StarButton
								mx={"auto"}
								w={"max-content"}
								link={"/product/necklace"}>
								Kette aussuchen
							</StarButton>
						</Flex>
					</Box>
				</Flex>

				<Divider my={10} />

				<Heading as="h2" size="md" mb={10}>
					So funktioniert es:
				</Heading>

				<StepsElement
					steps={[
						{
							heading: "Wähle eine Kette",
							text: "Wähle Deine persönliche Ewigkeitskette aus. Plastik und Versandkosten finden wir doof, deshalb kommen unsere Ketten ohne aus!",
						},
						{
							heading: "Funkspruch erstellen",
							text: "In 2 Minuten erstellst Du Deinen Funkspruch ans Universum. Oder Du lässt Dir mehr Zeit, Deine Nachricht ist ja schließlich für immer unterwegs!",
						},
						{
							heading: "Nachricht verfolgen",
							text: "Halte Dein Smartphone an den Anhänger und es öffnet sich Dein Dashboard. Dort siehst Du immer, wo sich Deine Nachricht gerade befindet.",
						},
					]}
					direction={"row"}
				/>
				<Divider my={10} />
				<QuickLinks />
				<Box>
					<Link to={"/products"}>
						<Button>Alle Produkte ansehen</Button>
					</Link>
				</Box>
				<Divider my={10} />

				<Box boxShadow={boxShadow}>
					<ImageAndText
						imageSrc={"/images/Herstellung Handarbeit.jpg"}
						alt={"Weltall Schmuck aus Handarbeit"}>
						<Heading as="h3">100% Handarbeit</Heading>
						<Text>
							Wir stellen alle Schmuckstücke in Handarbeit her.
							Außerdem arbeiten wir ausschließlich mit Lieferanten
							aus Deutschland zusammen. Das hält die Lieferwege
							kurz und Du kannst Dir sicher sein, dass Du{" "}
							<b>keinen billigen Modeschmuck</b> bekommst.
						</Text>
					</ImageAndText>
					<ImageAndText
						imageSrc={"/images/paper.jpg"}
						alt={"Schmuck Plastikfrei"}
						switchSide={true}>
						<Heading as="h3">Plastikfrei und Klimapositiv</Heading>
						<Text>
							Unsere Schmuckstücke kommen ohne Plastik bei Dir an.
							Die Versandemissionen werden kompensiert und ein
							Teil unseres Umsatzes wird direkt in
							Aufforstungsprojekte investiert.{" "}
							<b>
								Mit jeder Bestellung tust Du etwas Gutes für
								unseren Planeten!
							</b>
						</Text>
					</ImageAndText>
					<ImageAndText
						imageSrc={"/images/schatulle.jpg"}
						alt={
							"Closeup Weltall Schmuck mit Funkspruch ins Weltall"
						}>
						<Heading as="h3">Absolut einzigartig!</Heading>
						<Text>
							Schmuck, der mit einem unendlichen Funkspruch
							verbunden ist, der bis in alle Ewigkeit durchs
							Universum fliegt?{" "}
							<b>Das gibt es wirklich nur ein Mal</b> auf der
							Welt.{" "}
							<Link to={"/product/necklace"}>
								Sichere Dir jetzt Dein Unikat!
							</Link>
						</Text>
					</ImageAndText>
				</Box>
				<Divider my={10} />

				<Flex
					direction={{base: "column", md: "row"}}
					my={4}
					alignItems={"center"}>
					<Flex flex="1" flexDir={"row"} justifyContent={"center"}>
						<Image
							className="masked-image"
							src="/images/kolja.jpg"
							alt="Teamfoto DearUniverse"
							maxH={["200px", "300px", null, "400px"]}
						/>
					</Flex>
					<Box flex="1" p={4}>
						<Heading as={"h2"}>Mein Versprechen</Heading>
						<Text>
							Seit 2020 haben wir mit DearUniverse tausende
							Funksprüche ins Weltall abgesetzt. 2023 habe ich
							mich dann als Gründer selbstständig gemacht. Das
							Ziel ist dabei immer gleich geblieben:
						</Text>
						<Text fontWeight={"bold"} color={"darkBlue"}>
							Deine Erinnerungen, Wünsche und Träume für die
							Ewigkeit im Universum zu bewahren.
						</Text>
						<a
							href="https://dearuniverse.de/about"
							target="_blank"
							rel="noreferrer">
							<Icon iconName={"newtab"}>Zum Team</Icon>
						</a>
					</Box>
				</Flex>
			</Flex>
			<Box
				w={"100%"}
				bgColor={"darkBlue"}
				bgImage={"url('/images/milkyway_large.jpg')"}
				bgSize={"cover"}
				bgPos={"center"}
				textAlign={"center"}>
				<Flex
					p={[5, 10, 20]}
					bgColor={"rgba(0,0,0,0.5)"}
					justifyContent={"center"}
					alignItems={"center"}
					flexDir={"column"}>
					<Text color={"white"} m={0} fontSize={"2.5em"} mb={-5}>
						Das Universum hört Dich...
					</Text>
					<Text
						color={"white"}
						m={0}
						fontSize={"3.5em!important"}
						className="decorative">
						Sag etwas!
					</Text>
					<Box>
						<Link to="/products">
							<Button onClick={() => {}}>
								Jetzt Produkte ansehen!
							</Button>
						</Link>
					</Box>
				</Flex>
			</Box>
		</>
	);
}
