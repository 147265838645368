import {Box, Flex} from "@chakra-ui/react";
import React from "react";
import Icon from "../elements/Icon";

export default function ReviewOverview({reviews}) {
	function getOverviewRow(stars) {
		return (
			<Flex justifyContent={"space-between"}>
				<Box color={"yellow.500"}>
					{Array.from({length: 5}).map((_, i) => (
						<Icon
							key={"star" + i}
							icon={"star"}
							iconStyle={i < stars ? "filled" : undefined}
						/>
					))}
				</Box>
				<Box ml={5}>
					(
					{
						reviews.items.filter(review => review.rating === stars)
							.length
					}{" "}
					Bewertungen)
				</Box>
			</Flex>
		);
	}

	return (
		<Flex my={5} justifyContent={"center"}>
			<Flex flexDir={"column"} maxW={"350px"}>
				{getOverviewRow(5)}
				{getOverviewRow(4)}
				{getOverviewRow(3)}
				{getOverviewRow(2)}
				{getOverviewRow(1)}
			</Flex>
		</Flex>
	);
}
