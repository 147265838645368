import React from 'react'
import FormSection from '../productForms/FormSection';
import RadioButtons from '../productForms/RadioButtons';

export default function PsPlanetPoster({
	changeOption,
	options,
	optionGroups,
	product,
	priceWithTax,
}) {
	return (
		<FormSection title={"Wähle eine Größe"}>
			<RadioButtons
				changeOption={changeOption}
				optionName="size"
				optionGroups={optionGroups}
				options={options}
				curruntPrice={priceWithTax}
				product={product}
			/>
		</FormSection>
	);
}
