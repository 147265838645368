import {Box, Button, Flex, Heading, Image, Text} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {HeaderHeight} from "../layout/Header";
import {PreheaderHeight} from "../layout/PreHeader";

export default function HomeHero() {
	//const {refBG} = useParallax({speed: -20});
	const navigate = useNavigate();
	const [headingWordIndex, setHeadingWordIndex] = useState(0);
	const headingWords = [
		{artikel: "Dein", word: "Lieblingsbild"},
		{artikel: "Deine", word: "Glückwünsche"},
		{artikel: "Deine", word: "Liebesbekundung"},
		{artikel: "Dein", word: "Haustier"},
		{artikel: "Deine", word: "Kinder"},
		{artikel: "Deine", word: "Träume"},
		{artikel: "Deine", word: "Eltern"},
		{artikel: "Dein", word: "Hochzeitsbild"},
		{artikel: "Deine", word: "Oma"},
	];
	const [headingWord, setHeadingWord] = useState(headingWords[0]);

	useEffect(() => {
		const timer = setInterval(() => {
			setHeadingWordIndex(
				headingWords.length === headingWordIndex + 1
					? 0
					: headingWordIndex + 1
			);
		}, 4000);

		return () => {
			clearInterval(timer);
		};
	}, [headingWordIndex]);

	useEffect(() => {
		setHeadingWord(headingWords[headingWordIndex]);
	}, [headingWordIndex]);

	const [typedWord, setTypedWord] = useState("");
	const [typingIndex, setTypingIndex] = useState(0);

	useEffect(() => {
		setTypedWord("");
		setTypingIndex(0);
	}, [headingWord]);

	useEffect(() => {
		if (typingIndex < headingWord.word.length) {
			const timer = setTimeout(() => {
				setTypedWord(prev => prev + headingWord.word[typingIndex]);
				setTypingIndex(prev => prev + 1);
			}, 70);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [typingIndex, headingWord]);
	return (
		<Flex
			w={"100%"}
			pos={"relative"}
			alignItems={["center", null, "flex-start"]}
			overflow={"hidden"}
			minH={[
				"60vh",
				null,
				`calc(100vh - ${HeaderHeight} - ${PreheaderHeight})`,
			]}
			h={[
				"100%",
				null,
				`calc(100vh - ${HeaderHeight} - ${PreheaderHeight})`,
			]}
			flexDir={["column-reverse", null, "row"]}>
			<Flex
				flexDir={"column"}
				justifyContent={"center"}
				flex={1}
				height={"100%"}
				gap={5}
				p={[2, 4, 5, 10]}>
				<Box>
					<Heading as={"h1"} fontSize={["7vw", null, "3.5vw"]} mb={1}>
						Wir verewigen {headingWord.artikel} <br></br>
						<Text as={"span"} color={"accent"}>
							{typedWord}
							<Box
								as="span"
								w="2px"
								h="1.1em"
								transform={"translateY(5px)"}
								bg="accent"
								display="inline-block"
								ml={1}
								animation="blink 1s infinite"
								css={{
									"@keyframes blink": {
										"0%": {opacity: 1},
										"50%": {opacity: 0},
										"100%": {opacity: 1},
									},
								}}
							/>
						</Text>
						<br></br>
						als Funkspruch im All!
					</Heading>
					<Heading
						as={"span"}
						size={["xl", null, "2xl"]}
						className="decorative">
						Zertifikat oder scannbare Ewigkeitskette als Andenken!
					</Heading>
				</Box>
				<Text>
					Senden. Scannen. Erinnern. <br />
					Schicke Bilder und Texte als Funkspruch ins All und verfolge
					ihre ewige Reise in Deinem Dashboard.
				</Text>
				<Flex justifyContent={"center"} flexDir="column">
					<Box>
						<Image
							src="/images/customers.png"
							alt="Bisherige Kunden-Portraits"
							h={"40px"}
							w={"auto"}></Image>
					</Box>

					<Text
						mt={-2}
						lineHeight={"40px"}
						verticalAlign={"middle"}
						m={0}>
						Über{" "}
						<Text
							as={"span"}
							color={"darkBlue"}
							fontWeight={"semibold"}>
							4.031 Momente, Erinnerungen und Wünsche
						</Text>{" "}
						verewigt!
					</Text>
				</Flex>
				<Flex gap={5} flexDir={"column"}>
					<Link to={"/products"}>
						<Button>Alle Produkte ansehen</Button>
					</Link>
					<Link to={"#explain"}>
						<Button variant={"outline"}>
							Wie funktioniert das?
						</Button>
					</Link>
				</Flex>
			</Flex>

			<Box flex={1} height={"100%"}>
				<Image
					objectFit={"cover"}
					alt="Ewigkeitsketten von DearUniverse"
					height={"100%"}
					width={"100%"}
					objectPosition={"center"}
					src="/images/hero.jpg"></Image>
			</Box>
		</Flex>
	);
}
