import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import React from "react";
import {useStore} from "../../Store";
import LoginForm from "../auth/LoginForm";

export default function OneClickRegistration({order}) {
	const user = useStore(state => state.user);
	console.log(user);
	if (user) return "";
	// order.customer.emailAddress
	// order.customer.firstName
	// order.customer.lastName

	// Add a chakra form with

	return (
		<Flex gap={5} flexDir={"column"}>
			<Heading as={"h2"} size={"lg"} textAlign={"center"}>
				Lieferung verfolgen:
			</Heading>
			<Text>
				Um auf dem neusten Stand zu bleiben, kannst Du ganz einfach
				einen Account erstellen. In Deinem Profil findest Du alle Infos
				zu Deinen Bestellungen.
			</Text>
			<LoginForm register oneClick order={order}/>
		</Flex>
	);
}
