import React, {useEffect, useState} from "react";
import {client} from "../..";
import {GIFTCARD_DESIGNS} from "../../queries";
import VariantChooser from "../elements/VariantChooser";
import FormContainer from "./FormContainer";
import FormSection from "./FormSection";
import {
	Box,
	FormControl,
	InputGroup,
	InputRightAddon,
	Input,
	FormLabel,
	VStack,
	Flex,
	Text,
} from "@chakra-ui/react";
import GiftCardConfigurator from "./GiftCardConfigurator";
import Icon from "../elements/Icon";

export default function GiftCard({userInputs, changeUserInputs}) {
	const [designs, setDesigns] = useState([]);

	useEffect(() => {
		async function getDesigns() {
			let response = await client.query({query: GIFTCARD_DESIGNS});
			setDesigns(response.data.giftCardTemplates);
		}
		getDesigns();
	}, []);

	return (
		<FormContainer>
			<FormSection title={"Konfiguriere Deinen Gutschein"}>
				<FormControl>
					<Flex flexDir={"column"} gap={3}>
						<FormLabel>
							Betrag
							<InputGroup>
								<Input
									type="number"
									placeholder="50"
									value={userInputs.value / 100 || ""}
									onChange={e => {
										changeUserInputs(
											"value",
											e.target.value * 100 || ""
										);
									}}
								/>
								<InputRightAddon children="€" />
							</InputGroup>
						</FormLabel>
						<Text color={"gray.600"} fontSize={"sm"}>
							<Icon icon={"info"} /> Der Gutschein wird an Deine
							Mailadresse gesendet.
						</Text>

						{/* Der Gutschein wird standartmäßig an den Besteller gesendet. 
						Sollte die funktionalität des Sendens auf Termin einmal gewünscht sein, 
					können diese Felder benutzt werden. */}
						{/* <FormField
							type={"email"}
							title={
								"An welche Mailadresse sollen wir den Gutschein schicken?"
							}
							placeholder={"deine@mailadresse.de"}
							fieldName={"recipientEmailAddress"}
							required
							changeUserInputs={changeUserInputs}
							value={userInputs.recipientEmailAddress || ""}
							/>
						<FormField
						type={"text"}
						title={"Grußbotschaft"}
							placeholder={"Liebe/r..."}
							fieldName={"message"}
							changeUserInputs={changeUserInputs}
							value={userInputs.message || ""}
							/>
							<FormField
							type={"datetime-local"}
							title={"Versanddatum"}
							fieldName={"deliveryDate"}
							changeUserInputs={changeUserInputs}
							value={userInputs.deliveryDate || ""}
						/> */}
					</Flex>
				</FormControl>
			</FormSection>
			<FormSection title={"Wähle ein Motiv"}>
				<VariantChooser
					options={designs.map(design => {
						return {
							icon: null,
							image: design.preview,
							val: design.id,
						};
					})}
					keyName={"templateId"}
					change={changeUserInputs}
					value={userInputs.templateId}
					selectedColor={"blue.500"}
				/>
			</FormSection>
			<FormSection
				title={"Nicht sicher mit dem Betrag?"}
				collapsible
				defaultOpen={false}>
				<Text>
					Wähle eine Kombination von Produkten. Wir berechnen den
					Gesamtwert.
				</Text>
				<GiftCardConfigurator
					changeUserInputs={changeUserInputs}
					userInputs={userInputs}
				/>
			</FormSection>
		</FormContainer>
	);
}
