import React, {useEffect, useRef} from "react";
import {Box, Button} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";

class Starfield {
	constructor(canvas) {
		this.canvas = canvas;
		this.ctx = canvas.getContext("2d");
		this.stars = this.createStars(90);
		this.frameRate = 1000 / 15;
		this.lastUpdate = Date.now();
		this.loop = this.loop.bind(this);
	}

	createStars(n) {
		let width = (this.canvas.width = this.canvas.offsetWidth);
		let height = (this.canvas.height = this.canvas.offsetHeight);
		return new Array(n).fill().map(() => ({
			x: Math.random() * width,
			y: Math.random() * height,
			size: Math.random() * 0.5,
			brightness: Math.random() + 0.5,
		}));
	}

	drawStar(star) {
		this.ctx.beginPath();
		this.ctx.arc(
			star.x,
			star.y,
			star.size * star.brightness,
			0,
			2 * Math.PI
		);
		this.ctx.fillStyle = `rgba(255,255,255,${star.brightness})`;
		this.ctx.fill();
	}

	loop() {
		let now = Date.now();
		let elapsed = now - this.lastUpdate;

		if (elapsed > this.frameRate) {
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.stars.forEach(star => {
				star.y -= star.size * 0.5;
				if (star.y < -star.size)
					star.y = this.canvas.height + star.size;
				star.x += star.size * 0.7;
				if (star.x > this.canvas.width + star.size) star.x = -star.size;
				this.drawStar(star);
			});
			this.lastUpdate = now - (elapsed % this.frameRate);
		}
		this.raf = requestAnimationFrame(this.loop);
	}

	start() {
		this.loop();
	}

	stop() {
		cancelAnimationFrame(this.raf);
	}
}

const StarButton = ({children, link, ...rest}) => {
	const canvasRef = useRef(null);
	const navigate = useNavigate();

	useEffect(() => {
		const canvas = canvasRef.current;
		const starfield = new Starfield(canvas);

		// Using an IntersectionObserver to start and stop the animation based on visibility
		const observer = new IntersectionObserver(
			entries => {
				if (entries[0].isIntersecting) {
					starfield.start();
				} else {
					starfield.stop();
				}
			},
			{threshold: 0.1}
		);

		observer.observe(canvas);

		// Cleanup function to stop animation and disconnect observer when component is unmounted
		return () => {
			starfield.stop();
			observer.disconnect();
		};
	}, []);

	return (
		<Button
			{...rest}
			position="relative"
			bgGradient="linear-gradient(52deg, rgba(87,66,41,1) 0%, rgba(17,17,17,1) 20%, rgba(57,59,65,1) 90%, rgba(87,48,92,1) 100%)"
			border="2px solid rgb(50,50,50)"
			borderRadius="0.4em"
			_hover={{transform: "scale(1.02)"}}
			py={4}
			px={8}
			fontWeight="bold"
			onClick={() => {
				link && navigate(link);
				rest.onClick && rest.onClick();
			}}
			colorScheme="white">
			<Box
				as="span"
				bgGradient="linear(rgba(255,255,255,.92),#fff 80%)"
				bgClip="text"
				fontSize="16px"
				fontWeight="600"
				position="relative"
				zIndex="10">
				{children}
			</Box>
			<Box
				as="canvas"
				ref={canvasRef}
				position="absolute"
				top="0"
				left="0"
				width="100%"
				height="100%"
			/>
		</Button>
	);
};

export default StarButton;
