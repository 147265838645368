import {gql} from "@apollo/client";
import {ACTIVE_ORDER} from "./fragments";

export const GET_STORE_INFO = gql`
	query GetLocations {
		locations {
			id
			name
			description
			photo
		}
	}
`;

export const ADD_ITEM_TO_CART = gql`
	${ACTIVE_ORDER}
	mutation addItemToOrder(
		$variantId: ID!
		$userInputs: String
		$relatedLineId: ID
	) {
		result: addItemToOrder(
			productVariantId: $variantId
			quantity: 1
			customFields: {
				userInputs: $userInputs
				relatedLineId: $relatedLineId
			}
		) {
			...ActiveOrder
		}
	}
`;

export const CHANGE_QUANTITY = gql`
	${ACTIVE_ORDER}
	mutation AdjustOrderLine($quantity: Int!, $orderLineId: ID!) {
		result: adjustOrderLine(
			quantity: $quantity
			orderLineId: $orderLineId
		) {
			...ActiveOrder
		}
	}
`;

export const CHANGE_CUSTOM_FIELDS = gql`
	${ACTIVE_ORDER}
	mutation AdjustOrderLine(
		$orderLineId: ID!
		$quantity: Int!
		$customFields: OrderLineCustomFieldsInput
	) {
		result: adjustOrderLine(
			orderLineId: $orderLineId
			quantity: $quantity
			customFields: $customFields
		) {
			...ActiveOrder
		}
	}
`;

export const GET_CART = gql`
	${ACTIVE_ORDER}
	query {
		result: activeOrder {
			...ActiveOrder
		}
	}
`;

export const CLEAR_CART = gql`
	mutation {
		removeAllOrderLines {
			__typename
		}
	}
`;

export const GET_PRODUCT = gql`
	query product($slug: String!) {
		product(slug: $slug) {
			id
			name
			slug
			description
			reviewsHistogram {
				frequency
				bin
			}
			reviews {
				items {
					createdAt
					rating
					authorName
					body
					summary
					response
				}
			}
			assets {
				id
				source
				customFields {
					isArtistRendering
				}
			}
			customFields {
				shortDescription
				standardProductPage
			}
			optionGroups {
				code
				options {
					code
				}
			}
			variants {
				options {
					group {
						code
					}
					code
					name
				}
				id
				assets {
					id
					source
				}
				name
				price
				priceWithTax
				stockOnHand
				customFields {
					downloadable
					defaultVariant
				}
			}
		}
	}
`;

export const GET_PRODUCTS = gql`
	query {
		products {
			items {
				customFields {
					shortDescription
					standardProductPage
					sortPriority
					addonOnly
				}
				reviews {
					items {
						createdAt
						rating
						authorName
						body
						summary
						response
					}
				}
				id
				slug
				name
				description
				assets {
					source
				}
				variants {
					id
					name
					sku
					price
					priceWithTax
					stockOnHand
				}
			}
		}
	}
`;

export const GET_CUSTOMER = gql`
	query {
		activeCustomer {
			id
			lastName
			firstName
			phoneNumber
			emailAddress
			addresses {
				fullName
				streetLine1
				streetLine2
				postalCode
				country {
					code
				}
			}
		}
	}
`;

export const SET_CUSTOMER = gql`
	${ACTIVE_ORDER}
	mutation setCustomerForOrder(
		$firstName: String!
		$lastName: String!
		$emailAddress: String!
		$phoneNumber: String
	) {
		result: setCustomerForOrder(
			input: {
				firstName: $firstName
				lastName: $lastName
				emailAddress: $emailAddress
				phoneNumber: $phoneNumber
			}
		) {
			... on NoActiveOrderError {
				message
			}
			... on EmailAddressConflictError {
				message
			}
			... on AlreadyLoggedInError {
				message
			}
			...ActiveOrder
		}
	}
`;

export const SET_SHIPPING_ADDRESS = gql`
	${ACTIVE_ORDER}
	mutation setOrderShippingAddress(
		$fullName: String
		$streetLine1: String!
		$streetLine2: String
		$city: String
		$postalCode: String
		$countryCode: String!
		$phoneNumber: String
		$defaultShippingAddress: Boolean
		$defaultBillingAddress: Boolean
	) {
		result: setOrderShippingAddress(
			input: {
				fullName: $fullName
				streetLine1: $streetLine1
				streetLine2: $streetLine2
				city: $city
				postalCode: $postalCode
				countryCode: $countryCode
				phoneNumber: $phoneNumber
				defaultShippingAddress: $defaultShippingAddress
				defaultBillingAddress: $defaultBillingAddress
			}
		) {
			... on NoActiveOrderError {
				message
			}
			...ActiveOrder
		}
	}
`;
export const SET_BILLING_ADDRESS = gql`
	${ACTIVE_ORDER}
	mutation setOrderBillingAddress(
		$fullName: String
		$streetLine1: String!
		$streetLine2: String
		$city: String
		$postalCode: String
		$countryCode: String!
		$phoneNumber: String
		$defaultShippingAddress: Boolean
		$defaultBillingAddress: Boolean
	) {
		result: setOrderBillingAddress(
			input: {
				fullName: $fullName
				streetLine1: $streetLine1
				streetLine2: $streetLine2
				city: $city
				postalCode: $postalCode
				countryCode: $countryCode
				phoneNumber: $phoneNumber
				defaultShippingAddress: $defaultShippingAddress
				defaultBillingAddress: $defaultBillingAddress
			}
		) {
			... on NoActiveOrderError {
				message
			}
			...ActiveOrder
		}
	}
`;

export const GET_SHIPPING_METHODES = gql`
	query {
		eligibleShippingMethods {
			id
			code
			name
			price
			priceWithTax
		}
	}
`;

export const SET_ORDER_CUSTOM_FIELDS = gql`
	${ACTIVE_ORDER}
	mutation setOrderCustomFields($mollieMethod: String) {
		result: setOrderCustomFields(
			input: {customFields: {mollieMethod: $mollieMethod}}
		) {
			...ActiveOrder
		}
	}
`;

export const SET_SHIPPING_METHOD = gql`
	${ACTIVE_ORDER}
	mutation setOrderShippingMethod($shippingMethodId: [ID!]!) {
		result: setOrderShippingMethod(shippingMethodId: $shippingMethodId) {
			...ActiveOrder

			... on IneligibleShippingMethodError {
				message
			}
		}
	}
`;

export const GET_ORDER_BY_CODE = gql`
	${ACTIVE_ORDER}
	query orderByCode($code: String!) {
		result: orderByCode(code: $code) {
			...ActiveOrder
		}
	}
`;

export const REGISTER = gql`
	mutation RegisterCustomerAccount(
		$email: String!
		$firstName: String
		$lastName: String
		$password: String
	) {
		registerCustomerAccount(
			input: {
				emailAddress: $email
				firstName: $firstName
				lastName: $lastName
				password: $password
			}
		) {
			... on MissingPasswordError {
				message
			}
			... on PasswordValidationError {
				message
			}
			... on Success {
				success
			}
		}
	}
`;

export const VERIFY = gql`
	mutation VerifyCustomerAccount($token: String!) {
		verifyCustomerAccount(token: $token) {
			... on VerificationTokenInvalidError {
				message
			}
			... on VerificationTokenExpiredError {
				message
			}
			... on CurrentUser {
				id
				identifier
			}
		}
	}
`;

export const GET_USER = gql`
	query {
		activeCustomer {
			firstName
			lastName
			emailAddress
			id
			addresses {
				fullName
				streetLine1
				streetLine2
				postalCode
				city
				phoneNumber
				country {
					name
				}
			}
			orders {
				items {
					customFields {
						processingData
					}
					code
					fulfillments {
						trackingCode
						state
						method
						lines {
							orderLineId
						}
					}
					state
					orderPlacedAt
					updatedAt
					totalWithTax
					lines {
						id
						quantity
						unitPrice
						customFields {
							userInputs
							relatedLine {
								id
								customFields {
									userInputs
								}
							}
						}
						productVariant {
							product {
								slug
								id
								assets {
									source
								}
							}
							options {
								group {
									code
								}
								code
								name
							}
							id
							name
							priceWithTax
							assets {
								source
							}
							customFields {
								downloadable
							}
						}
					}
				}
			}
		}
	}
`;
export const LOGIN = gql`
	mutation Login($username: String!, $password: String!) {
		login(username: $username, password: $password) {
			... on InvalidCredentialsError {
				message
			}
			... on CurrentUser {
				id
			}
		}
	}
`;

export const GET_MOLLIE_METHODS = gql`
	query {
		molliePaymentMethods(input: {paymentMethodCode: "mollie"}) {
			id
			code
			description
			minimumAmount {
				value
				currency
			}
			maximumAmount {
				value
				currency
			}
			image {
				size1x
				size2x
				svg
			}
		}
	}
`;

export const CREATE_MOLLIE_PAYMENT_INTENT = gql`
	mutation CreateMolliePaymentIntent($mollieMethod: String) {
		createMolliePaymentIntent(
			input: {
				paymentMethodCode: "mollie"
				molliePaymentMethodCode: $mollieMethod
			}
		) {
			... on MolliePaymentIntent {
				url
			}
			... on MolliePaymentIntentError {
				errorCode
				message
			}
		}
	}
`;

export const ADD_GIFTCARD_PAYMENT = gql`
	mutation ($code: String!) {
		result: addPaymentToOrder(
			input: {method: "gift-card-payment", metadata: {code: $code}}
		) {
			__typename
		}
	}
`;

export const TRANSITION_ORDER = gql`
	mutation TransitionOrder($state: String!) {
		transitionOrderToState(state: $state) {
			... on ErrorResult {
				errorCode
				message
			}
		}
	}
`;

export const CHECKOUT_INFO = gql`
	${ACTIVE_ORDER}

	mutation (
		$firstName: String!
		$lastName: String!
		$emailAddress: String!
		$fullName: String
		$streetLine1: String!
		$streetLine2: String
		$city: String!
		$postalCode: String!
		$countryCode: String!
		$phoneNumber: String
		$defaultShippingAddress: Boolean
		$defaultBillingAddress: Boolean
	) {
		setCustomerForOrder(
			input: {
				firstName: $firstName
				lastName: $lastName
				emailAddress: $emailAddress
				phoneNumber: $phoneNumber
			}
		) {
			... on NoActiveOrderError {
				message
			}
			... on EmailAddressConflictError {
				message
			}
			... on AlreadyLoggedInError {
				message
			}
			...ActiveOrder
		}

		result: setOrderBillingAddress(
			input: {
				fullName: $fullName
				streetLine1: $streetLine1
				streetLine2: $streetLine2
				city: $city
				postalCode: $postalCode
				countryCode: $countryCode
				phoneNumber: $phoneNumber
				defaultShippingAddress: $defaultShippingAddress
				defaultBillingAddress: $defaultBillingAddress
			}
		) {
			... on NoActiveOrderError {
				message
			}
			...ActiveOrder
		}
	}
`;

export const CHECKOUT_INFO_WITH_SHIPPING = gql`
	${ACTIVE_ORDER}

	mutation (
		$firstName: String!
		$lastName: String!
		$emailAddress: String!
		$fullName: String
		$streetLine1: String!
		$streetLine2: String
		$city: String!
		$postalCode: String!
		$countryCode: String!
		$phoneNumber: String
		$defaultShippingAddress: Boolean
		$defaultBillingAddress: Boolean
	) {
		setCustomerForOrder(
			input: {
				firstName: $firstName
				lastName: $lastName
				emailAddress: $emailAddress
				phoneNumber: $phoneNumber
			}
		) {
			... on NoActiveOrderError {
				message
			}
			... on EmailAddressConflictError {
				message
			}
			... on AlreadyLoggedInError {
				message
			}
			...ActiveOrder
		}
		setOrderShippingAddress(
			input: {
				fullName: $fullName
				streetLine1: $streetLine1
				streetLine2: $streetLine2
				city: $city
				postalCode: $postalCode
				countryCode: $countryCode
				phoneNumber: $phoneNumber
				defaultShippingAddress: $defaultShippingAddress
				defaultBillingAddress: $defaultBillingAddress
			}
		) {
			... on NoActiveOrderError {
				message
			}
			...ActiveOrder
		}
		result: setOrderBillingAddress(
			input: {
				fullName: $fullName
				streetLine1: $streetLine1
				streetLine2: $streetLine2
				city: $city
				postalCode: $postalCode
				countryCode: $countryCode
				phoneNumber: $phoneNumber
				defaultShippingAddress: $defaultShippingAddress
				defaultBillingAddress: $defaultBillingAddress
			}
		) {
			... on NoActiveOrderError {
				message
			}
			...ActiveOrder
		}
	}
`;

export const CHECKOUT_SHIPPING = gql`
	${ACTIVE_ORDER}

	mutation (
		$shippingMethodId: [ID!]!
		$fullName: String
		$streetLine1: String!
		$streetLine2: String
		$city: String
		$postalCode: String
		$countryCode: String!
		$phoneNumber: String
		$defaultShippingAddress: Boolean
		$defaultBillingAddress: Boolean
	) {
		setOrderShippingMethod(shippingMethodId: $shippingMethodId) {
			...ActiveOrder
			... on IneligibleShippingMethodError {
				message
			}
		}
		result: setOrderShippingAddress(
			input: {
				fullName: $fullName
				streetLine1: $streetLine1
				streetLine2: $streetLine2
				city: $city
				postalCode: $postalCode
				countryCode: $countryCode
				phoneNumber: $phoneNumber
				defaultShippingAddress: $defaultShippingAddress
				defaultBillingAddress: $defaultBillingAddress
			}
		) {
			... on NoActiveOrderError {
				message
			}
			...ActiveOrder
		}
	}
`;

export const ADD_ORDER_TO_SESSION = gql`
	${ACTIVE_ORDER}

	mutation ($code: String!) {
		addOrderToSession(code: $code) {
			...ActiveOrder
		}
	}
`;

export const GIFTCARD_DESIGNS = gql`
	query GetGiftCardTemplates {
		giftCardTemplates {
			id
			preview
		}
	}
`;

export const ADD_GIFTCARD_TO_CART = gql`
	${ACTIVE_ORDER}

	mutation AddGiftCardToOrder($input: AddGiftCardToOrderInput!) {
		result: addGiftCardToOrder(input: $input) {
			...ActiveOrder

			... on ErrorResult {
				errorCode
				message
			}
		}
	}
`;

export const APPLY_GIFTCARD = gql`
	${ACTIVE_ORDER}

	mutation ApplyGiftCardToOrder($code: String!) {
		result: applyGiftCardToOrder(code: $code) {
			...ActiveOrder
			... on ErrorResult {
				errorCode
				message
			}
		}
	}
`;
export const APPLY_COUPON = gql`
	${ACTIVE_ORDER}

	mutation ApplyCouponCode($code: String!) {
		result: applyCouponCode(couponCode: $code) {
			...ActiveOrder
			... on CouponCodeExpiredError {
				errorCode
				message
			}
			... on CouponCodeInvalidError {
				errorCode
				message
			}
			... on CouponCodeLimitError {
				errorCode
				message
			}
		}
	}
`;

export const REMOVE_COUPON = gql`
	${ACTIVE_ORDER}

	mutation RemoveCouponCode($code: String!) {
		result: removeCouponCode(couponCode: $code) {
			...ActiveOrder
		}
	}
`;

export const REMOVE_GIFTCARD = gql`
	${ACTIVE_ORDER}

	mutation RemoveGiftCardFromOrder($code: String!) {
		result: removeGiftCardFromOrder(code: $code) {
			...ActiveOrder
		}
	}
`;

export const REQUEST_PASSWORD_RESET = gql`
	mutation RequestPasswordReset($emailAddress: String!) {
		requestPasswordReset(emailAddress: $emailAddress) {
			__typename
		}
	}
`;

export const RESET_PASSWORD = gql`
	mutation ResetPassword($token: String!, $password: String!) {
		resetPassword(token: $token, password: $password) {
			__typename
		}
	}
`;

export const SUBMIT_PRODUCT_REVIEW = gql`
	mutation SubmitProductReview($input: SubmitProductReviewInput!) {
		submitProductReview(input: $input) {
			id
			state
		}
	}
`;
