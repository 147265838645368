import {Grid, Text, Box} from "@chakra-ui/react";
import React from "react";
import {Link} from "react-router-dom";

const quickLinks = [
	{
		imageSrc: "/images/schatulle.jpg",
		text: "Ewigkeitskette",
		link: "/product/necklace",
	},
	{
		imageSrc: "/images/message.png",
		text: "Funkspruch",
		link: "/product/message",
	},
	{
		imageSrc: "/images/quicklink_starposter.jpg",
		text: "Sternkarte",
		link: "/product/starposter",
	},
	{
		imageSrc: "/images/quicklink_planetposter.jpg",
		text: "Planetenkarte",
		link: "/product/planetposter",
	},
];

export default function QuickLinks({links = quickLinks}) {
	return (
		<Grid
			templateColumns={{base: "repeat(2, 1fr)", md: "repeat(4, 1fr)"}}
			gap={["10px", null, "15px"]}
			my={10}
			w={"100%"}
			maxW={"740px"}
			mx={"auto"}
			justifyContent={"center"}
			justifyItems={"stretch"}>
			{links.map((link, index) => (
				<QuickLink key={index} {...link} />
			))}
		</Grid>
	);
}

function QuickLink({imageSrc, text, link}) {
	return (
		<Link to={link}>
			<Box
				_hover={{transform: "scale(1.04)"}}
				transition={"transform 0.2s ease-in-out"}
				boxShadow={"md"}
				p={3}
				borderRadius={"2xl"}
				overflow={"hidden"}
				w={["100%", null, "170px"]}
				h="90px"
				position="relative"
				cursor={"pointer"}>
				<Box
					bgImage={`url(${imageSrc})`}
					bgPosition="center"
					bgRepeat="no-repeat"
					bgSize="cover"
					position="absolute"
					top="0"
					left="0"
					right="0"
					bottom="0">
					<Box
						position="absolute"
						top="0"
						left="0"
						right="0"
						bottom="0"
						backgroundColor="blackAlpha.600"></Box>
				</Box>
				<Text
					zIndex="1"
					color="white"
					fontWeight="bold"
					textAlign="center"
					position="absolute"
					width="100%"
					top="50%"
					left="50%"
					transform="translate(-50%, -50%)">
					{text}
				</Text>
			</Box>
		</Link>
	);
}
