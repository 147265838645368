import {Box, Flex, Text} from "@chakra-ui/react";
import React from "react";
import Loading from "../feedback/Loading";
import Seperator from "../layout/Seperator";
import CartProduct from "../cart/CartProduct";

export default function CartProductGrid({cart, children, minimal}) {
	if (!cart) return <Loading />;
	return (
		<Flex
			flexDir={"column"}
			flex={1}
			minW={"min(540px, 100%)"}
			overflowY={"auto"}
			pb={10}>
			{cart.lines.map((line, i) => {
				const relatedLine = line.customFields.relatedLine;
				return (
					<Box
						key={"cartProduct" + line.id}
						mt={!!relatedLine || i === 0 ? "0" : "6"}>
						<CartProduct
							line={line}
							minimal={minimal}
							relatedLine={relatedLine}
						/>
					</Box>
				);
			})}
			<Box mt={5}>
			{children}

			</Box>

		</Flex>
	);
}
