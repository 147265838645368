import {Box, Collapse, Flex, Heading, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import IconButton from "../layout/IconButton";
import {CartIcon} from "../layout/Menu";
import Description from "./Description";
import SmallProductConfiguratorMenu from "../elements/SmallProductConfiguratorMenu";

export default function ProductPageHeader({product, bgColor}) {
	const [descriptionOpen, setDescriptionOpen] = useState(false);

	return (
		<Flex
			justifyContent={"center"}
			alignItems={"center"}
			flexDir={"column"}
			p={5}
			display={["block", null, "block"]}
			color={"gray.900"}>
			<Flex
				flexDir={"row"}
				justifyContent={"space-between"}
				alignItems={"center"}
				width={"100%"}>
				<Heading
					display={["none", null, "block"]}
					cursor={"pointer"}
					as={"h1"}
					size={"xl"}
					onClick={() => {
						setDescriptionOpen(!descriptionOpen);
					}}>
					{product.name}
				</Heading>
				<SmallProductConfiguratorMenu product={product} />
			</Flex>
			<Collapse in={descriptionOpen}>
				<Box py={4}>
					<Description text={product.description} />
					{/* <Text
						onClick={() => {
							setDescriptionOpen(false);
						}}
						cursor={"pointer"}>
						Weniger anzeigen
					</Text> */}
				</Box>
			</Collapse>
		</Flex>
	);
}
