import {Flex, Image} from "@chakra-ui/react";
import React from "react";
import {BORDER_BLACK} from "../../App";
import Icon from "../elements/Icon";

export const PreheaderHeight = "35px";

export default function PreHeader() {
	return (
		<Flex
			w={"100%"}
			h={PreheaderHeight}
			alignItems={"center"}
			transition={"background-color 0.5s ease,color 0.5s ease"}
			bgColor={"blue.200"}
			color={"blue.700"}
			justifyContent={"space-evenly"}
			px={[2, 3, 5]}
			fontWeight={"light"}
			borderBottom={BORDER_BLACK}
			flexWrap={"wrap"} // Allow items to wrap
			fontSize={["10px", null, "12px"]} // Responsive font size
			zIndex={1}>
			<Flex
				justifyContent={"center"}
				flex={["0 0 50%", "0 0 50%", "0 0 33%"]}>
				<Icon iconName={"shield"} iconStyle="light">
					2 Wochen Probetragen
				</Icon>
			</Flex>
			<Flex
				justifyContent={"center"}
				flex={["0 0 50%", "0 0 50%", "0 0 33%"]}>
				<Icon iconName={"infinite"} iconStyle="light">
					Ewige Botschaft im Universum
				</Icon>
			</Flex>
			<Flex
				justifyContent={"center"}
				flex={["0 0 100%", "0 0 100%", "0 0 33%"]}
				alignItems={"center"}>
				<Image
					alt="Trustpilot Sterne"
					h={["10px", null, "20px"]}
					src="/images/tpstars.svg"
					mr={3}
				/>
				4.7 Sterne bei Trustpilot
			</Flex>
		</Flex>
	);
}
