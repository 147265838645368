import React from "react";
import {Box} from "@chakra-ui/react";

const baseWidth = 30;
const Layer = ({color, topWidth, bottomWidth, height}) => {
	const path = `M${(baseWidth - topWidth) / 2},0 L${
		(baseWidth + topWidth) / 2
	},0 L${(baseWidth + bottomWidth) / 2},${height} L${
		(baseWidth - bottomWidth) / 2
	},${height}z`;

	return (
		<Box>
			<svg
				viewBox={`0 0 ${baseWidth} ${height}`}
				width="100%"
				height={height}>
				<path d={path} fill={color} />
			</svg>
		</Box>
	);
};

const BinaryTriangle = ({binaryString, totalHeight}) => {
	const layers = binaryString.split("");
	return (
		<Box height={totalHeight + "px"}>
			{layers.map((digit, index) => (
				<Layer
					key={index}
					color={digit === "1" ? "red" : "transparent"}
					topWidth={(baseWidth / binaryString.length) * index}
					bottomWidth={
						(baseWidth / binaryString.length) * (index + 1)
					}
					height={totalHeight / binaryString.length}
				/>
			))}
		</Box>
	);
};

export default BinaryTriangle;
