import {Box, Flex, Text} from "@chakra-ui/react";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {useStore} from "../../Store";
import IconButton from "./IconButton";

export function MenuLeft() {
	return (
		<Flex
			as={"nav"}
			flexDir="row"
			gap={[3, null, 5]}
			mr={3}
			alignItems={"center"}>
			<Link to={"/products"}>
				<Text color={"darkBlue"} as={"span"}>
					Alle Produkte
				</Text>
			</Link>
			<Link to={"/product/necklace"}>
				<Text as={"span"} color={"lightBlue"}>
					Ewigkeitsketten
				</Text>
			</Link>
		</Flex>
	);
}

export default function Menu() {
	const user = useStore(state => state.user);

	return (
		<Flex
			as={"nav"}
			flexDir="row"
			gap={[3, null, 5]}
			mr={3}
			alignItems={"center"}>
			<Link to={"/user"}>
				<Text color={"darkBlue"} as={"span"}>
					{user ? "Dein Bereich" : "Login"}
				</Text>
			</Link>
			<CartIcon />
		</Flex>
	);
}

export function CartIcon({}) {
	const cart = useStore(state => state.cart);
	const navigate = useNavigate();
	return (
		<Box
			cursor={"pointer"}
			onClick={() => {
				navigate("/cart");
			}}>
			<IconButton iconName="cart1" number={cart?.totalQuantity} />
		</Box>
	);
}
