import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import {getRatingStars} from "./ReviewBadge";
import Icon from "../elements/Icon";

export default function ReviewCards({reviews, minIndex, maxIndex}) {
	const sortedReviews = [...reviews.items].sort((a, b) => {
		return (
			new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
		);
	});

	return sortedReviews.map((review, i) => {
		if (i < minIndex || i >= maxIndex) return "";
		return (
			<Flex
				bgColor={"white"}
				key={review.createdAt}
				align="flex-start"
				gap={3}
				p={4}
				shadow="md"
				borderWidth="1px"
				flexDir={"column"}>
				<Flex justifyContent={"space-between"} width={"100%"}>
					<Box>{getRatingStars(review.rating)}</Box>
					<Flex
						fontSize={"xs"}
						px={2}
						w={"max-content"}
						bg={"green.100"}
						color={"green.800"}
						borderRadius={"md"}
						alignItems={"center"}>
						<Icon icon={"checkmark"} />
						<Text as={"span"} ml={2} mb={0}>
							Verifiziert
						</Text>
					</Flex>
				</Flex>
				<Heading as="h3" size="sm">
					{review.summary}
				</Heading>
				<Text fontSize="xs">
					{new Date(review.createdAt).toLocaleDateString("de-DE", {
						year: "numeric",
						month: "long",
						day: "numeric",
					})}
				</Text>
				<Text fontSize="sm">{review.body}</Text>
				<Text fontWeight={"bold"} fontSize="sm">
					{review.authorName}
				</Text>
			</Flex>
		);
	});
}
