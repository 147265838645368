import {Box, Heading} from "@chakra-ui/react";
import React from "react";
import {useStore} from "../../Store";
import NormalBox from "../elements/NormalBox";
import FormField from "./FormField";

export default function MainText({value, changeUserInputs}) {
	const cart = useStore(state => state.cart);

	function getSuggestions() {
		let suggestions = [];
		for (const line of cart.lines) {
			console.log(line);
			if (line.productVariant.product.slug === "message") {
				let messageText = JSON.parse(
					line.customFields.userInputs
				).message_text;
				suggestions.push(messageText);
			}
		}
        let generalSuggestions = [
            "The day our stars aligned",
            "Bis zur Ewigkeit und wieder zurück...",
            "Du bist mein Polarstern", 
        ]
		suggestions = [
			...suggestions,
			...generalSuggestions
		];
		return suggestions;
	}

	return (
		<Box>
			<FormField
				type={"textarea"}
				title={"Dein Text"}
				fieldName={"poster_text"}
				changeUserInputs={changeUserInputs}
				value={value || ""}
			/>
			<Heading as={"h3"} size={"sm"} my={2}>
				Vorschläge
			</Heading>
			{cart && getSuggestions().map(suggestion => {
				return (
					<NormalBox
						py={1}
						boxShadow={"sm"}
						mb={1}
						bgColor={"blue.50"}
						onClick={() => {
							changeUserInputs("poster_text", suggestion);
						}}
						cursor={"pointer"}>
						{suggestion}
					</NormalBox>
				);
			})}
		</Box>
	);
}
