import {Box} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {numberOfPhysicalAndDigitalLineItems} from "../checkoutFlow/ShippingForm";
import {OrderSummaryItem} from "./OrderSummary";

const processingTime = 48;
const shippingTime = 48;
const dailyDeadline = 16;

export default function ShippingEstimator({cart}) {
	function getDigitalShippingEstimate() {
		if (numberOfPhysicalAndDigitalLineItems(cart).digital) {
			return "In 10 Minuten";
		}
		return null;
	}

	function getPhysicalShippingEstimate() {
		if (numberOfPhysicalAndDigitalLineItems(cart).physical) {
			return calculateDeliveryDate();
		}
		return null;
	}

	return (
		<>
			{!!getDigitalShippingEstimate() && (
				<OrderSummaryItem label="Vsl. Mailzustellung">
					{getDigitalShippingEstimate()}
				</OrderSummaryItem>
			)}
			{!!getPhysicalShippingEstimate() && (
				<>
					<OrderSummaryItem label="Vsl. Postzustellung">
						{getPhysicalShippingEstimate()}
					</OrderSummaryItem>
					<OrderSummaryItem label="bei Bestellung in">
						<Countdown dailyDeadline={dailyDeadline} />
					</OrderSummaryItem>
				</>
			)}
		</>
	);
}

function calculateDeliveryDate() {
	const today = new Date();
	const processingDays = Math.ceil(processingTime / 24); // convert hours to days and round up
	const shippingDays = Math.ceil(shippingTime / 24);
	let totalDays = processingDays + shippingDays;

	// If processing extends into the weekend, skip to next Monday
	const dayOfWeek = today.getDay();
	if (dayOfWeek === 5 && processingDays > 1) {
		totalDays += 2;
	} else if (dayOfWeek === 6 && processingDays > 0) {
		totalDays += 1;
	}

    if(today.getHours()+1 > dailyDeadline){
        totalDays += 1;
    }

	const deliveryDate = new Date(
		today.getTime() + totalDays * 24 * 60 * 60 * 1000
	); // add totalDays as milliseconds to today's date
	const deliveryDayOfWeek = deliveryDate.getDay();

	// If delivery falls on a Sunday, skip to next Monday
	if (deliveryDayOfWeek === 0) {
		deliveryDate.setDate(deliveryDate.getDate() + 1);
	}

	const options = {
		weekday: "short",
		year: "numeric",
		month: "short",
		day: "numeric",
	};
	return deliveryDate.toLocaleDateString("de-DE", options);
}

function Countdown({dailyDeadline}) {
	const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTimeRemaining(getTimeRemaining());
		}, 1000);
		return () => clearInterval(intervalId);
	}, []);

	function getTimeRemaining() {
		const now = new Date();
		const deadline = new Date();
		deadline.setHours(dailyDeadline, 0, 0, 0); // set the deadline time
		if (deadline < now) deadline.setDate(deadline.getDate() + 1); // if deadline has already passed, set it to tomorrow
		const timeDiff = deadline - now;
		const hours = Math.floor(timeDiff / (1000 * 60 * 60));
		const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
		const seconds = Math.floor((timeDiff / 1000) % 60);
		return {hours, minutes, seconds};
	}

	return (
		<Box
			display="flex"
			fontWeight="bold"
			alignItems="center"
			color="green.500">
			<Box>
				{timeRemaining.hours > 0 ? (
					<Box as="span">{timeRemaining.hours}h </Box>
				) : null}
				{timeRemaining.minutes > 0 ? (
					<Box as="span">{timeRemaining.minutes}min </Box>
				) : null}
				<Box as="span">{timeRemaining.seconds}s</Box>
			</Box>
		</Box>
	);
}
