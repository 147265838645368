import {gql, useQuery} from "@apollo/client";
import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Heading,
	Link,
	Text,
} from "@chakra-ui/react";
import React from "react";
import {Parallax, useParallax} from "react-scroll-parallax";
import Error from "../feedback/Error";
import Loading from "../feedback/Loading";
import ProductCard from "./ProductCard";
import {GET_PRODUCTS} from "../../queries";
import Icon from "../elements/Icon";
import Seperator from "../layout/Seperator";

export default function ProductGrid() {
	const {loading, error, data} = useQuery(GET_PRODUCTS);

	if (error) return <Error />;

	function getProducts() {
		if (loading) return <Loading />;
		let grid = [];
		const products = JSON.parse(JSON.stringify(data.products.items)).sort(
			(a, b) => a.customFields.sortPriority - b.customFields.sortPriority
		);
		for (let product of products) {
			if (product.customFields.addonOnly) continue;
			grid.push(
				<>
					<GridItem w={"100%"} key={"product" + product.id}>
						<ProductCard
							heading={product.name}
							product={product}
							price={getProductPrice(product)}
							asset={product.assets[0]}
							slug={product.slug}></ProductCard>
					</GridItem>
					<Seperator />
				</>
			);
		}
		grid.push(
			<GridItem w={"100%"} key={"temp-giftcard"}>
				<Flex
					flexDir={["column", null, null, null, "row"]}
					w={"100%"}
					h={"100%"}
					overflow={"hidden"}>
					<Flex flexDir={"column"} gap={3} flex={1} p={4}>
						<Box>
							<Heading color={"gray.800"} fontSize={"2xl"} mb={1}>
								Geschenkgutschein
							</Heading>
						</Box>
						<Box>
							<Text mb={2}>
								Verschenke einen Gutschein für DearUniverse an
								Deine Liebsten!
							</Text>
							<Text>
								Aus technischen Gründen ist der
								Geschenkgutschein vorübergehend nicht im
								Onlineshop verfügbar. Wir können Dir aber
								trotzdem einen ausstellen. Schreib uns dafür
								einfach eine kurze Mail mit Deiner Adresse und
								dem gewünschten Betrag. Du erhältst dann schnell
								eine Rechnung, bezahlen kannst du ganz einfach
								per PayPal oder Überweisung.
							</Text>
						</Box>

						<Box mt={"auto"}>
							<Link
								href={`mailto:info@dearuniverse.de?subject=Geschenkgutschein%20Anfrage&body=Hey%2C%20ich%20möchte%20einen%20Geschenkgutschein%20kaufen...`}>
								<Button
									size={["sm", "md"]}
									rightIcon={<Icon icon={"letter"} />}>
									Mail senden
								</Button>
							</Link>
						</Box>
					</Flex>
				</Flex>
			</GridItem>
		);
		return grid;
	}

	return (
		<Grid
			mb={[10, null, null, "90px"]}
			gap={["50px", "50px", "30px", "30px"]}
			px={[3, null, null, "80px"]}
			wrap={"wrap"}
			w={"100%"}
			templateColumns={["1fr", null, null, null, "1fr"]}>
			{getProducts()}
		</Grid>
	);
}

export function getProductPrice(product) {
	let smallestPrice;
	for (let variant of product.variants) {
		if (!smallestPrice || variant.priceWithTax < smallestPrice)
			smallestPrice = variant.priceWithTax;
	}
	return "ab " + (smallestPrice / 100).toFixed(2) + "€";
}
