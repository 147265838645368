import {
	Box,
	FormControl,
	FormLabel,
	HStack,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
} from "@chakra-ui/react";
import React, {useState} from "react";
import Help from "./Help";

export default function DateTimePicker({
	change,
	keyName,
	date,
	disabled = false,
	children,
	title,
	help,
	hideTime,
}) {
	const [dateInput, setDateInput] = useState(null);
	const [yearInput, setYearInput] = useState(null);
	const dateObj = new Date(date);

	function changeDate(segment, val) {
		let newDate = dateObj;
		if (segment === "year") {
			newDate.setFullYear(val);
		} else if (segment === "month") {
			let maxDaysNewMonth = getDaysInMonth(dateObj.getFullYear(), val);
			let maxDaysCurrentDate = getDaysInMonth(
				dateObj.getFullYear(),
				dateObj.getMonth()
			);
			let currentDays = dateObj.getDate();
			if (
				maxDaysNewMonth < maxDaysCurrentDate &&
				maxDaysNewMonth < currentDays
			) {
				newDate.setDate(getDaysInMonth(dateObj.getFullYear(), val));
			}
			newDate.setMonth(val);
		} else if (segment === "day") {
			newDate.setDate(val);
		} else if (segment === "time") {
			let splitVal = val.split(":");
			splitVal = splitVal.map(v => {
				return Number(v);
			});
			console.log([...splitVal, 0, 0]);
			newDate.setHours(...splitVal, 0, 0);
		}

		if (hideTime) {
			newDate.setHours(0);
			newDate.setMinutes(0);
		}
		change(keyName, newDate.toISOString());
	}

	function getDaysInMonth(year, month) {
		console.log("Month" + (month + 1));
		return new Date(year, month + 1, 0).getDate();
	}

	function generateDateInputs() {
		let elements = [];
		// DAY
		elements.push(
			<NumberInput
				key="dayInput"
				disabled={disabled}
				value={dateInput !== null ? dateInput : dateObj.getDate()}
				onBlur={e => {
					setDateInput(null);
					console.log(e.target.value);
					changeDate("day", e.target.value);
				}}
				onChange={e => {
					let maxDays = getDaysInMonth(
						dateObj.getFullYear(),
						dateObj.getMonth()
					);
					if (e === "") {
						setDateInput(e);
						return;
					}
					if (e > maxDays) e = maxDays;
					if (e < 1) e = 1;
					console.log(e);
					setDateInput(e);
					setDateInput(null);
					changeDate("day", e);
				}}
				step={1}>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>
		);

		// MONTH
		elements.push(
			<Select
				key="monthInput"
				disabled={disabled}
				value={dateObj.getMonth()}
				onChange={e => {
					changeDate("month", Number(e.target.value));
				}}>
				<option value={"0"}>{"Januar"}</option>
				<option value={"1"}>{"Februar"}</option>
				<option value={"2"}>{"März"}</option>
				<option value={"3"}>{"April"}</option>
				<option value={"4"}>{"Mai"}</option>
				<option value={"5"}>{"Juni"}</option>
				<option value={"6"}>{"Juli"}</option>
				<option value={"7"}>{"August"}</option>
				<option value={"8"}>{"September"}</option>
				<option value={"9"}>{"Oktober"}</option>
				<option value={"10"}>{"November"}</option>
				<option value={"11"}>{"Dezember"}</option>
			</Select>
		);

		// YEAR
		elements.push(
			<NumberInput
				key="yearInput"
				disabled={disabled}
				value={yearInput !== null ? yearInput : dateObj.getFullYear()}
				onBlur={e => {
					let diff = Math.abs(
						new Date().getFullYear() - e.target.value
					);
					setYearInput(null);
					console.log(e.target.value);
					changeDate("year", e.target.value);
				}}
				onChange={e => {
					console.log(e);
					setYearInput(e);
					if (e === "") {
						return;
					}
					setYearInput(null);
					changeDate("year", e);
				}}
				step={1}>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>
		);

		return elements;
	}

	return (
		<Box>
			<FormControl>
				{help ? <Help tip={help} /> : ""}
				{title ? <FormLabel>{title}</FormLabel> : ""}
				<HStack mb={3} spacing={3}>
					{generateDateInputs()}
				</HStack>
				{!hideTime && (
					<Input
						disabled={disabled}
						type="time"
						w={"max-content"}
						value={
							leadingZero(dateObj.getHours()) +
							":" +
							leadingZero(dateObj.getMinutes())
						}
						onChange={e => {
							changeDate("time", e.target.value);
						}}></Input>
				)}
				{children}
			</FormControl>
		</Box>
	);
}

export function leadingZero(val) {
	return ("000000" + val).slice(-2);
}

export function formatDates(date) {
	date = new Date(date);
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		timeZoneName: "long",
	};

	return date.toLocaleDateString(undefined, options);
}
