import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
} from "@chakra-ui/react";
import React, {useEffect, useRef} from "react";
import {Outlet, useLocation, useParams} from "react-router-dom";
import CheckoutHeader from "../checkout/CheckoutHeader";
import {CartOrderSummary} from "../cart/OrderSummary";
import Page from "../layout/Page";
import MobileSummary from "../checkout/MobileSummary";
import {useStore} from "../../Store";
import {client} from "../..";
import {GET_ORDER_BY_CODE} from "../../queries";
import {BORDER_BLACK} from "../../App";

export default function Checkout() {
	let cart = useStore(state => state.cart);
	const location = useLocation();
	const [order, setOrder] = React.useState(null);
	const {orderCode} = useParams();
	const form = useRef();

	if (order) cart = order;

	React.useEffect(() => {
		async function getOrder() {
			if (!orderCode) return;
			let result = await client.query({
				query: GET_ORDER_BY_CODE,
				variables: {code: orderCode},
			});
			setOrder(await result.data.result);
		}
		getOrder();
	}, []);

	return (
		<Page header={false} fullsize={true} title={"Kasse"}>
			<Flex width={"100%"} flexWrap={"wrap"} h={"100%"}>
				<Flex
					flex={"1"}
					pt={"16"}
					px={["3", null, "10"]}
					alignItems={["center", null, null, "flex-end"]}
					bgColor={"white"}
					flexDir={"column"}>
					<Flex
						width={"100%"}
						maxW={"600px"}
						gap={7}
						flexDir={"column"}>
						<CheckoutHeader />
						<MobileSummary cart={cart} />
						<Outlet />
					</Flex>
				</Flex>
				<Box
					display={["none", null, "block"]}
					borderLeft={BORDER_BLACK}
					flex={"1"}
					pt={"16"}
					px={"10"}
					minW={"400px"}
					as={"aside"}
					boxShadow={"md"}>
					<Box maxWidth={"600px"}>
						<CartOrderSummary
							inCheckout
							confirmationPage={location.pathname.match(
								/confirmation/
							)}
							cart={cart}
						/>
					</Box>
				</Box>
			</Flex>
		</Page>
	);
}
