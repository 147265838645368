import {Button, Flex, Input, Text, useToast} from "@chakra-ui/react";
import React, {useState} from "react";
import {getFormData} from "../checkoutFlow/helper";
import {client} from "../..";
import {APPLY_COUPON, APPLY_GIFTCARD} from "../../queries";
import {useStore} from "../../Store";
import Icon from "./Icon";

export default function GiftCardInput() {
	const [open, setOpen] = useState(false);
	const setCart = useStore(state => state.setCart);
	const toast = useToast();

	async function submit(e) {
		e.preventDefault();
		let variables = getFormData(e.target);
		let result = await client.mutate({mutation: APPLY_GIFTCARD, variables});
		if (result.data.result.errorCode) {
			result = await client.mutate({
				mutation: APPLY_COUPON,
				variables,
			});
			if (result.data.result.errorCode) {
				toast({
					title: "Dieser Code ist leider nicht gültig.",
					description:
						"Korrigiere Deine Eingabe oder wende Dich an den Support.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				return;
			}
		}
		setCart(result.data.result);
		toast({
			title: "Code hinzugefügt!",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
		setOpen(false);
	}

	return (
		<>
			<Text
				color={"blue.400"}
				cursor={"pointer"}
				onClick={() => {
					setOpen(!open);
				}}>
				Ich habe einen Gutschein <Icon iconName={"gift"} />
			</Text>
			{open && (
				<Flex
					as={"form"}
					alignItems={"center"}
					gap={3}
					mt={3}
					onSubmit={submit}>
					<Input
						type={"text"}
						flex={1}
						name={"code"}
						bgColor={"white"}
						placeholder={"XXXXXX"}
					/>
					<Button type="submit" w={"min-content"} size={"md"}>
						Einlösen
					</Button>
				</Flex>
			)}
		</>
	);
}
