import {Link} from "@chakra-ui/react";
import React from "react";

export default function CheckAttachmentLink({fileName}) {
	return (
		<Link
			href={process.env.REACT_APP_DU_API + "/messagefile/get/" + fileName}
			target="_blank"
			referrerPolicy="no-referrer">
			Datei überprüfen
		</Link>
	);
}
