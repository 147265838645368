import {Box, List, ListItem, Text} from "@chakra-ui/react";
import React from "react";
import {formatPrice} from "../../helpers";
import {checkMessageDate} from "../../validateForms";
import {formatDates} from "../elements/DateTimePicker";
import CheckAttachmentLink from "../feedback/CheckAttachmentLink";

export default function CartMetaData({
	userInputs,
	id,
	minimal,
	relatedLine,
	line,
}) {
	function getMetaDataItems() {
		function add(metaTitle, value, isError) {
			items.push(
				<MetaItem
					key={metaTitle + id}
					metaTitle={metaTitle}
					value={value}
					isError={isError}
				/>
			);
		}

		let items = [];

		if (userInputs.message_text)
			add("Text Deiner Nachricht", userInputs.message_text);
		if (userInputs.message_date)
			add("Sendedatum", formatDates(userInputs.message_date));
		if (
			userInputs.message_date &&
			!checkMessageDate(userInputs.message_date)
		) {
			add(
				"Achtung",
				"Das Sendedatum sollte für einen reibungslosen Ablauf mind. 1 Stunde in der Zukunft liegen. Bitte passe Deinen Sendezeitpunkt an.",
				true
			);
		}
		if (relatedLine) {
			let relatedUserInputs = JSON.parse(
				relatedLine.customFields.userInputs
			);
			add("Text", relatedUserInputs.message_text);
			//add("Sendedatum", formatDates(relatedUserInputs.message_date));
		}
		if (userInputs.poster_date)
			add("Datum", formatDates(userInputs.poster_date));
		if (userInputs.poster_location) add("Ort", userInputs.poster_location);
		if (userInputs.poster_text) add("Text", userInputs.poster_text);
		if (userInputs.message_origFileName) {
			add("Datei", userInputs.message_origFileName);
			add(<CheckAttachmentLink fileName={userInputs.message_fileName} />);
		}

		if (line.giftCardInput?.message)
			add("Nachricht", line.giftCardInput?.message);
		if (line.giftCardInput?.recipientEmailAddress)
			add("Empfänger", line.giftCardInput?.recipientEmailAddress);

		if (line.giftCardInput?.deliveryDate)
			add(
				"Versanddatum",
				new Date(
					line.giftCardInput?.deliveryDate
				).toLocaleDateString() +
					" um " +
					new Date(
						line.giftCardInput?.deliveryDate
					).toLocaleTimeString()
			);

		return items;
	}

	const metaItems = getMetaDataItems();

	if (metaItems.length === 0) return "";

	return (
		<Box
			fontSize={minimal ? "xs" : "sm"}
			borderRadius={"lg"}
			width={"100%"}>
			<List p={"0!important"}>{metaItems}</List>
		</Box>
	);
}

function MetaItem({metaTitle, value, isError}) {
	return (
		<ListItem
			p={isError ? "1" : "0"}
			bgColor={isError ? "red.500" : ""}
			color={isError ? "white" : ""}>
			<Text as={"span"}>
				{metaTitle}
				{!!value && ":"}{" "}
			</Text>
			{value}
		</ListItem>
	);
}
