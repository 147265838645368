import {Flex} from "@chakra-ui/react";
import React from "react";

export default function NormalBox({children, ...props}) {
	return (
		<Flex
			bgColor={"white"}
			p={3}
            gap={3}
			boxShadow={"sm"}
			borderRadius={"sm"}
			{...props}>
			{children}
		</Flex>
	);
}
