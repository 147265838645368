import {Box, Button} from "@chakra-ui/react";

import React from "react";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../Store";
import Icon from "../elements/Icon";

export default function AddCertBtn({lineId, slug}) {
	const navigate = useNavigate();
	const cart = useStore(state => state.cart);

	if (slug !== "message") return "";
	if (isSomethingRelatedToLine(cart, lineId)) return "";

	return (
		<Box>
			<Button
				leftIcon={<Icon icon={"quality"} iconStyle="regular" />}
				mx={"auto"}
				onClick={() => {
					navigate("/product/certificate/" + lineId);
				}}>
				Zertifikat hinzufügen
			</Button>
		</Box>
	);
}

export function isSomethingRelatedToLine(cart, lineId) {
	return !!cart.lines.find(
		line => line.customFields.relatedLine?.id === lineId
	);
}
