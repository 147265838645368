import React from "react";
import DateTimePicker from "../elements/DateTimePicker";
import FontPicker from "../elements/FontPicker";
import MainTextTransfomation from "../elements/MainTextTransfomation";
import FormContainer from "./FormContainer";
import FormField from "./FormField";
import FormSection from "./FormSection";

export default function PlanetPoster({
	changeOption,
	changeUserInputs,
	userInputs,
	setUserInputs,
}) {
	return (
		<FormContainer>
			<FormSection title={"Datum Deines Moments"}>
				<DateTimePicker
					title={"Datum"}
					date={userInputs.poster_date}
					keyName={"poster_date"}
					change={changeUserInputs}
					help={"Welches Datum soll das Poster zeigen?"}
					hideTime
				/>
			</FormSection>
			<FormSection title={"Deine Widmung"}>
				<FormField
					type={"textarea"}
					title={"Dein Text"}
					placeholder={"Dear Universe,..."}
					fieldName={"poster_text"}
					changeUserInputs={changeUserInputs}
					value={userInputs.poster_text || ""}
				/>
				<FontPicker
					change={changeUserInputs}
					font={userInputs.poster_font}
				/>
				<MainTextTransfomation
					change={changeUserInputs}
					userInputs={userInputs}
				/>
			</FormSection>
		</FormContainer>
	);
}
