import React, {useRef} from "react";
import {
	Button,
	Checkbox,
	Flex,
	FormErrorMessage,
	Heading,
	Input,
	Select,
	SelectField,
	Text,
} from "@chakra-ui/react";
import {useEffect} from "react";
import {useState} from "react";
import {useStore} from "../../Store";
import {Link, useNavigate} from "react-router-dom";
import {H2, inputProps, MultiFieldRow} from "../checkout/CustomerDataForm";
import NormalBox from "../elements/NormalBox";
import Seperator from "../layout/Seperator";
import AddressForm from "../checkout/AddressForm";
import {FormField} from "../checkout/FormField";
import ControlButtons from "./ControlButtons";
import {fillForm, getFormData} from "./helper";
import {mutateAndGetNewCart} from "../../helpers";
import {
	CHECKOUT_INFO,
	CHECKOUT_INFO_WITH_SHIPPING,
	SET_BILLING_ADDRESS,
} from "../../queries";
import CheckoutFormWrapper from "./CheckoutFormWrapper";
import Icon from "../elements/Icon";

export default function GeneralForm() {
	const cart = useStore(state => state.cart);
	const user = useStore(state => state.user);
	const [loading, setLoading] = useState(false);
	const [telephoneInputOpen, setTelephoneInputOpen] = useState(false);
	const form = useRef();

	function handleNameChange() {
		const data = getFormData(form.current);
		fillForm(form.current, {
			fullName: data.firstName + " " + data.lastName,
		});
	}

	if (user) {
		return (
			<CheckoutFormWrapper
				setLoading={setLoading}
				form={form}
				mutation={SET_BILLING_ADDRESS}
				nextUrl={"/checkout/shipping"}
				updateBillingOrShipping={"billing"}>
				<Flex flexDir={"column"} textAlign={"center"}>
					<H2>Hey {user.firstName}</H2>
					<Text>
						Deine E-Mail-Adresse lautet: {user.emailAddress}
					</Text>
					<Text>
						Deine Daten kannst Du jederzeit in Deinem Profil ändern.
					</Text>
					<Link to={"/user"}>Daten ändern</Link>
				</Flex>
				<Seperator />
				<H2>Deine Rechnungsadresse</H2>
				<AddressForm cart={cart} />
				<ControlButtons
					prevButtonText={"Zum Warenkorb"}
					nextButtonText={"Weiter zum Versand"}
					isLoading={loading}
				/>
			</CheckoutFormWrapper>
		);
	}

	return (
		<CheckoutFormWrapper
			setLoading={setLoading}
			form={form}
			mutation={
				!cart?.shippingAddress?.countryCode
					? CHECKOUT_INFO_WITH_SHIPPING
					: CHECKOUT_INFO
			}
			nextUrl={"/checkout/shipping"}
			updateBillingOrShipping={"billing"}>
			<NormalBox bgColor={"blue.50"} color={"blue.600"}>
				Hast Du bereits einen Account?
				<Link to={"/login"}>Login!</Link>
			</NormalBox>
			<Seperator />
			<H2>Deine Kontaktdaten</H2>
			<MultiFieldRow>
				<FormField
					onChange={handleNameChange}
					label={"Vorname"}
					inputProps={inputProps["firstName"]}
				/>
				<FormField
					onChange={handleNameChange}
					label={"Nachname"}
					inputProps={inputProps["lastName"]}
				/>
			</MultiFieldRow>
			<FormField
				label={"E-Mail Adresse"}
				inputProps={inputProps["emailAddress"]}
			/>
			{!telephoneInputOpen && !user?.phoneNumber && (
				<Text
					as={"a"}
					fontSize={"small"}
					color={"blue.400"}
					cursor={"pointer"}
					onClick={() => {
						setTelephoneInputOpen(true);
					}}>
					<Icon iconName={"phone"} dispay={"inline-block"}>
						Telefonnummer hinzufügen
					</Icon>
				</Text>
			)}
			{(telephoneInputOpen || user?.phoneNumber) && (
				<FormField
					label={"Telefon"}
					inputProps={inputProps["phoneNumber"]}
					help={"Für Rückfragen zu Deiner Bestellung"}
				/>
			)}
			<Seperator />
			<H2>Deine Rechnungsadresse</H2>
			<AddressForm cart={cart} />
			<ControlButtons
				prevButtonText={"Zum Warenkorb"}
				nextButtonText={"Weiter zum Versand"}
				isLoading={loading}
			/>
		</CheckoutFormWrapper>
	);
}
