import {Badge, Box, Button, Flex, Heading, Image, Text} from "@chakra-ui/react";
import React from "react";
import {useNavigate} from "react-router-dom";
import {formatPrice, productsWithPreview} from "../../helpers";
import {useStore} from "../../Store";
import {NumberBubble} from "../layout/IconButton";
import AddCertBtn from "../product/AddCertBtn";
import CartMetaData from "./CartMetaData";
import QuantityChooser from "../product/QuantityChooser";
import {useState} from "react";
import {BORDER_BLACK} from "../../App";
import Seperator from "../layout/Seperator";
import Icon from "../elements/Icon";

const StockWarning = () => {
	return (
		<Box p={2} border={BORDER_BLACK} fontSize={"sm"} bgColor={"blue.50"}>
			<Text lineHeight={"1.4em"}>
				Dieser Artikel ist momentan nicht auf Lager, aber Du kannst ihn
				vorbestellen. Rechne mit einer zusätzlichen Lieferzeit von max.
				2 Wochen.
			</Text>
			<Text lineHeight={"1.4em"}>
				Du hast Fragen zum Ablauf? Schreib uns einfach eine{" "}
				<a href="mailto:info@dearuniverse.de">Mail</a>!
			</Text>
		</Box>
	);
};

export default function CartProduct({line, minimal, relatedLine}) {
	const [bigPreview, setBigPreview] = useState(false);

	function getMedia() {
		if (line.productVariant.assets.length > 0) {
			return line.productVariant.assets[0].source;
		} else {
			return line.productVariant.product.assets[0].source;
		}
	}

	const forceUpdate = useStore(state => state.forceUpdate);

	const userInputs = JSON.parse(line.customFields?.userInputs || "{}");
	const slug = line.productVariant.product.slug;

	const hasPreview = productsWithPreview.indexOf(slug) !== -1;
	return (
		<>
			<Flex
				position={"relative"}
				py={minimal ? "" : 5}
				px={minimal ? "" : 2}
				flexDir={"column"}
				bgColor={minimal ? "" : "white"}
				borderRadius={"sm"}
				ml={minimal ? "" : relatedLine ? "50px" : 0}
				borderLeft={minimal ? "" : relatedLine ? "3px solid gray" : ""}>
				{relatedLine && (
					<Box
						color={"gray.500"}
						position={"absolute"}
						left={"-10px"}
						top={"50%"}
						transform={"translate(-100%, -50%)"}>
						<Icon iconName={"link"} size={"lg"} />
					</Box>
				)}
				<Flex
					flexDir={"row"}
					gap={[4, null, 7]}
					p={3}
					//border={minimal ? "none" : "1px solid #eeeeee"}
					//boxShadow={minimal ? "none" : "sm"}
					width="100%">
					<Flex alignItems={"center"}>
						{bigPreview && <Box w={"120px"}></Box>}
						<Flex
							position={bigPreview ? "fixed" : "relative"}
							bgColor={bigPreview ? "#eeeeeeee" : ""}
							justifyContent={"center"}
							alignItems={"center"}
							minW={"90px"}
							w={bigPreview ? "100%" : "auto"}
							h={bigPreview ? "100%" : "auto"}
							top={bigPreview ? "0" : "0"}
							left={bigPreview ? "0" : "0"}
							zIndex={bigPreview ? "10" : "0"}
							p={bigPreview ? 10 : 0}
							cursor={
								hasPreview && !bigPreview ? "pointer" : "auto"
							}
							onClick={() => {
								if (!hasPreview || bigPreview) return;
								setBigPreview(true);
							}}>
							<Image
								src={
									hasPreview
										? process.env.REACT_APP_DU_API +
										  "/poster/preview/" +
										  line.id +
										  ".jpg?" +
										  forceUpdate
										: getMedia()
								}
								data-lineid={line.id}
								fallbackSrc={getMedia()}
								width={
									bigPreview ? "" : ["90px", null, "130px"]
								}
								maxW={"min(600px , 100%)"}
								maxH={"100%"}
								height={
									hasPreview
										? "auto"
										: ["90px", null, "130px"]
								}
								borderRadius={hasPreview ? "none" : "lg"}
								crossOrigin="anonymous"
								boxShadow={"lg"}
								border={"1px #eeeeee"}
								objectFit={"cover"}></Image>
							{!bigPreview ? (
								<NumberBubble
									number={line.quantity}
									color={"gray.500"}
								/>
							) : (
								<Box
									position={"fixed"}
									top={"20px"}
									right={"20px"}
									cursor={"pointer"}
									onClick={() => {
										setBigPreview(false);
									}}>
									<Icon iconName={"close"} size={"2x"} />
								</Box>
							)}
						</Flex>
					</Flex>
					<Flex gap={4} flexDir={"column"} flex={"1"}>
						<Heading
							mb={0}
							as={"h3"}
							size={minimal ? "sm" : "md"}
							fontWeight={"normal"}>
							{line.productVariant.name}
						</Heading>
						{line.productVariant.stockOnHand === 0 && (
							<StockWarning />
						)}
						{line.customFields.code && (
							<Text>{line.customFields.code}</Text>
						)}
						<CartMetaData
							userInputs={userInputs}
							id={line.id}
							minimal={minimal}
							relatedLine={relatedLine}
							line={line}
						/>
						{minimal ? (
							""
						) : (
							<>
								{!!line.productVariant.product.customFields
									.cartDescription && (
									<Text>
										{
											line.productVariant.product
												.customFields.cartDescription
										}
									</Text>
								)}
								<Flex gap={2} wrap={"wrap"} mt={"auto"}>
									<QuantityChooser line={line} />
									<AddCertBtn lineId={line.id} slug={slug} />
								</Flex>
								{!line.productVariant.product.customFields
									.standardProductPage && (
									<EditButton
										slug={
											relatedLine
												? slug + "/" + relatedLine.id
												: slug
										}
										id={line.id}
									/>
								)}
							</>
						)}
						<Flex flexDir={"row"} textAlign={"center"}>
							<Text>
								{formatPrice(
									line.productVariant.priceWithTax ||
										line.unitPrice
								)}
							</Text>
						</Flex>
					</Flex>
				</Flex>
				{/* UPSELL ADDON COMING LATER */}
				{/* {line.productVariant.product.slug === "necklace" && <UpsellAddon />} */}
			</Flex>
			{!minimal && <Seperator />}
		</>
	);
}

function EditButton({slug, id}) {
	const navigate = useNavigate();

	return (
		<Button
			variant={"outline"}
			w={"min-content"}
			colorScheme={"blue"}
			leftIcon={<Icon iconName={"edit"} iconStyle="regular" />}
			onClick={() => {
				navigate(`/product/${slug}?editid=${id}`);
			}}>
			Bearbeiten
		</Button>
	);
}
