export function track(eventType, cart, singleProduct, singleProductVariantId) {
	if (singleProduct && !singleProductVariantId)
		return console.error(
			"If single product is set, make sure to pass the variantId also (Tracking.jsx)"
		);

	// -------------GOOGLE--------------
	// Prepare data for dataLayer
	const dataLayerData = {
		event: eventType,
		ecommerce: {
			transaction_id: cart?.code,
			value: cart?.subTotal / 100,
			currency: "EUR",
			items: !singleProduct
				? cart?.lines.map(line => ({
						item_id: line.productVariant.product.id,
						item_name: line.productVariant.product.name,
						price: line.productVariant.price / 100,
						quantity: line.quantity,
						currency: "EUR",
						// Include other product details as needed
				  }))
				: [
						{
							item_id: singleProduct.id, // Product ID or SKU
							item_name: singleProduct.name, // Name of the product
							item_variant: singleProductVariantId, // Product variant
							price:
								singleProduct.variants.find(
									v => v.id === singleProductVariantId
								)?.price / 100 || 0, // Price of the product
							quantity: 1, // Quantity of the product
						},
				  ],
		},
	};

	// Push to dataLayer
	console.log("Datalayer Data:", dataLayerData);
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(dataLayerData);

	// -------------TIKTOK--------------
	// Prepare data for TikTok
	const contents = !singleProduct
		? cart?.lines.map(line => ({
				content_id: line.productVariant.product.id,
				content_name: line.productVariant.product.name,
				quantity: line.quantity,
				price: line.linePrice / 100,
				// Add other TikTok specific parameters if needed
		  }))
		: [
				{
					content_id: singleProduct.id, // Product ID or SKU
					content_name: singleProduct.name, // Name of the product
					price:
						singleProduct.variants.find(
							v => v.id === singleProductVariantId
						)?.price / 100 || 0, // Price of the product
					quantity: 1, // Quantity of the product
				},
		  ];

	const tikTokData = {
		contents,
		content_type: "product",
		value: (cart?.subTotal || 0) / 100,
		currency: "EUR",
	};
	console.log("TikTok Data:", tikTokData);

	// Send data to TikTok
	if (window.ttq && typeof window.ttq.track === "function") {
		if (
			window.ttq &&
			typeof window.ttq.identify === "function" &&
			(cart?.customer?.emailAddress || cart?.customer?.phoneNumber)
		) {
			const identData = {
				email: cart?.customer?.emailAddress || "",
				phone_number: cart?.customer?.phoneNumber || "",
			};
			console.log(identData);
			window.ttq.identify(identData);
		}
		switch (eventType) {
			case "add_to_cart":
				window.ttq.track("AddToCart", tikTokData);
				break;
			case "view_item":
				window.ttq.track("ViewContent", tikTokData);
				break;
			case "begin_checkout":
				window.ttq.track("InitiateCheckout", tikTokData);
				break;
			case "purchase":
				window.ttq.track("CompletePayment", tikTokData);
				break;
			// Add other cases for different event types if needed
			default:
				console.warn("Unsupported event type for TikTok:", eventType);
		}
	}

	// -------------PINTEREST--------------
	// Prepare data for Pinterest
	const pinterestLineItems = !singleProduct
		? cart?.lines.map(line => ({
				product_id: line.productVariant.product.id,
				product_name: line.productVariant.product.name,
				product_variant_id: line.productVariant.product.id, // Adjust if you have a specific variant ID
				product_variant: line.productVariant.product.name, // Adjust if you have a specific variant name
				product_price: line.linePrice / 100,
				product_quantity: line.quantity,
		  }))
		: [
				{
					product_id: singleProduct.id, // Product ID or SKU
					product_name: singleProduct.name, // Name of the product
					product_variant_id: singleProductVariantId, // Product variant ID
					product_variant: singleProduct.name, // Adjust if you have a specific variant name
					product_price:
						singleProduct.variants.find(
							v => v.id === singleProductVariantId
						)?.price / 100 || 0, // Price of the product
					product_quantity: 1, // Quantity of the product
				},
		  ];

	const pinterestData = {
		value: (cart?.subTotal || 0) / 100,
		order_quantity: cart?.lines.length || 1,
		currency: "EUR",
		order_id: cart?.code,
		line_items: pinterestLineItems,
	};

	console.log("Pinterest Data:", pinterestData);

	// Send data to Pinterest
	if (window.pintrk && typeof window.pintrk === "function") {
		switch (eventType) {
			case "add_to_cart":
				window.pintrk("track", "addtocart", pinterestData);
				break;
			case "purchase":
				window.pintrk("track", "checkout", pinterestData);
				break;
			case "view_item":
				window.pintrk("track", "pagevisit", pinterestData);
				break;
			// Add other cases for different event types if needed
			default:
				console.warn(
					"Unsupported event type for Pinterest:",
					eventType
				);
		}
	}
}

export function trackPageview() {
	if (window.pintrk && typeof window.pintrk === "function") {
		window.pintrk("track", "pagevisit");
	}
}
