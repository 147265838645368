import {useQuery} from "@apollo/client";
import {Box, Button, Flex, Text} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {
	convertISOToDatetimeLocal,
	findVariant,
	formatPrice,
} from "../../helpers";
import {GET_PRODUCT} from "../../queries";
import {useStore} from "../../Store";
import Loading from "../feedback/Loading";
import Page from "../layout/Page";
import {defaultUserInputs} from "../../defaultUserInputs";
import PreviewWrapper from "../preview/PreviewWrapper";
import AddToCartBtn from "../product/AddToCartBtn";
import Certificate from "../productForms/Certificate";
import StarPoster from "../productForms/StarPoster";
import Message from "../productForms/Message";
import PlanetPoster from "../productForms/PlanetPoster";
import PsMessage from "../productSelectors/PsMessage";
import PsCertificate from "../productSelectors/PsCertificate";
import PsStarPoster from "../productSelectors/PsStarPoster";
import ProductPageHeader from "../product/ProductPageHeader";
import validateForms from "../../validateForms";
import PsPlanetPoster from "../productSelectors/PsPlanetPoster";
import PhotoPoster from "../productForms/PhotoPoster";
import PsPhotoPoster from "../productSelectors/PsPhotoPoster";
import {BORDER_BLACK} from "../../App";
import GiftCard from "../productForms/GiftCard";
import NormalProductGallery from "../product/NormalProductGallery";
import MessagePreview from "../preview/MessagePreview";
import {track} from "../tracking/Tracking";
import Icon from "../elements/Icon";

export default function Configurator() {
	let {slug, relatedLineId} = useParams();

	const {loading, error, data} = useQuery(GET_PRODUCT, {
		variables: {slug},
	});
	const [variant, setVariant] = useState(null);
	const [options, setOptions] = useState([]);
	const [product, setProduct] = useState(null);
	const [userInputs, setUserInputs] = useState();
	const [searchParams, setSearchParams] = useSearchParams();
	const [editMode, setEditMode] = useState(false);
	const [relatedLine, setRelatedLine] = useState();
	const cart = useStore(state => state.cart);
	const [orderLineId, setOrderLineId] = useState(searchParams.get("editid"));
	const [currentOrderLine, setCurrentOrderLine] = useState(null);
	const location = useLocation();
	const [productSelection, setProductSelection] = useState(false);
	const [inputErrors, setInputErrors] = useState({});
	const [showInputErrors, setShowInputErrors] = useState(false);
	const sidebar = useRef();

	let listOfTwoStepAddToCartProducts = [
		"message",
		"starposter",
		"certificate",
		"planetposter",
		"photoposter",
	];

	const twoStepAddToCart =
		listOfTwoStepAddToCartProducts.indexOf(slug) !== -1;

	useEffect(() => {
		if (!product) return;
		track("view_item", cart, product, product.variants[0].id);
	}, [product]);

	useEffect(() => {
		if (!data) return;
		setProduct(data.product);
		if (!currentOrderLine) {
			setVariant(findVariant(data.product, []));
		}
	}, [data]);

	useEffect(() => {
		let newOrderLineId = searchParams.get("editid");
		setOrderLineId(newOrderLineId);
		setEditMode(!!newOrderLineId);
	}, [searchParams]);

	useEffect(() => {
		if (!orderLineId || !cart) return;
		let orderLine = cart.lines.find(line => {
			return String(line.id) === orderLineId;
		});
		setCurrentOrderLine(orderLine);
	}, [orderLineId, cart]);

	useEffect(() => {
		if (!relatedLineId || !cart) return;
		const relatedLine = cart.lines.find(line => line.id === relatedLineId);
		setRelatedLine(relatedLine);
		console.log();
	}, [relatedLineId, cart]);

	useEffect(() => {
		if (!relatedLine) return;
		changeUserInputs(
			"relatedUserInputs",
			JSON.parse(relatedLine.customFields.userInputs)
		);
	}, [relatedLine]);

	useEffect(() => {
		if (
			!userInputs ||
			!userInputs.productSlug ||
			userInputs.productSlug !== slug
		)
			setUserInputs({
				...JSON.parse(JSON.stringify(defaultUserInputs[slug])),
				productSlug: slug,
			});
		validateUserInputs();
	}, [userInputs, slug]);

	// useEffect(() => {
	// 	setOptions([]);
	// }, [slug]);

	// useEffect(() => {
	// 	setRelatedLine(null);
	// }, [slug, location]);

	useEffect(() => {
		/* Set Editmode if orderLineId is in Cart */
		if (!cart) return;
		let matchingLine = cart.lines.find(line => line.id === orderLineId);
		if (matchingLine) {
			/* Set userInputs accordingly */
			if (slug === "giftcard") {
				let newUserInputs = matchingLine.giftCardInput;
				newUserInputs.__typename = undefined;
				newUserInputs.productSlug = slug;
				newUserInputs.deliveryDate = convertISOToDatetimeLocal(
					newUserInputs.deliveryDate
				);
				setUserInputs(newUserInputs);
			} else {
				setUserInputs(JSON.parse(matchingLine.customFields.userInputs));
			}
			setEditMode(true);
		}
	}, [cart, location, orderLineId]);

	// useEffect(() => {
	// 	if (!data || loading || error) return;
	// 	if (data.product.slug !== slug) return;
	// 	console.log(currentOrderLine,options)
	// 	if (currentOrderLine && options.length === 0) {
	// 		let newVariant = data.product.variants.find(
	// 			variant => variant.id === currentOrderLine.productVariant.id
	// 		);
	// 		setVariant(newVariant);
	// 	} else {
	// 		console.log("set first variant you can find")
	// 		setVariant(findVariant(data.product, options));
	// 	}
	// }, [options, currentOrderLine]);

	useEffect(() => {
		if (!data || loading || error || !currentOrderLine) return;
		if (data.product.slug !== slug) return;
		let newVariant = data.product.variants.find(
			variant => variant.id === currentOrderLine.productVariant.id
		);
		setVariant(newVariant);
	}, [currentOrderLine, data]);

	useEffect(() => {
		if (!variant) return;
		let newOptions = [];
		for (const option of variant.options) {
			newOptions.push({group: option.group.code, value: option.code});
		}
		setOptions(newOptions);
	}, [variant]);

	useEffect(() => {
		if (!sidebar.current) return;
		sidebar.current.scrollTop = 0;
	}, [productSelection]);

	function changeOption(group, value) {
		console.log("Change option");
		let newOptions = [...options];
		let optionExists = false;
		for (const option of newOptions) {
			if (option.group === group) {
				optionExists = true;
				option.value = value;
				//setOptions(newOptions);
				break;
			}
		}
		// Wenn Group nicht schon vorhanden
		if (!optionExists) newOptions.push({group, value});
		setOptions(newOptions);
		console.log("find:", findVariant(data.product, newOptions));
		setVariant(findVariant(data.product, newOptions));
	}

	function changeUserInputs(key, value) {
		let newUserInputs = {...userInputs};
		newUserInputs[key] = value;
		setUserInputs(newUserInputs);
	}

	function getForm() {
		const props = {
			changeOption,
			changeUserInputs,
			userInputs,
			options,
			product,
			setOptions,
			setUserInputs,
			inputErrors,
			showInputErrors,
			relatedLine,
			setRelatedLine,
			relatedLineId,
			optionGroups: product.optionGroups,
			priceWithTax: variant.priceWithTax,
		};
		switch (product.slug) {
			case "message":
				return <Message {...props} />;
			case "certificate":
				return <Certificate {...props} />;
			case "starposter":
				return <StarPoster {...props} />;
			case "planetposter":
				return <PlanetPoster {...props} />;
			case "photoposter":
				return <PhotoPoster {...props} />;
			case "giftcard":
				return <GiftCard {...props} />;
			default:
				return "Keine Form verfügbar.";
		}
	}

	function getProductSelection() {
		let selector;
		let props = {
			changeOption,
			options,
			optionGroups: product.optionGroups,
			priceWithTax: variant.priceWithTax,
			product,
		};
		switch (product.slug) {
			case "message":
				selector = <PsMessage {...props} />;
				break;
			case "certificate":
				selector = <PsCertificate {...props} />;
				break;
			case "starposter":
				selector = <PsStarPoster {...props} />;
				break;
			case "planetposter":
				selector = <PsPlanetPoster {...props} />;
				break;
			case "photoposter":
				selector = <PsPhotoPoster {...props} />;
				break;
			default:
				return "Keine Form verfügbar.";
		}
		return selector;
	}

	if (loading || !variant) return <Loading />;
	if (error) {
		console.eror(error.message);
		return "";
	}

	function getAddToCartBtn() {
		return (
			<Flex flex={"1"} alignItems={"center"}>
				<AddToCartBtn
					setEditMode={setEditMode}
					editMode={editMode}
					orderLineId={orderLineId}
					variantId={variant.id}
					userInputs={JSON.stringify(userInputs, null, " ")}
					setSearchParams={setSearchParams}
					relatedLineId={relatedLineId || relatedLine?.id}
					currentOrderLine={currentOrderLine}
					setProductSelection={setProductSelection}
					slug={slug}
					product={product}
				/>
			</Flex>
		);
	}

	function validateUserInputs() {
		if (!userInputs || !slug) return;
		let valid = validateForms(userInputs, slug);
		setInputErrors(valid[slug]);
		return valid.valid;
	}

	const normalGallerySlugs = ["giftcard"];

	if (!userInputs) {
		return <Loading />;
	}

	return (
		<Page
			fullsize={true}
			header={false}
			footer={false}
			title={product.name}
			description={product.customFields?.shortDescription}>
			<Flex
				flexDir={["column", null, "row"]}
				width={"100%"}
				h={"100svh"}
				overflow={"hidden"}>
				<Flex
					display={[
						!productSelection ? "flex" : "none",
						null,
						"flex",
					]}
					flex={1}
					maxH={["45svh", null, "unset"]}
					bgSize={"cover"}
					bgPos={"center"}>
					{normalGallerySlugs.indexOf(slug) !== -1 ? (
						<NormalProductGallery
							product={product}
							slug={slug}
							userInputs={userInputs}
						/>
					) : slug === "message" ? (
						<MessagePreview userInputs={userInputs} />
					) : (
						<PreviewWrapper shopData={userInputs} />
					)}
				</Flex>
				<Flex
					flexDir={"column"}
					flex={1}
					maxW={[null, null, "550px"]}
					position={"relative"}
					ref={sidebar}
					gap={5}
					bgColor={"white"}
					overflow={"auto"}
					justifyContent={"space-between"}
					//boxShadow={["inset 0px 5px 5px -5px #00000078", null, "md"]}
					borderTop={[BORDER_BLACK, null, "none"]}
					borderLeft={["none", null, BORDER_BLACK]}
					className={"productForm"}>
					<Box p={[2, 3, 5]} pt={[0, 0, 5]} pb={"30px"}>
						<ProductPageHeader
							product={product}
							bgColor={"gray.900"}
						/>

						{!productSelection ? getForm() : getProductSelection()}
					</Box>
					<Flex
						flexDir={"column"}
						position={"sticky"}
						bgColor={"white"}
						bottom={"0"}
						w={"100%"}>
						{!twoStepAddToCart || productSelection ? (
							<>
								<Flex
									flex={"0.2"}
									gap={3}
									justifyContent={"center"}>
									<Text>Gesamt: </Text>
									<PriceTag
										price={
											userInputs.value ||
											variant.priceWithTax
										}
									/>
								</Flex>
								<Flex gap={2}>
									{twoStepAddToCart && (
										<Box flex={0.4}>
											<Button
												display={["none", "block"]}
												onClick={() => {
													setProductSelection(false);
												}}
												colorScheme={"gray"}
												width={"100%"}>
												Zurück
											</Button>
											<Button
												display={["block", "none"]}
												onClick={() => {
													setProductSelection(false);
												}}
												colorScheme={"gray"}
												width={"100%"}>
												<Icon
													iconName="arrowBack"
													iconStyle="regular"
												/>
											</Button>
										</Box>
									)}
									{getAddToCartBtn()}
								</Flex>
							</>
						) : (
							<>
								<Button
									onClick={() => {
										if (!validateUserInputs()) {
											setShowInputErrors(true);
											sidebar.current.scrollTo({
												top: 0,
												behavior: "smooth",
											});
										} else {
											setShowInputErrors(false);
											setProductSelection(true);
										}
									}}
									colorScheme={"blackAlpha"}
									borderTop={BORDER_BLACK}
									rightIcon={
										<Icon
											iconName={"arrowforward"}
											iconStyle="regular"
										/>
									}
									w={"100%"}>
									Weiter
								</Button>
							</>
						)}
					</Flex>
				</Flex>
			</Flex>
		</Page>
	);
}

function PriceTag({price}) {
	return (
		<Text fontSize={"lg"} fontWeight={"bold"}>
			{formatPrice(price)}
		</Text>
	);
}
