import {getTimeZone} from "user-timezone";
import {uid} from "./helpers";

let tomorrowDate = new Date();
tomorrowDate.setDate(tomorrowDate.getDate() + 1);
tomorrowDate.setMilliseconds(0);
tomorrowDate.setSeconds(0);
tomorrowDate.setMinutes(0);
tomorrowDate.setHours(20);
export const tomorrowISO = tomorrowDate.toISOString();

export const defaultUserInputs = {
	message: {
		message_medium: "none",
		message_text: "Deine Nachricht",
		message_fileName: null,
		message_origFileName: null,
		message_date: tomorrowISO,
		message_sendYearly: false,
		message_send24: false,
		orientation: "portrait",
		uid: uid(),
	},
	certificate: {
		certificate_medium: "none",
		certificate_printMessage: true,
		poster_variant: "certificate",
		poster_font: "classic",
		poster_textSize: 2.4,
		poster_textPositionX: 0,
		poster_textPositionY: 0,
		poster_useMessageText: true,
		poster_useMessageDate: true,
		poster_size: "a4",
		orientation: "landscape",
		relatedUserInputs: {},
		uid: uid(),
	},
	starposter: {
		poster_medium: "none",
		poster_type: "starMap",
		poster_variant: "normal",
		poster_color: "#000000",
		poster_whiteBg: false,
		poster_date: tomorrowISO,
		poster_lat: "52",
		poster_long: "7",
		poster_text: "",
		poster_showGrid: true,
		poster_showConstellations: true,
		poster_font: "classic",
		poster_textSize: 2.4,
		poster_textPositionX: 0,
		poster_textPositionY: 0,
		poster_location: "Dein Ort",
		poster_fineline: true,
		orientation: "portrait",
		uid: uid(),
	},
	planetposter: {
		poster_medium: "none",
		poster_type: "planetposter",
		poster_date: tomorrowISO,
		poster_text: "",
		poster_font: "classic",
		poster_textSize: 2.4,
		poster_textPositionX: 0,
		poster_textPositionY: 0,
		orientation: "portrait",
		uid: uid(),
	},
	photoposter: {
		poster_medium: "none",
		poster_type: "photoposter",
		poster_variant: "carina",
		poster_text: "Dein Text",
		poster_font: "classic",
		poster_textSize: 2.4,
		poster_textPositionX: 0,
		poster_textPositionY: 0,
		orientation: "landscape",
		uid: uid(),
	},
	giftcard: {
		templateId: "23",
		value: 0,
		deliveryDate: null,
		recipientEmailAddress: null,
		senderName: "",
		message: "",
	},
	necklace: {},
	code: "XC0D3X",
	preferredTimezone: getTimeZone() || "Europe/Berlin",
	currentStep: "message",
};
