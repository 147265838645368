import {Box, Image} from "@chakra-ui/react";
import React from "react";

export default function VendureImage({
	asset,
	w,
	h,
	size = "large",
	coverBlurryBg,
	...props
}) {
	if (!asset) return "";
	const src = asset.source.replace(/\\/g, "/");

	const srcSet = `${src}?format=webp&preset=${size} 1x, ${src}?format=jpg&preset=${size} 1x`;
	const fallbackSrc = `${src}?format=jpg`;

	const img = (
		<Image
			zIndex={1}
			loading="lazy"
			srcSet={srcSet}
			src={fallbackSrc}
			alt={asset.altText}
			objectFit={coverBlurryBg ? "contain" : "cover"}
			objectPosition="center"
			width={w}
			height={h}
			bgColor={"blackAlpha.800"}
			{...props}
		/>
	);
	return (
		<>
			{coverBlurryBg ? (
				<Box w="100%" h="100%" position="relative" overflow={"hidden"}>
					<Image
						filter="blur(12px)" /* Change the value to adjust the amount of blur */
						zIndex={1}
						srcSet={srcSet}
						src={fallbackSrc}
						alt={asset.altText}
						objectFit={"cover"}
						objectPosition="center"
						w="100%"
						h="100%"
						transform={"scale(1.5)"}
						pos={"absolute"}
					/>
					<Box
						w="100%"
						h="100%"
						pos={"absolute"}
						top={0}
						left={0}
						boxShadow={"0px 0px 0px 999px black"}
						zIndex={2}>
						{img}
					</Box>
				</Box>
			) : (
				img
			)}
		</>
	);
}
