import {Box, Collapse, Flex, Text} from "@chakra-ui/react";
import React from "react";
import Icon from "../elements/Icon";

export default function InputError({text, show}) {
	return (
		<Collapse in={show}>
			<Flex
				bgColor={"red.500"}
				color={"white"}
				px={4}
				py={2}
				alignItems={"center"}
				gap={3}
				borderRadius={"sm"}>
				<Icon icon={"info"} />
				<Text color={"white"} m={0}>
					{text}
				</Text>
			</Flex>
		</Collapse>
	);
}
