import {Box, Collapse, Flex, Heading} from "@chakra-ui/react";
import React from "react";
import {useState} from "react";
import Seperator from "../layout/Seperator";
import Icon from "../elements/Icon";

export default function FormSection({
	title,
	children,
	collapsible,
	defaultOpen = true,
}) {
	const [open, setOpen] = useState(defaultOpen);

	return (
		<Box>
			<Heading
				as={"h2"}
				size={"md"}
				my={5}
				cursor={collapsible ? "pointer" : "auto"}
				onClick={() => {
					if (collapsible) setOpen(!open);
				}}
				textAlign={"center"}
				className={"headingLine"}>
				{title}
				{collapsible && (
					<Box ml={2} display={"inline-block"}>
						<Icon icon={!open ? "arrowdown" : "arrowup"} />
					</Box>
				)}
			</Heading>
			<Collapse in={open} animateOpacity>
				<Flex flexDir={"column"} gap={5} pt={2}>
					{children}
				</Flex>
			</Collapse>
		</Box>
	);
}
