import {useTheme} from "@chakra-ui/react";
import React from "react";
import FontPicker from "../elements/FontPicker";
import MainTextTransfomation from "../elements/MainTextTransfomation";
import VariantChooser from "../elements/VariantChooser";
import FormContainer from "./FormContainer";
import FormField from "./FormField";
import FormSection from "./FormSection";

export default function PhotoPoster({
	changeOption,
	changeUserInputs,
	userInputs,
	setUserInputs,
}) {
	const photoVariants = [
		// {name: "mondnacht", orientation: "portrait"},
		// {name: "nebula", orientation: "portrait"},
		// {name: "erdenlicht", orientation: "landscape"},
		// {name: "milchstrasse", orientation: "landscape"},
		{name: "carina", orientation: "landscape"},
		{name: "moon", orientation: "portrait"},
		{name: "deepfield", orientation: "portrait"},
		{name: "nebula", orientation: "portrait"},
		{name: "quintet", orientation: "portrait"},
	];

	function changeVariant(key, value) {
		let newUserInputs = {...userInputs};
		newUserInputs[key] = value;
		newUserInputs.orientation = photoVariants.find(
			v => v.name === newUserInputs.poster_variant
		).orientation;
		console.log(newUserInputs)
		setUserInputs(newUserInputs);
	}

	return (
		<FormContainer>
			<FormSection title={"Wähle Dein Motiv"}>
				<VariantChooser
					options={photoVariants.map(variant => {
						return {
							icon: null,
							image:
								process.env.REACT_APP_CREATOR_URL +
								"/img/" +
								variant.name +
								"_small.jpg",
							val: variant.name,
						};
					})}
					keyName={"poster_variant"}
					change={changeVariant}
					value={userInputs.poster_variant}
					selectedColor={userInputs.poster_color || "blue.500"}
				/>
				{photoVariants.map(variant => {
					return "";
				})}
			</FormSection>
			<FormSection title={"Deine Widmung"}>
				<FormField
					type={"text"}
					title={"Dein Text"}
					placeholder={"Dear Universe,..."}
					fieldName={"poster_text"}
					changeUserInputs={changeUserInputs}
					value={userInputs.poster_text || ""}
				/>
				<FontPicker
					change={changeUserInputs}
					font={userInputs.poster_font}
				/>
				<MainTextTransfomation
					change={changeUserInputs}
					userInputs={userInputs}
				/>
			</FormSection>
		</FormContainer>
	);
}
