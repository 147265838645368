import {
	Box,
	Button,
	Flex,
	List,
	ListItem,
	Text,
	useTheme,
} from "@chakra-ui/react";
import React from "react";
import {BORDER_BLACK} from "../../App";
import {
	bgGradient,
	bgGradientSoft,
	bgGradientSoftLight,
} from "../../theme/foundations/colors";
import Icon from "./Icon";

export default function SelectableBox({
	selected,
	select,
	children,
	label,
	listItems,
	disabled,
	...rest
}) {
	const theme = useTheme();
	return (
		<Flex
			py={2}
			px={3}
			opacity={disabled ? "0.7" : "1"}
			borderStyle={"solid"}
			borderColor={selected ? "black" : "gray.300"}
			bg={selected ? "darkBlue" : "transparent"}
			color={selected ? "white" : "darkBlue"}
			borderWidth={"1px"}
			borderRadius={"sm"}
			boxShadow={"sm"}
			position={"relative"}
			flex={1}
			alignItems={"center"}
			justifyContent={"space-between"}
			flexDir={"row"}
			onClick={select}
			fontWeight={"bold"}
			cursor={"pointer"}
			textAlign={"left"}
			{...rest}>
			<Flex flexDir={"column"}>
				<Text
					fontSize={"xl"}
					mb={0}
					color={selected ? "white" : "darkBlue"}>
					{label || ""}
				</Text>
				{!!listItems && (
					<List
						listStyleType={"initial"}
						my={1}
						fontSize={"sm"}
						fontWeight={"normal"}>
						{listItems.map((item, i) => (
							<ListItem key={"item" + i}>{item}</ListItem>
						))}
					</List>
				)}
				{children}
			</Flex>
			{selected ? (
				<Flex
					ml={"auto"}
					bgColor={"white"}
					borderRadius={"full"}
					p={3}
					mr={4}
					width={"40px"}
					height={"40px"}>
					<Icon
						iconName={"checkmark"}
						iconStyle="regular"
						color="darkBlue"
						size={"2x"}
					/>
				</Flex>
			) : (
				<Button
					minW={"min-content"}
					ml={"auto"}
					size={"sm"}
					w={"auto"}
					bgColor={"darkBlue"}
					color={"white"}
					isDisabled={disabled}
					onClick={select}>
					Auswählen
				</Button>
			)}
		</Flex>
	);
}
