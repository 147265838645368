import {Box} from "@chakra-ui/react";
import React from "react";

export default function AwinTracking({order}) {
	if (order.state !== "PaymentAuthorised" && order.state !== "PaymentSettled")
		return "";

	function getCode() {
		let text = `
        <!-- Fall-back Conversion Pixel - Mandatory -->
            <img src="https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=21329&amount=${
                        order.subTotal / 100
                    }&cr=EUR&ref=${order.code}&parts=DEFAULT:${order.subTotal / 100}&vc=${
                        order.couponCodes[0] || ""
                    }&ch=aw" border="0" width="0" height="0">

            <!-- Conversion Tag - Mandatory -->
        <script type="text/javascript">
            //<![CDATA[ /*** Do not change ***/
            var AWIN = {};
            AWIN.Tracking = {};
            AWIN.Tracking.Sale = {};
            /*** Set your transaction parameters ***/
            AWIN.Tracking.Sale.amount = "${order.subTotal / 100}";
            AWIN.Tracking.Sale.orderRef = "${order.code}";
            AWIN.Tracking.Sale.parts = "DEFAULT:${order.subTotal / 100}";
            AWIN.Tracking.Sale.voucher = "${order.couponCodes[0] || ""}";
            AWIN.Tracking.Sale.currency = "EUR";
            AWIN.Tracking.Sale.channel = "aw";
            //]]>
        </script>`;
        return text;
	}

	return <Box dangerouslySetInnerHTML={{__html: getCode()}}></Box>;
}
