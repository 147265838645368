import {
	Box,
	Collapse,
	Flex,
	FormControl,
	FormLabel,
	Text,
} from "@chakra-ui/react";
import React from "react";
import {useState} from "react";
import {useEffect} from "react";
import ColorPicker from "../elements/ColorPicker";
import DateTimePicker from "../elements/DateTimePicker";
import FontPicker from "../elements/FontPicker";
import SlideSwitch from "../elements/SlideSwitch";
import TinyPreview from "../elements/TinyPreview";
import VariantChooser from "../elements/VariantChooser";
import FormContainer from "./FormContainer";
import FormField from "./FormField";
import FormSection from "./FormSection";
import ReactGoogleAutocomplete, {
	usePlacesWidget,
} from "react-google-autocomplete";
import geocoder from "google-geocoder";
import MainTextTransfomation from "../elements/MainTextTransfomation";
import RadioButtons from "./RadioButtons";
import InputError from "./InputError";
import MainText from "./MainText";

export default function StarPoster({
	changeOption,
	changeUserInputs,
	userInputs,
	setUserInputs,
	inputErrors,
	showInputErrors,
	options,
	optionGroups,
	product,
	priceWithTax,
}) {
	const [location, setLocation] = useState(null);

	var geo = geocoder({
		key: process.env.REACT_APP_GOOGLE_API_KEY,
	});
	const {ref} = usePlacesWidget({
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		onPlaceSelected: setLocation,
		options: {fields: ["place_id", "name", "types"]},
	});

	function updateLocation() {
		geo.find(location.name, function (err, res) {
			let newData = {...userInputs};
			newData.poster_location = location.name;
			newData.poster_lat = res[0].location.lat;
			newData.poster_long = res[0].location.lng;
			setUserInputs(newData);
		});
	}
	useEffect(() => {
		console.log(location);
		if (!location) return;
		updateLocation();
	}, [location]);

	// useEffect(() => {
	// 	console.log("newErrors:", inputErrors);
	// }, [inputErrors]);

	function getCoordText() {
		let data = userInputs;

		if (data.poster_lat === "52") {
			return "Wähle einen Ort...";
		}
		let NS = "N";
		if (data.poster_lat < 0) {
			NS = "S";
		}
		let WO = "W";
		if (data.poster_long > 0) {
			WO = "O";
		}
		return (
			Math.abs(data.poster_lat).toFixed(4) +
			"°" +
			NS +
			", " +
			Math.abs(data.poster_long).toFixed(4) +
			"°" +
			WO
		);
	}
	const colors = [
		{name: "black", val: "#000000"},
		{name: "midnight", val: "#20263c"},
		{name: "blue", val: "#064f76"},
		{name: "deepblue", val: "#0F2C6F"},
		{name: "gray", val: "#474A4F"},
		{name: "darkviolet", val: "#6c5b7b"},
		{name: "violet", val: "#45ada8"},
		{name: "g", val: "#ff847c"},
		/* {name: "ombre-dark-blue", val: "#0c1222,#4e95af"},
		{name: "ombre-dark", val: "#140830,#6E78FF"},
		{name: "ombre-dark2", val: "#0A031B,#0F2C6F"}, */
	];

	return (
		<FormContainer>
			<FormSection title={"Datum und Ort Deines Moments"}>
				<DateTimePicker
					title={"Datum"}
					date={userInputs.poster_date}
					keyName={"poster_date"}
					change={changeUserInputs}
					help={"Welches Datum soll das Poster zeigen?"}
				/>
				<InputError
					show={showInputErrors && inputErrors.dateInvalid}
					text={
						"Dieses Datum ist irgendwie falsch. Versuche es noch einmal."
					}
				/>
				<FormControl>
					<FormLabel htmlFor="location">Dein Ort</FormLabel>
					<ReactGoogleAutocomplete
						className="text-input"
						placeholder={"London"}
						apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
						onPlaceSelected={setLocation}
						options={{
							fields: ["place_id", "name", "types"],
						}}
					/>
					<Text fontSize={"2xs"} mt={2} pl={1}>
						{getCoordText()}
					</Text>
				</FormControl>
				<InputError
					show={showInputErrors && inputErrors.locationMissing}
					text={
						"Wähle bitte einen Ort aus der Popup-Liste, die erscheint, wenn Du den Ort eingibst."
					}
				/>
			</FormSection>
			<FormSection title={"Personalisiere das Layout"}>
				<ColorPicker
					colors={colors}
					change={changeUserInputs}
					color={userInputs.poster_color}
				/>
				<VariantChooser
					title={"Wähle eine Kartenform"}
					options={[
						{
							icon: "circle",
							val: "normal",
						},
						{
							icon: "compass",
							val: "compass",
						},
						{
							icon: "heart",
							val: "heart",
						},
					]}
					keyName={"poster_variant"}
					change={changeUserInputs}
					value={userInputs.poster_variant}
					selectedColor={userInputs.poster_color}
				/>
				<Box mt={4}>
					<SlideSwitch
						label={"Weißer Hintergrund"}
						keyName={"poster_whiteBg"}
						change={changeUserInputs}
						isChecked={userInputs.poster_whiteBg}
					/>
					<SlideSwitch
						label={"Umrandungen"}
						keyName={"poster_fineline"}
						change={changeUserInputs}
						isChecked={userInputs.poster_fineline}
						last
					/>
				</Box>
			</FormSection>
			<FormSection title={"Deine Widmung"}>
				<MainText
					changeUserInputs={changeUserInputs}
					value={userInputs.poster_text}
				/>
				<Box opacity={userInputs.poster_text !== "" ? "1" : "0.5"}>
					<FontPicker
						change={changeUserInputs}
						font={userInputs.poster_font}
					/>
					<MainTextTransfomation
						change={changeUserInputs}
						userInputs={userInputs}
					/>
				</Box>
			</FormSection>
			<FormSection title={"Weitere Optionen"}>
				<Box>
					<SlideSwitch
						label={"Sternbilder"}
						keyName={"poster_showConstellations"}
						change={changeUserInputs}
						isChecked={userInputs.poster_showConstellations}
					/>
					<SlideSwitch
						label={"Koordinatensystem"}
						keyName={"poster_showGrid"}
						change={changeUserInputs}
						isChecked={userInputs.poster_showGrid}
						last
					/>
				</Box>
			</FormSection>
		</FormContainer>
	);
}
