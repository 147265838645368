import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	CircularProgress,
	CloseButton,
	FormControl,
	FormLabel,
	Input,
	Text,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {makeCall} from "../../API";
import CheckAttachmentLink from "../feedback/CheckAttachmentLink";
import Icon from "../elements/Icon";

export default function FileUpload({file, setFile, userInputs, setUserInputs}) {
	const [isUploading, setIsUploading] = useState(false);
	const [fileError, setFileError] = useState(null);

	async function uploadFile(event) {
		setIsUploading(true);
		const newFile = event.target.files[0];
		if (!newFile) {
			setIsUploading(false);
			return;
		}
		const checkFile = {
			size: newFile.size,
			type: newFile.name.split(".").pop(),
		};

		// console.log(
		// 	newFile.size,
		// 	newFile.name,
		// 	newFile.type,
		// 	newFile.webkitRelativePath
		// );

		let fileTest = await verifyFile(checkFile);

		if (!fileTest.status) {
			setFileError(fileTest);
			setFile(checkFile);
			setIsUploading(false);
			return;
		}

		setFile(newFile);
		const data = new FormData();
		data.append("file", newFile);

		let response;
		try {
			response = await makeUploadCall(data, userInputs.message_fileName);
		} catch (error) {
			alert("Catch block", error);
			console.log(error);
			console.trace();
			setFileError(fileTest);
			setFile(checkFile);
			setIsUploading(false);
			return;
		}

		if (response.filename) {
			changeMany([
				{key: "message_fileName", value: response.filename},
				{key: "message_origFileName", value: response.originalname},
			]);
			setFileError(null);
		} else {
			alert(
				"Leider ist ein Fehler aufgetreten. Versuche es vielleicht später noch einmal.\n" +
					JSON.stringify(response)
			);
			changeMany([
				{key: "message_fileName", value: null},
				{key: "message_origFileName", value: null},
			]);
		}
		setIsUploading(false);
	}

	function changeMany(changes) {
		let newUserInputs = {...userInputs};
		for (let change of changes) {
			newUserInputs[change.key] = change.value;
		}
		setUserInputs(newUserInputs);
	}

	async function verifyFile(file) {
		let result = await makeCall("/messagefile/verify", "POST", file);
		console.log(result);
		return result;
	}

	return (
		<FormControl>
			<FormLabel htmlFor="file" w={"max-content"}>
				Deine Datei (optional)
			</FormLabel>
			<FormLabel htmlFor="file" w={"min-content"} m={0}>
				<Box
					cursor={"pointer"}
					borderRadius={7}
					bgColor={file ? "white" : ""}
					color={file ? "gray.900" : "gray.800"}
					border={"1px solid"}
					p={3}
					fontSize={18}
					w={"max-content"}>
					<Box mr={3} display={"inline-block"}>
						<Icon icon={"upload"} />
					</Box>
					{userInputs.message_fileName
						? "Datei ändern"
						: "Datei auswählen"}
				</Box>
			</FormLabel>
			<Box mt={5}>
				{userInputs.message_origFileName ? (
					<Alert status="success" variant="solid" borderRadius={7}>
						<AlertIcon />
						{userInputs.message_origFileName}
						<CloseButton
							onClick={() => {
								deleteFile(
									userInputs.message_fileName,
									changeMany([
										{key: "message_fileName", value: null},
										{
											key: "message_origFileName",
											value: null,
										},
									])
								);
							}}
							position="absolute"
							right="8px"
							top="8px"
						/>
					</Alert>
				) : (
					""
				)}
				{userInputs.message_fileName && (
					<Box mt={3}>
						<CheckAttachmentLink
							fileName={userInputs.message_fileName}
						/>
					</Box>
				)}
				{fileError && (
					<>
						<Alert status="error" variant="solid" borderRadius={7}>
							<AlertIcon />
							<AlertTitle mr={2}>
								Leider ist ein Fehler aufgetreten
							</AlertTitle>
							<AlertDescription>
								{fileError.message}
							</AlertDescription>
							<CloseButton
								onClick={() => {
									setFileError(null);
								}}
								position="absolute"
								right="8px"
								top="8px"
							/>
						</Alert>
						<Text
							mt={3}
							display={
								fileError.message === "fileSizeError"
									? "block"
									: "none"
							}>
							<strong>Maximale Dateigröße:</strong>{" "}
							{bytesToSize(fileError.maxFileSize)} (Deine:{" "}
							{bytesToSize(file?.size)})
						</Text>
						<Text
							display={
								fileError.message === "fileTypeError"
									? "block"
									: "none"
							}>
							<strong>Unterstützte Formate:</strong>{" "}
							{fileError.allowedFileTypes.join(", ")}
						</Text>
					</>
				)}
			</Box>
			<CircularProgress
				display={isUploading ? "block" : "none"}
				isIndeterminate={true}
			/>
			<Input
				id="file"
				type="file"
				display={"none"}
				onChange={uploadFile}
			/>
		</FormControl>
	);
}

export function bytesToSize(bytes) {
	var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	if (bytes === 0) return "0 Byte";
	var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export async function deleteFile(fileName, cb) {
	let response = await makeUploadCall(null, fileName);
	if (response.status === 200) {
		if (cb) cb();
		let uploadField = document.getElementById("file");
		if (uploadField) uploadField.value = null;
	}
}

async function makeUploadCall(file, oldfile) {
	/* Only delete mit file === null */
	let result;
	if (file) {
		result = await makeCall("/messagefile", "POST", file, true);
	}
	if (oldfile) {
		if (!file) {
			//Only delete
			result = await makeCall("/messagefile", "DELETE", {
				id: oldfile,
			});
		} else {
			/* No need to await and return when there is a new file */
			makeCall("/messagefile", "DELETE", {id: oldfile});
		}
	}
	return result;
}
