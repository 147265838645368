import {gql, useQuery} from "@apollo/client";
import {Badge, Button, Flex, Heading, Text} from "@chakra-ui/react";
import React, {useContext} from "react";
import {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {client} from "../..";
import {ADD_ORDER_TO_SESSION} from "../../queries";
import {useStore} from "../../Store";
import CartProductGrid from "../cart/CartProductGrid";
import ClearCartBtn from "../cart/ClearCartBtn";
import {CartOrderSummary} from "../cart/OrderSummary";
import Loading from "../feedback/Loading";
import Page from "../layout/Page";
import Icon from "../elements/Icon";
import {bgGradientSoftGreen} from "../../theme/foundations/colors";

export default function Cart() {
	const cart = useStore(state => state.cart);
	const setCart = useStore(state => state.setCart);

	const {orderCode} = useParams();

	useEffect(() => {
		async function addOrderToSession() {
			if (!orderCode) return;
			if (orderCode === cart?.code) return;

			let result = await client.mutate({
				mutation: ADD_ORDER_TO_SESSION,
				variables: {code: orderCode},
			});
			console.log(result);
			if (result.data.addOrderToSession?.code) {
				setCart(result.data.addOrderToSession);
			}
		}
		addOrderToSession();
	}, [orderCode]);

	if (orderCode && orderCode !== cart?.code) {
		return <Loading />;
	}

	if (!cart && cart !== null) {
		return <Loading />;
	}

	return (
		<Page heading={"Warenkorb"} footer>
			{cart && cart.totalQuantity !== 0 ? (
				<>
					<Badge
						mx={"auto"}
						w={"max-content"}
						borderRadius="full"
						p="2"
						px={5}
						colorScheme={"green"}
						textTransform={"none"}
						fontWeight={"normal"}
						bg={bgGradientSoftGreen}>
						Gute Wahl!
					</Badge>
					<Flex gap={5} flexWrap={"wrap"} justifyContent={"center"}>
						<CartProductGrid cart={cart}>
							<Link to={"/products"}>
								<Button
									width={"max-content"}
									variant={"outline"}>
									Weiter stöbern
								</Button>
							</Link>
						</CartProductGrid>
						<CartOrderSummary cart={cart} />
					</Flex>
				</>
			) : (
				<Flex flexDir={"column"} alignItems={"center"}>
					<Icon iconName={"stars"} size={"3xl"} />
					<Heading
						as={"h2"}
						textAlign={"center"}
						size={"md"}
						mt={"6"}>
						Keine Produkte im Warenkorb...
					</Heading>
					<Text>
						<Link to={"/"}>Füge welche hinzu!</Link>
					</Text>
				</Flex>
			)}
		</Page>
	);
}
