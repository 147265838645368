import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Checkbox,
	Stack,
	Button,
	Heading,
	Text,
	useColorModeValue,
	InputRightAddon,
	InputGroup,
	useToast,
	Link,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {useStore} from "../../Store";
import {client} from "../..";
import {LOGIN, REGISTER} from "../../queries";
import Icon from "../elements/Icon";

export default function LoginForm({register, oneClick, order}) {
	const [email, setEmail] = useState("");
	const [pw, setPw] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [rememberMe, setRememberMe] = useState(true);
	const [loading, setLoading] = useState(false);
	const user = useStore(state => state.user);
	const getUser = useStore(state => state.getUser);
	const toast = useToast();
	const navigate = useNavigate();

	async function auth() {
		setLoading(true);
		let success;
		let error;
		if (!pw) {
			toast({
				title: "Bitte wähle ein Passwort",
				status: "warning",
				duration: 5000,
				isClosable: true,
			});
			setLoading(false);
			return;
		}
		if (register) {
			let res = await client.mutate({
				mutation: REGISTER,
				variables: {
					firstName: firstName || order.customer.firstName,
					lastName: lastName || order.customer.lastName,
					email: email || order.customer.emailAddress,
					password: pw,
				},
			});
			console.log(res);
			success = res.data.registerCustomerAccount.success;
			error = res.data.registerCustomerAccount.message;
		} else {
			let res = await client.mutate({
				mutation: LOGIN,
				variables: {
					username: email,
					password: pw,
				},
			});
			console.log(res);
			success = !!res.data.login.id;
			error = res.data.login.message;
		}
		if (success) {
			if (register) {
				navigate("/verify");
			} else {
				await getUser();
				toast({
					title: "Erfolgreich angemeldet.",
					description: "Willkommen zurück!",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			}
		} else {
			toast({
				title: "Leider ist etwas schief gelaufen...",
				description:
					error ||
					"Versuche es später noch einmal oder wende Dich an den Support.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		setLoading(false);
	}

	const [pwVisible, setPwVisible] = useState(false);
	return (
		<Flex align={"center"} justify={"center"}>
			<Stack spacing={8} mx={"auto"} maxW={"lg"}>
				<Box rounded={"sm"} bg={"white"} boxShadow={"sm"} p={[5, 8]}>
					<Stack spacing={4}>
						{!oneClick && (
							<>
								{register && (
									<Flex gap={3}>
										<FormControl id="firstName">
											<FormLabel>Vorname</FormLabel>
											<Input
												type="firstName"
												value={firstName}
												onChange={e => {
													setFirstName(
														e.target.value
													);
												}}
											/>
										</FormControl>
										<FormControl id="lastName">
											<FormLabel>Nachname</FormLabel>
											<Input
												type="lastName"
												value={lastName}
												onChange={e => {
													setLastName(e.target.value);
												}}
											/>
										</FormControl>
									</Flex>
								)}
								<FormControl id="email">
									<FormLabel>E-Mail Adresse</FormLabel>
									<Input
										type="email"
										value={email}
										onChange={e => {
											setEmail(e.target.value);
										}}
									/>
								</FormControl>
							</>
						)}
						{oneClick && (
							<Text>Email: {order?.customer.emailAddress}</Text>
						)}
						<FormControl id="password">
							<FormLabel>Password</FormLabel>
							<InputGroup>
								<Input
									type={pwVisible ? "text" : "password"}
									value={pw}
									onChange={e => {
										setPw(e.target.value);
									}}
								/>
								<InputRightAddon
									onClick={() => {
										setPwVisible(!pwVisible);
									}}>
									<Icon
										iconName={pwVisible ? "hide" : "show"}
									/>
								</InputRightAddon>
							</InputGroup>
						</FormControl>
						<Flex flexDir={"column"}>
							{!register && (
								<Stack
									mt={5}
									direction={{base: "column", sm: "row"}}
									align={"start"}
									justify={"space-between"}>
									<Checkbox
										onChange={() => {
											setRememberMe(!rememberMe);
										}}
										isChecked={rememberMe}>
										Angemeldet bleiben
									</Checkbox>
									<Link
										color={"blue.400"}
										href={"/password-reset"}>
										Passwort vergessen?
									</Link>
								</Stack>
							)}
							<Button mt={10} isLoading={loading} onClick={auth}>
								{register
									? oneClick
										? "OneClick Registrierung"
										: "Registrieren"
									: "Anmelden"}
							</Button>
							{!oneClick && (
								<Button
									mt={5}
									variant={"link"}
									onClick={() => {
										navigate(
											register ? "/login" : "/register"
										);
									}}>
									{register
										? "Du hast bereits einen Account?"
										: "Du hast noch kein Account?"}
								</Button>
							)}
						</Flex>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	);
}
