import {Routes, Route, NavLink, Navigate, useNavigate} from "react-router-dom";
import {useStore} from "../../Store";

export const ProtectedRoute = ({children}) => {
	const user = useStore(state => state.user);

	if (!user) {
		return <Navigate to="/login" replace />;
	}

	return children;
};
