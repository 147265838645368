import colors from "../foundations/colors";

const Button = {
	baseStyle: {
		fontWeight: "bold",
		borderRadius: "none",
		fontFamily: "Montserrat, sans-serif",
	},
	sizes: {
		sm: {
			fontSize: "sm",
			px: 4,
			py: 2,
		},
		md: {
			fontSize: "md",
			px: 6,
			py: 3,
		},
		lg: {
			fontSize: "lg",
			px: 8,
			py: 4,
		},
	},
	variants: {
		solid: {
			bg: colors.accent,
			color: "white",
			fontWeight: "normal",
			transition: "filter 0.2s ease-in-out",
			_hover: {bg: "", filter: "brightness(1.07)"},
		},
		outline: {
			borderColor: colors.accent,
			color: colors.accent,
			fontWeight: "normal",
			transition: "filter 0.2s ease-in-out",
			_hover: {bg: "transparent", filter: "brightness(1.1)"},
		},
		ghost: {
			fontWeight: "normal",
		},
	},
	defaultProps: {
		size: "md",
		variant: "solid",
	},
};

export default Button;
