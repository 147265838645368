import {
	AspectRatio,
	Box,
	Button,
	Center,
	Flex,
	Heading,
	Image,
	Stack,
	Text,
} from "@chakra-ui/react";
import React from "react";
import {Link} from "react-router-dom";
import {useParallax} from "react-scroll-parallax";
import {useStore} from "../../Store";
import VendureImage from "../elements/VendureImage";
import Truncate from "../layout/Truncate";
import {findMessagesFromCart} from "../productForms/Certificate";
import ReviewBadge from "../reviews/ReviewBadge";
import {boxShadow} from "../../theme/foundations/shadow";
import Icon from "../elements/Icon";

export default function ProductCard({heading, product, price, asset, slug}) {
	const cart = useStore(state => state.cart);
	/* No Message yet, so some of the UI has to be rewritten */
	const messageMissingAndIsCert =
		findMessagesFromCart(cart).length === 0 && slug === "certificate";
	const linkDestination = messageMissingAndIsCert ? "message" : slug;

	const infoLink = "/product/" + slug;

	return (
		<Flex
			flexDir={["column", null, "row"]}
			w={"100%"}
			h={"100%"}
			overflow={"hidden"}>
			<AspectRatio
				width={["100%", null, "300px"]}
				pos={"relative"}
				ratio={1}>
				<Box
					style={{
						aspectRatio: "1 / 1",
					}}>
					<Link to={infoLink} title={`Infos zu ${heading}`}>
						<VendureImage
							alt={`${heading} - Produkt von DearUniverse`}
							asset={asset}
							w={"100%"}
							h={"100%"}
							size={"medium"}
							objectFit={"cover"}
						/>
					</Link>
				</Box>
			</AspectRatio>
			<Flex flexDir={"column"} gap={3} flex={1} p={[5, null, 10]}>
				<Box>
					<Link to={infoLink} title={`Infos zu ${heading}`}>
						<Heading fontSize={"2xl"} mb={1}>
							{heading}
						</Heading>
					</Link>
					<ReviewBadge
						reviews={product.reviews}
						link={infoLink + "#reviews"}
					/>
				</Box>
				<Box>
					<Box>
						{product.customFields?.shortDescription ? (
							<Text>{product.customFields.shortDescription}</Text>
						) : (
							<Truncate text={product.description} length={90} />
						)}
					</Box>
					<Link to={infoLink} title={`Infos zu ${heading}`}>
						<Text as={"span"} color={"blue.500"}>
							Mehr erfahren
						</Text>
					</Link>
				</Box>
				<Flex
					justifyContent={"space-between"}
					alignContent={"center"}
					mt={"auto"}>
					<Text
						color={"gray.800"}
						as={"span"}
						fontWeight={"bold"}
						fontSize={"md"}
						letterSpacing={1.1}
						display={"flex"}
						alignItems={"center"}>
						{price}
					</Text>
				</Flex>

				<Box>
					<Link
						to={
							"/product/" +
							(!product.customFields.standardProductPage
								? "configurator/"
								: "") +
							linkDestination
						}
						title={`Produktseite von ${heading}`}>
						<Button
							size={["sm", "md"]}
							rightIcon={
								<Icon
									icon={"arrowforward"}
									iconStyle="regular"
								/>
							}>
							{messageMissingAndIsCert
								? "Zuerst Nachricht erstellen"
								: product.customFields.standardProductPage
								? "Zum Produkt"
								: "Personalisieren"}
						</Button>
					</Link>
				</Box>
			</Flex>
		</Flex>
	);
}
