import { Box } from '@chakra-ui/react'
import React from 'react'

export default function Seperator() {
  return (
    <Box w={"100%"} py={4} color={"gray.400"}>
        <hr />
    </Box>
  )
}
