const colors = {
	background: "white",
	accent: "#7D86B0",
	darkBackground: "#0C1B3D",
	grayElement: "#BFC0C4",
	lightBlue: "#7D86B0",
	darkBlue: "#0C1B3D",
	blue: {
		50: "#f8f8f8",
		100: "#c8cde1",
		200: "#b3b9d3",
		300: "#9aa0c3",
		400: "#8a92b8",
		500: "#727da4",
		600: "#606a92",
		700: "#3f4c71",
		800: "#2c3b5e",
		900: "#0e1e40",
	},
	orange: {
		50: "#fff1e2",
		100: "#f4d9bc",
		200: "#eabf94",
		300: "#e1a66b",
		400: "#d88c42",
		500: "#bf7429",
		600: "#95591e",
		700: "#6b4015",
		800: "#41260a",
		900: "#1a0c00",
	},
	green: {
		50: "#ecfde3",
		100: "#cff4bc",
		200: "#adeb93",
		300: "#89e369",
		400: "#74db40",
		500: "#64c126",
		600: "#55961c",
		700: "#416b12",
		800: "#2a4008",
		900: "#0c1600",
	},
};

export const bgGradient = "linear-gradient(90deg, #ffc98a, #ee6bff)";
export const bgGradientSoft = "linear-gradient(90deg, #ecb26f, #eca980)";
export const bgGradientSoftLight = "linear-gradient(90deg, #f1c797, #eebc9e)";
export const bgGradientSoftGreen =
	"linear-gradient(90deg, var(--chakra-colors-green-200), var(--chakra-colors-green-300))";

export default colors;
