import {
	Box,
	Flex,
	FormLabel,
	Image,
	Radio,
	RadioGroup,
	Stack,
} from "@chakra-ui/react";
import React from "react";
import Icon from "./Icon";

export default function VariantChooser({
	options,
	keyName,
	change,
	title,
	value,
	selectedColor,
	image,
}) {
	return (
		<RadioGroup
			onChange={val => {
				if (val === "true") val = true;
				if (val === "false") val = false;
				change(keyName, val);
			}}>
			<FormLabel>{title}</FormLabel>
			<Flex gap={5}>
				{options.map(option => {
					const checked = value === option.val;
					return (
						<Radio
							hidden
							value={option.val}
							fontSize={"2xl"}
							key={keyName + option.val}>
							<Box
								p={!option.image ? 2 : 0.5}
								borderRadius={"sm"}
								bgColor={checked ? selectedColor : "none"}
								border={
									checked
										? "1px solid white"
										: "1px solid transparent"
								}
								boxShadow={
									checked
										? "0px 0px 0px 2px " + selectedColor
										: "none"
								}
								color={
									value === option.val ? "white" : "gray.500"
								}>
								{option.component}
								{option.image && (
									<Image
										src={option.image}
										width={"90px"}></Image>
								)}
								{option.icon && (
									<Icon
										iconStyle="regular"
										iconName={option.icon}
										size={"2xl"}
									/>
								)}
							</Box>
						</Radio>
					);
				})}
			</Flex>
		</RadioGroup>
	);
}
