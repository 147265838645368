import React from "react";
import {Box, Heading, Text, VStack} from "@chakra-ui/react";
import Icon from "../elements/Icon";

export const MessageFeatures = () => {
	return (
		<VStack spacing={3} align="start" fontSize={"sm"} color={"blue.700"}>
			<Box>
				<Text>
					<Icon iconName={"infinite"}>EWIGE BOTSCHAFT IM ALL</Icon>
				</Text>
			</Box>

			<Box>
				<Text>
					<Icon iconName={"stars"}>DASHBOARD ZUM NACHVERFOLGEN</Icon>
				</Text>
			</Box>

			<Box>
				<Text>
					<Icon iconName={"heart"}>
						ERINNERUNGEN UND WÜNSCHE VEREWIGEN
					</Icon>
				</Text>
			</Box>
			<Box>
				<Text>
					<Icon iconName={"shield"}>WISSENSCHAFTLICH GEPRÜFT</Icon>
				</Text>
			</Box>
		</VStack>
	);
};
