const typography = {
	fonts: {
		heading: "Montserrat, sans-serif",
		body: "Montserrat, sans-serif",
		special: "Addington, sans-serif",
		decorative: "Alex Brush Regular, cursive",
	},
};

export default typography;
