import {FormLabel, Radio, RadioGroup, Stack, Text} from "@chakra-ui/react";
import React from "react";

export default function FontPicker({change, font}) {
	return (
		<RadioGroup
			my={4}
			color={"gray.800"}
			value={String(font)}
			onChange={e => {
				change("poster_font", e);
			}}>
			<FormLabel>Schriftart auswählen</FormLabel>
			<Stack direction={"column"} spacing={3}>
			<Radio value="classic">
					<Text fontSize="24px" fontFamily={"Classic"}>
						Klassisch
					</Text>
				</Radio>
				<Radio value="greek">
					<Text fontSize="24px" fontFamily={"Greek"}>
						Griechisch
					</Text>
				</Radio>
				<Radio value="fancy">
					<Text fontSize="24px" fontFamily={"Fancy"}>
						Verschnörkelt
					</Text>
				</Radio>
				<Radio value="minimalist">
					<Text fontSize="24px" fontFamily={"Minimalist"}>
						Minimalistisch
					</Text>
				</Radio>
			</Stack>
		</RadioGroup>
	);
}
