import React, {useState, useEffect} from "react";
import {Box, Flex, Text} from "@chakra-ui/react";
import Menu, {CartIcon, MenuLeft} from "./Menu";
import {Link, useLocation} from "react-router-dom";
import Logo from "./Logo";
import {BORDER_BLACK} from "../../App";
import PreHeader, {PreheaderHeight} from "./PreHeader";
import {maxPageWidth} from "./Page";
import Icon from "../elements/Icon";
import IconButton from "./IconButton";

export const HeaderHeight = "95px";

export default function Header() {
	let location = useLocation();

	const [isHomepage, setIsHomepage] = useState(location.pathname === "/");
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		setIsHomepage(location.pathname === "/");
		window.scrollTop = 0;
	}, [location.pathname]);

	return (
		<>
			<PreHeader />
			<Flex
				position={"relative"}
				w={"100%"}
				bgColor="white"
				pos={"sticky"}
				top={0}
				px={[2, 3, 5]}
				py={5}
				h={HeaderHeight}
				borderBottom={BORDER_BLACK}
				justifyContent={"center"}
				zIndex={2}
				display={"flex"}>
				<Flex alignItems={"center"} maxW={maxPageWidth} flex={1}>
					<Flex
						justifyContent={"flex-start"}
						flex={1}
						display={{base: "none", md: "flex"}}>
						<MenuLeft />
					</Flex>
					<Flex justifyContent={"flex-start"} flex={1}>
						<Logo horizontal={true} h={"70px"} />
					</Flex>
					<Flex
						justifyContent={"flex-end"}
						flex={1}
						display={{base: "none", md: "flex"}}>
						<Menu />
					</Flex>
					<Flex
						justifyContent={"flex-end"}
						flex={1}
						display={{base: "flex", md: "none"}}>
						<Box
							mr={2}
							onClick={() => {
								setIsOpen(!isOpen);
							}}>
							<IconButton iconName="menu" />
						</Box>
						<CartIcon />
					</Flex>
				</Flex>
				<Box
					position={"absolute"}
					top={HeaderHeight}
					w={"100%"}
					textAlign={"center"}
					bgColor={"white"}
					h={isOpen ? 3 * 40 + "px" : "0px"}
					opacity={isOpen ? "1" : "0"}
					transition={"all 0.3s ease-in-out"}
					overflow={"hidden"}>
					<Link
						to={"/products"}
						onClick={() => {
							setIsOpen(false);
						}}>
						<Box h={"40px"} pt={"6px"} borderBottom={BORDER_BLACK}>
							<Text as={"span"}>Alle Produkte</Text>
						</Box>
					</Link>
					<Link
						to={"/product/necklace"}
						onClick={() => {
							setIsOpen(false);
						}}>
						<Box h={"40px"} pt={"6px"} borderBottom={BORDER_BLACK}>
							<Text color={"darkBlue"} as={"span"}>
								Ewigkeitsketten
							</Text>
						</Box>
					</Link>
					<Link
						to={"/login"}
						onClick={() => {
							setIsOpen(false);
						}}>
						<Box h={"40px"} pt={"6px"} borderBottom={BORDER_BLACK}>
							<Text as={"span"}>Login</Text>
						</Box>
					</Link>
				</Box>
			</Flex>
		</>
	);
}
