import {
	Box,
	Flex,
	Heading,
	List,
	ListIcon,
	ListItem,
	UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import Preview from "./Preview";

export default function PreviewWrapper({
	shopData,
	loaded,
	setForcePreview,
	forcePreview,
	mobileShowPreview,
}) {
	// if (forcePreview || shopData.productSlug === "summary") {
	// 	let customShopData = {...shopData};
	// 	if (shopData.productSlug === "summary" && !forcePreview) {
	// 		customShopData.productSlug = "poster";
	// 	} else {
	// 		customShopData.productSlug = forcePreview;
	// 	}
	// 	return (
	// 		<Flex
	// 			w="100%"
	// 			h="100%"
	// 			justifyContent={"center"}
	// 			alignItems={"center"}>
	// 			<Preview
	// 				data={customShopData}
	// 				loaded={loaded}
	// 				mobileShowPreview={mobileShowPreview}
	// 			/>
	// 		</Flex>
	// 	);
	// }
	return (
		<Flex w="100%" h="100%" justifyContent={"center"} alignItems={"center"}>
			<Preview data={shopData} loaded={loaded} />
		</Flex>
	);
}
