import { Flex } from '@chakra-ui/react'
import React from 'react'

export default function FormContainer({children}) {
  return (
    <Flex flexDir={"column"} gap={5}>
        {children}
    </Flex>
  )
}
