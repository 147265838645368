import {useQuery} from "@apollo/client";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {GET_PRODUCT} from "../../queries";
import {useStore} from "../../Store";
import Loading from "../feedback/Loading";
import Page from "../layout/Page";
import {track} from "../tracking/Tracking";
import ProductPage from "./ProductPage";

export default function Product() {
	let {slug} = useParams();

	const {loading, error, data} = useQuery(GET_PRODUCT, {
		variables: {slug},
	});
	const [product, setProduct] = useState(null);
	const cart = useStore(state => state.cart);

	useEffect(() => {
		if (!product) return;
		track("view_item", cart, product, product.variants[0].id);
	}, [product]);

	useEffect(() => {
		if (!data) return;
		setProduct(data.product);
	}, [data]);

	if (loading || !product) return <Loading />;
	if (error) {
		console.eror(error.message);
		return "";
	}

	return (
		<Page
			fullsize={false}
			footer={true}
			title={product.name}
			description={product.customFields?.shortDescription}>
			<ProductPage product={product} />
		</Page>
	);
}
