import {Flex, Hide, Text} from "@chakra-ui/react";
import React from "react";
import {useState} from "react";
import {formatPrice} from "../../helpers";
import {CartOrderSummary} from "../cart/OrderSummary";
import NormalBox from "../elements/NormalBox";
import Icon from "../elements/Icon";

export default function MobileSummary({cart}) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Hide above="md">
			<NormalBox fontSize={"sm"} flexDir={"column"} bgColor={"blue.50"}>
				<Flex
					color={"blue.600"}
					alignItems={"center"}
					onClick={() => {
						setIsOpen(!isOpen);
					}}>
					<Icon iconName={"cart1"} />
					<Text mx={2}>Deine Artikel ansehen</Text>
					<Icon iconName={isOpen ? "arrowup" : "arrowdown"} />
					<Text ml={"auto"} fontWeight={"bold"}>
						{formatPrice(cart?.totalWithTax)}
					</Text>
				</Flex>
			</NormalBox>
			{isOpen && <CartOrderSummary inCheckout cart={cart} />}
		</Hide>
	);
}
