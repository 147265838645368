import React from "react";
import {Box, Heading, Text, VStack} from "@chakra-ui/react";
import Icon from "../elements/Icon";

function displayMovingWarning() {
	const today = new Date();
	const movingDate = new Date("2024-02-28");
	return today < movingDate;
}
export const NFCFeatures = () => {
	return (
		<VStack spacing={3} align="start" fontSize={"sm"} color={"blue.700"}>
			{/* If date is befor 27.02.2024, display a warning that "Ketten werden wegen eines Umzugs erst wieder ab dem 28.02.2024 versendet" */}
			<Box display={displayMovingWarning() ? "block" : "none"}>
				<Text>
					<Icon iconName={"exclamationtriangle"}>
						Ketten werden wegen eines Umzugs erst wieder ab dem
						28.02.2024 versendet!
					</Icon>
				</Text>
			</Box>
			<Box>
				<Text>
					<Icon iconName={"infinite"}>EWIGE BOTSCHAFT AM HERZEN</Icon>
				</Text>
			</Box>

			<Box>
				<Text>
					<Icon iconName={"stars"}>100% HANDGEMACHTE UNIKATE</Icon>
				</Text>
			</Box>

			<Box>
				<Text>
					<Icon iconName={"eco2"}>LIEFERANTEN AUS DEUTSCHLAND</Icon>
				</Text>
			</Box>

			<Box>
				<Text>
					<Icon iconName={"heart"}>
						PERSÖNLICHER BEZUG ZUM SCHMUCK
					</Icon>
				</Text>
			</Box>
			<Box>
				<Text>
					<Icon iconName={"shield"}>
						2 WOCHEN PROBETRAGEN - 2 JAHRE GARANTIE
					</Icon>
				</Text>
			</Box>
		</VStack>
	);
};

export const NFCFeaturesCheckout = () => {
	return (
		<>
			<Heading
				mb={7}
				as={"h2"}
				fontSize={"md"}
				fontWeight={"normal"}
				className="headingLine">
				Vorteile, wenn Du bei uns bestellst
			</Heading>
			<VStack
				py={[3, null, 0]}
				spacing={3}
				align="start"
				fontSize={"sm"}
				color={"blue.700"}>
				<Box display={displayMovingWarning() ? "block" : "none"}>
					<Text>
						<Icon iconName={"exclamationtriangle"}>UMZUG</Icon>
						<Text as={"span"} opacity={0.6}>
							Ketten werden wegen eines Umzugs erst wieder ab dem
							28.02.2024 versendet!
						</Text>
					</Text>
				</Box>
				<Box mb={2}>
					<Icon iconName={"shield"}>2 WOCHEN PROBETRAGEN</Icon>
					<Text as={"span"} opacity={0.6}>
						Du kannst alle unsere Schmuckstücke 2 Wochen testen.
						Falls sie Dir doch nicht gefallen, kannst Du sie einfach
						an uns zurückschicken.
					</Text>
				</Box>
				<Box mb={2}>
					<Icon iconName={"fastdelivery1"}>SCHNELLER VERSAND</Icon>
					<Text as={"span"} opacity={0.6}>
						Wir lassen Dich nicht lange warten. In 1-3 Tagen ist das
						Paket bei Dir!
					</Text>
				</Box>
			</VStack>
		</>
	);
};
