import React from "react";
import FormSection from "../productForms/FormSection";
import RadioButtons from "../productForms/RadioButtons";

export default function PsCertificate({
	changeOption,
	options,
	optionGroups,
	product,
	priceWithTax,
}) {
	return (
		<FormSection title={"Wie möchtest Du Dein Zertifikat erhalten?"}>
			<RadioButtons
				changeOption={changeOption}
				optionName="medium"
				options={options}
				optionGroups={optionGroups}
				product={product}
				curruntPrice={priceWithTax}
			/>
		</FormSection>
	);
}
