import colors from "../foundations/colors";

const Heading = {
	baseStyle: {
		color: colors.blue[800],
		mb: 3,
	},
};

export default Heading;
