import React from "react";
import {Box, Image, Text, useBreakpointValue} from "@chakra-ui/react";
import {boxShadow} from "../../theme/foundations/shadow";

const ImageAndText = ({switchSide, children, imageSrc, alt}) => {
	const isMobile = useBreakpointValue({base: true, md: false});

	return (
		<Box
			display="flex"
			flexDirection={
				isMobile ? "column" : switchSide ? "row-reverse" : "row"
			}>
			<Box flex="1" position={"relative"} overflow={"hidden"}>
				<Image
					alt={alt || "Symbolbild DearUniverse"}
					src={imageSrc}
					objectFit="cover"
					position={["initial", null, "absolute"]}
					width={"100%"}
					height={["200px", null, "100%"]}
					top={"50%"}
					left={"50%"}
					transform={[null, null, "translate(-50%, -50%)"]}
				/>
			</Box>
			<Box flex="1" bgColor={""}>
				<Box my={[5, null, 12]} mx={5}>
					{children}
				</Box>
			</Box>
		</Box>
	);
};

export default ImageAndText;
