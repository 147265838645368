import React from "react";
import {useState} from "react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../Store";
import {formatDates} from "../elements/DateTimePicker";
import SelectableBox from "../elements/SelectableBox";
import FormContainer from "./FormContainer";
import FormSection from "./FormSection";
import InputError from "./InputError";
import SlideSwitch from "../elements/SlideSwitch";

export default function Certificate({
	changeUserInputs,
	userInputs,
	relatedLine,
	setRelatedLine,
	relatedLineId,
	showInputErrors,
	inputErrors,
}) {
	const cart = useStore(state => state.cart);
	const navigate = useNavigate();
	const [messages, setMessages] = useState([]);

	useEffect(() => {
		let msgs = findMessagesFromCart(cart);
		if (cart && msgs.length === 0) {
			navigate("/product/message", {replace: true});
		} else {
			setMessages(msgs);
		}
	}, [cart]);

	console.log(userInputs);

	return (
		<FormContainer>
			<FormSection title={"Wähle Deine Nachricht"}>
				{messages.map((line, i) => {
					const data = JSON.parse(line.customFields.userInputs);
					const isRelatedProduct = relatedLine === line;
					function isDisabled() {
						if (!relatedLineId) return false;
						if (isRelatedProduct) return false;
						return true;
					}
					return (
						<SelectableBox
							key={"messageChooser" + line.id}
							selected={isRelatedProduct}
							select={() => {
								setRelatedLine(line);
							}}
							disabled={isDisabled()}
							label={data.message_text}
							listItems={[
								`${line.productVariant.name}`,
								`Sendedatum: ${formatDates(data.message_date)}`,
							]}></SelectableBox>
					);
				})}
			</FormSection>
			<InputError
				show={showInputErrors && inputErrors.noMessageSelected}
				text={
					"Wähle die Nachricht aus, welche Du zertifizieren willst."
				}
			/>
			<FormSection title={"Soll Deine Nachricht gedruckt werden?"}>
				<SlideSwitch
					label={"Nachrichtentext auf das Zertifikat drucken?"}
					keyName={"certificate_printMessage"}
					change={changeUserInputs}
					isChecked={userInputs.certificate_printMessage}
					last
				/>
			</FormSection>
		</FormContainer>
	);
}

export function findMessagesFromCart(cart) {
	if (!cart) return [];
	return cart.lines.filter(
		line => line.productVariant.product.slug === "message"
	);
}
