import {gql} from "@apollo/client";
import {client} from ".";
import {GET_CART} from "./queries";

export function formatPrice(price) {
	if (!price) price = 0;
	return (price / 100).toFixed(2) + "€";
}

// {group:"medium",value:"digital"}
export function findVariant(product, options) {
	if (!options || options.length === 0)
		return (
			product.variants.find(variant => {
				return variant.customFields.defaultVariant;
			}) || product.variants[0]
		);

	for (const variant of product.variants) {
		let qualifies = true;
		for (const option of options) {
			let variantOption = variant.options.find(o => {
				return o.group.code === option.group;
			});
			if (variantOption.code !== option.value) {
				qualifies = false;
				break;
			}
		}
		if (qualifies) return variant;
	}
}

export async function mutateAndGetNewCart(mutation, variables) {
	let newCart = await client.mutate({
		mutation,
		variables,
		fetchPolicy: "no-cache",
	});
	console.log(newCart);
	/* Hacky!!! Find better solution for Errors and such! */
	return newCart.data.result;
}

export const uid = function () {
	return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export function setFormValues(setValueFunction, valueObject) {
	for (const key in valueObject) {
		if (Object.hasOwnProperty.call(valueObject, key)) {
			const value = valueObject[key];
			setValueFunction(key, value);
		}
	}
}

export const productsWithPreview = [
	"starposter",
	"planetposter",
	"photoposter",
];

export const validateEmail = email => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};


export function convertISOToDatetimeLocal(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const seconds = `0${date.getSeconds()}`.slice(-2);
    // Return the local time in the format required by the datetime-local input
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}
