import {Flex, FormControl, FormLabel, Input, Textarea} from "@chakra-ui/react";
import React, {useId} from "react";

export default function FormField({
	value,
	changeUserInputs,
	title,
	help,
	hidden,
	type,
	fieldName,
	placeholder,
	required,
}) {
	const id = useId();
	return (
		<Flex flexDir={"column"} display={hidden ? "none" : "flex"}>
			<FormControl
				isRequired={required}
				isDisabled={hidden ? true : false}>
				<FormLabel htmlFor={id}>
					{title}
					{!required ? " (optional)" : ""}
				</FormLabel>

				{type === "textarea" ? (
					<Textarea
						onChange={e =>
							changeUserInputs(fieldName, e.target.value)
						} value={value}></Textarea>
				) : (
					<Input
						variant={"solid"}
						borderWidth={1}
						color={"gray.800"}
						_placeholder={{
							color: "gray.400",
						}}
						borderColor={"gray.300"}
						id={id}
						type={type || "text"}
						name={fieldName}
						placeholder={placeholder}
						aria-label={title}
						value={value}
						onChange={e =>
							changeUserInputs(fieldName, e.target.value)
						}
					/>
				)}
			</FormControl>
		</Flex>
	);
}
