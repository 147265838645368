import React from "react";
import FormSection from "../productForms/FormSection";
import RadioButtons from "../productForms/RadioButtons";

export default function PsMessage({
	changeOption,
	options,
	optionGroups,
	product,
	priceWithTax,
}) {
	return (
		<FormSection title={"Wähle Deine Variante"}>
				<RadioButtons
					changeOption={changeOption}
					optionName="zusatzleistungen"
					optionGroups={optionGroups}
					options={options}
					curruntPrice={priceWithTax}
					product={product}
				/>
			</FormSection>
	);
}
