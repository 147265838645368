import React from "react";
import {Box, Heading, Text, VStack} from "@chakra-ui/react";
import Icon from "../elements/Icon";

export const PosterFeatures = () => {
	return (
		<VStack spacing={3} align="start" fontSize={"sm"} color={"blue.700"}>
			<Box>
				<Text>
					<Icon iconName={"infinite"}>
						EINZIGARTIGES ERINNERUNGSSTÜCK
					</Icon>
				</Text>
			</Box>

			<Box>
				<Text>
					<Icon iconName={"eco1"}>
						HOCHWERTIGES UND NACHHALTIGES MATERIAL
					</Icon>
				</Text>
			</Box>

			<Box>
				<Text>
					<Icon iconName={"heart"}>IDEAL AUCH ALS GESCHENK</Icon>
				</Text>
			</Box>
			<Box>
				<Text>
					<Icon iconName={"shield"}>2 WOCHEN RÜCKGABERECHT</Icon>
				</Text>
			</Box>
		</VStack>
	);
};
