import React, {useState} from "react";
import {Box, Input, Textarea, Button, Flex, Text} from "@chakra-ui/react";
import Icon from "./Icon";
import {client} from "../..";
import {SUBMIT_PRODUCT_REVIEW} from "../../queries";

export default function ReviewPrompt({productId, onSubmit, user}) {
	const [summary, setSummary] = useState("");
	const [body, setBody] = useState("");
	const [rating, setRating] = useState(0);
	const [hoverRating, setHoverRating] = useState(0);
	const [reviewStatus, setreviewStatus] = useState(null);
	const authorName = user?.firstName + " " + user.lastName.slice(0, 1) + ".";

	const handleSubmit = () => {
		client
			.mutate({
				mutation: SUBMIT_PRODUCT_REVIEW,
				variables: {
					input: {
						productId: productId,
						summary: summary,
						body: body,
						rating: rating,
						authorName: authorName,
						authorLocation: "Deutschland",
					},
				},
			})
			.then(response => {
				setreviewStatus("success");
			})
			.catch(error => {
				setreviewStatus("error");
				console.error(error);
			});
	};

	if (reviewStatus) {
		return (
			<Flex flexDir={"column"} gap={3} ml={5}>
				<Text>
					{reviewStatus === "success"
						? "Deine Bewertung wurde eingereicht und ist in Kürze auf der Website sichtbar. Vielen Dank für Deine Feedback!"
						: "Etwas ist schief gelaufen..."}
				</Text>
				<Box>
					{reviewStatus === "error" && (
						<Button
							onClick={() => {
								setreviewStatus(null);
							}}>
							Noch einmal versuchen
						</Button>
					)}
				</Box>
			</Flex>
		);
	}

	return (
		<Flex flexDir={"column"} gap={3} ml={5}>
			<Box>
				{Array.from({length: 5}, (_, i) => (
					<Box
						key={productId + "-" + i}
						display={"inline-block"}
						color={"accent"}
						onMouseEnter={() => setHoverRating(i + 1)}
						onMouseLeave={() => setHoverRating(0)}
						onClick={() => setRating(i + 1)}>
						<Icon
							icon={"star"}
							iconStyle={
								i < (hoverRating || rating)
									? "filled"
									: undefined
							}
						/>
					</Box>
				))}
			</Box>
			<label>Name: {authorName}</label>
			<Input
				type="text"
				value={summary}
				onChange={e => setSummary(e.target.value)}
				placeholder="Zusammenfassung"
			/>
			<Textarea
				value={body}
				onChange={e => setBody(e.target.value)}
				placeholder="Bewertung"
			/>
			<Box>
				<Button onClick={handleSubmit}>Bewertung abschicken</Button>
			</Box>
		</Flex>
	);
}
