import {Box, Flex, Heading, Image, Link, Text} from "@chakra-ui/react";
import React from "react";
import {BORDER_BLACK} from "../../App";
import Logo from "./Logo";
import Icon from "../elements/Icon";
import {maxPageWidth} from "./Page";

export const Footer = () => {
	return (
		<Box as="footer" bg="#f7f7f7" py={6} px={4} borderTop={BORDER_BLACK} width={"100%"}>
			<Flex
				flexDir={["column", null, "row"]}
				textAlign={["center", null, "left"]}
				gap={[5, null, 10]}
				justifyContent={["space-between"]}
				align={["center", null, "flex-start"]}
				maxW={maxPageWidth}
				mx="auto">
				<Flex flexDir={"column"} fontSize={"sm"} flex={"1"} maxW={"350px"}>
					<Heading as={"h2"} fontSize={"lg"}>
						Das Wichtigste auf einen Blick:
					</Heading>
					<Text>
						<Text
							fontWeight={"bold"}
							mb={0}
							as={"span"}
							display={["block", null, "inline-block"]}>
							Probetragen:
						</Text>{" "}
						Probiere unsere Ewigkeitsketten 14 Tage aus. Nicht zufrieden? Geld zurück, keine
						Fragen.
					</Text>
					<Text>
						<Text
							fontWeight={"bold"}
							mb={0}
							as={"span"}
							display={["block", null, "inline-block"]}>
							2-Jahre Garantie:
						</Text>{" "}
						Sollte etwas schiefgehen, bekommst Du sofort eine neue Kette.
					</Text>
					<Text>
						<Text
							fontWeight={"bold"}
							mb={0}
							as={"span"}
							display={["block", null, "inline-block"]}>
							Versand & Kontakt:
						</Text>{" "}
						Schneller Versand und persönlicher Support. Wir sind immer für Dich da!
					</Text>
				</Flex>
				<Flex flexDir={"column"} flex={"0.5"}>
					<Heading as={"h2"} fontSize={"lg"}>
						Rechtliches
					</Heading>
					<Link
						mx={2}
						href="https://dearuniverse.de/agb"
						fontSize="sm"
						target="_blank"
						rel="noreferrer">
						AGB
					</Link>
					<Link
						mx={2}
						href="https://www.dearuniverse.de/widerrufsbelehrung"
						fontSize="sm"
						target="_blank"
						rel="noreferrer">
						Rückgabebedingungen
					</Link>
					<Link
						mx={2}
						href="https://www.dearuniverse.de/versandarten"
						fontSize="sm"
						target="_blank"
						rel="noreferrer">
						Versand
					</Link>
					<Link
						mx={2}
						href="https://dearuniverse.de/datenschutzerklaerung"
						fontSize="sm"
						target="_blank"
						rel="noreferrer">
						Datenschutzerklärung
					</Link>
					<Link
						mx={2}
						href="https://dearuniverse.de/impressum"
						fontSize="sm"
						target="_blank"
						rel="noreferrer">
						Impressum
					</Link>
					<Heading as={"h2"} fontSize={"lg"} mt={5} mb={0}>
						Shop-Rating
					</Heading>
					<Link
						ml={1}
						href="https://de.trustpilot.com/review/dearuniverse.de"
						rel="noreferrer"
						target="_blank">
						<Image mb={5} src="/images/trustpilotbadge.png" h={"80px"}></Image>
					</Link>
				</Flex>
				<Flex
					borderTop={[BORDER_BLACK, null, "none"]}
					pt={[5, null, 0]}
					flex={"0.7"}
					maxW={"450px"}
					alignItems={["center", null, "flex-end"]}
					flexDir={"column"}>
					<Box maxW={"250px"} mb={3}>
						<Logo horizontal />
					</Box>
					<Text textAlign={["center", null, "right"]}>
						Bei Fragen erreichst Du uns jederzeit per Mail:
					</Text>

					<Icon iconName={"mail2"}>
						<Link href="mailto:info@dearuniverse.de">info@dearuniverse.de</Link>
					</Icon>
				</Flex>
			</Flex>
		</Box>
	);
};

export default Footer;
