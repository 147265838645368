import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import theme from "./theme/index";
import {ChakraProvider} from "@chakra-ui/react";
import {ApolloClient, InMemoryCache, ApolloProvider, gql} from "@apollo/client";
import {PartnerStoreProvider} from "./inStore/PartnerStoreProvider";

export const client = new ApolloClient({
	uri: process.env.REACT_APP_SHOP_API,
	cache: new InMemoryCache(),
	credentials: "include",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
	<PartnerStoreProvider>
		<ApolloProvider client={client}>
			<ChakraProvider theme={theme}>
				<App />
			</ChakraProvider>
		</ApolloProvider>
	</PartnerStoreProvider>
	// </React.StrictMode>
);
