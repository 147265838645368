import React, {useState} from "react";
import IconButton from "../layout/IconButton";
import {Box} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../Store";

export default function SmallProductConfiguratorMenu({product}) {
	const [isOpen, setIsOpen] = useState(false);
	const cart = useStore(state => state.cart);

	return (
		<Box
			position={["fixed", null, "relative"]}
			top={[5, null, 0]}
			right={[5, null, 0]}
			zIndex={10}>
			<Box
				zIndex={2}
				onClick={() => {
					setIsOpen(!isOpen);
				}}>
				<IconButton iconName={isOpen ? "close" : "menu"}></IconButton>
			</Box>
			<MenuItem
				destination={"/"}
				icon={"home"}
				isOpen={isOpen}
				index={1}
			/>
			<MenuItem
				destination={"/product/" + product.slug}
				icon={"question"}
				isOpen={isOpen}
				index={2}
			/>
			<MenuItem
				number={cart?.totalQuantity || null}
				destination={"/cart"}
				icon={"cart1"}
				isOpen={isOpen}
				index={3}
			/>
		</Box>
	);
}

function MenuItem({icon, destination, isOpen, index, number}) {
	const navigate = useNavigate();
	return (
		<Box
			onClick={() => {
				if (destination) navigate(destination);
			}}
			zIndex={1}
			position={"absolute"}
			top={0}
			pointerEvents={isOpen ? "all" : "none"}
			transform={`translateY(calc(50px * ${index} * ${isOpen ? 1 : 0}))`}
			opacity={isOpen ? "1" : "0"}
			transition={"all 0.5s ease-in-out"}>
			<IconButton iconName={icon} number={number} />
		</Box>
	);
}
