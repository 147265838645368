import {
	Button,
	ButtonGroup,
	Flex,
	FormControl,
	FormLabel,
} from "@chakra-ui/react";
import React from "react";
import Icon from "./Icon";

export default function MainTextTransfomation({change, userInputs}) {
	return (
		<Flex className="group">
			<FormControl className="noStyle">
				<FormLabel>Textgröße</FormLabel>
				<ButtonGroup className="noStyle">
					<Button
						variant={"solid"}
						colorScheme={"blackAlpha"}
						onClick={() => {
							change(
								"poster_textSize",
								Math.max(userInputs.poster_textSize - 0.2, 0.5)
							);
						}}>
						<Icon iconName={"minus"} />
					</Button>
					<Button
						variant={"solid"}
						colorScheme={"blackAlpha"}
						onClick={() => {
							change(
								"poster_textSize",
								Math.min(userInputs.poster_textSize + 0.2, 20)
							);
						}}>
						<Icon iconName={"plus"} />
					</Button>
				</ButtonGroup>
			</FormControl>
			<FormControl className="noStyle">
				<FormLabel>Position</FormLabel>
				<ButtonGroup className="noStyle">
					<Button
						variant={"solid"}
						colorScheme={"blackAlpha"}
						onClick={() => {
							change(
								"poster_textPositionY",
								Math.max(
									userInputs.poster_textPositionY - 0.5,
									-8
								)
							);
						}}>
						<Icon iconName={"arrowup"} />
					</Button>
					<Button
						variant={"solid"}
						colorScheme={"blackAlpha"}
						onClick={() => {
							change(
								"poster_textPositionY",
								Math.min(
									userInputs.poster_textPositionY + 0.5,
									7
								)
							);
						}}>
						<Icon iconName={"arrowdown"} />
					</Button>
				</ButtonGroup>
			</FormControl>
		</Flex>
	);
}
