import {extendTheme} from "@chakra-ui/react";

import colors from "./foundations/colors";
import typography from "./foundations/typography";
import Button from "./components/button";
import Link from "./components/link";
import Text from "./components/text";
import Heading from "./components/heading";
import Form from "./components/form";
import Image from "./components/image";

const overrides = {
	styles: {
		body: {
			bg: "white",
			color: "blue.900",
			fontFamily: "body",
			lineHeight: "base",
		},
	},
	colors,
	...typography,
	components: {
		Button,
		Link,
		Text,
		Heading,
		Form,
		Image,
		// Other components go here
	},
};

const theme = extendTheme(overrides);

export default theme;
