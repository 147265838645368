import {createContext, useContext, useEffect, useState} from "react";

const PartnerStoreContext = createContext();

export function usePartnerStore() {
	return useContext(PartnerStoreContext);
}

export function PartnerStoreProvider({children}) {
	const [details, setDetails] = useState({});
	const [inStoreDevice, setInStoreDevice] = useState(false);
	const [active, setActive] = useState(false);

	useEffect(() => {
		const subdomain = getSubdomain();
		const localhost =
			window.location.hostname.split(".")[0] === "localhost";

		if (!localhost && (!subdomain || subdomain === "shop")) return;
		const apiUrl = `${process.env.REACT_APP_DU_API}/instore?subdomain=${subdomain}`;

		fetch(apiUrl)
			.then(response => response.json())
			.then(json => {
				setDetails(json);
				setActive(true);
			});
	}, []);

	useEffect(() => {
		console.log(details);
	}, [details]);

	useEffect(() => {
		const inStoreDeviceString = localStorage.getItem("inStoreDevice");
		setInStoreDevice(inStoreDeviceString === "true");
	}, []);

	return (
		<PartnerStoreContext.Provider value={{details, inStoreDevice, active}}>
			{children}
		</PartnerStoreContext.Provider>
	);
}

function getSubdomain() {
	const hostname = window.location.hostname;
	const parts = hostname.split(".");
	if (parts.length > 2) {
		return parts[0];
	}
	return null;
}
