import React from "react";
import {Box, Badge} from "@chakra-ui/react";

const StockIndicator = ({stockOnHand}) => {
	let status, colorScheme;
	if (stockOnHand <= 0) {
		status = "Ausverkauft ‒ Jetzt vorbestellen!";
		colorScheme = "red";
	} else if (stockOnHand <= 7) {
		status = `Nur noch ${stockOnHand} verfügbar!`;
		colorScheme = "orange";
	} else {
		status = "Verfügbar, in 1-3 Tagen bei Dir!";
		colorScheme = "green";
	}

	return (
		<Badge borderRadius="full" px="2" colorScheme={colorScheme}>
			{status}
		</Badge>
	);
};

export default StockIndicator;
