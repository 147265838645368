import {Flex} from "@chakra-ui/react";
import React from "react";
import Icon from "../elements/Icon";

export default function IconButton({iconName, number}) {
	return (
		<Flex
			w={["30px", null, "40px"]}
			h={["30px", null, "40px"]}
			borderRadius={"40px"}
			position={"relative"}
			bgColor="gray.100"
			_hover={{bgColor: "gray.200"}}
			justifyContent={"center"}
			alignItems={"center"}
			fontSize={"lg"}
			color={"black"}>
			<Icon iconName={iconName} />
			{number ? <NumberBubble number={number} color={"#0C1B3D"} /> : ""}
		</Flex>
	);
}

export function NumberBubble({number, color}) {
	return (
		<Flex
			position={"absolute"}
			fontSize={["xx-small", "x-small"]}
			fontWeight={"bold"}
			color={"white"}
			top={0}
			right={0}
			transform={"translate(40% , -40%)"}
			px={[1, null, 2]}
			py={[0.5, null, 1]}
			borderRadius={"99px"}
			bgColor={color}>
			{number}
		</Flex>
	);
}
