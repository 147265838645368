import {Button, Flex, Text} from "@chakra-ui/react";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {bgGradientSoftGreen} from "../../theme/foundations/colors";
import Icon from "../elements/Icon";

export default function ControlButtons({
	handleSubmit,
	prevStepName,
	isLoading,
	nextButtonText,
	prevButtonText,
	startOrderOnSubmit,
}) {
	const navigate = useNavigate();

	return (
		<Flex
			justifyContent={"space-between"}
			mt={8}
			mb={10}
			gap={5}
			position={"sticky"}
			bottom={"0"}
			left={"0"}
			zIndex={"2"}
			mx={[-3, null, 0]}
			bgColor={"white"}>
			<Button
				width={"max-content"}
				color={"blackAlpha.800"}
				onClick={async () => {
					navigate(
						prevStepName ? "/checkout/" + prevStepName : "/cart"
					);
				}}
				variant={"ghost"}
				leftIcon={<Icon iconName={"arrowback"} />}>
				<Text display={["none", null, "block"]} mb={0}>
					{prevButtonText || "Zurück"}
				</Text>
			</Button>
			<Button
				flex={1}
				className={"grow-on-hover"}
				type={"submit"}
				rightIcon={
					<Icon
						iconName={
							!startOrderOnSubmit ? "arrowforward" : "checkmark"
						}
						iconStyle="regular"
					/>
				}
				bg={bgGradientSoftGreen}
				color={"green.800"}
				isLoading={isLoading}>
				{nextButtonText || "Weiter"}
			</Button>
		</Flex>
	);
}
