import {Box, Flex, Text} from "@chakra-ui/react";
import React from "react";
import {useState} from "react";
import {findVariant, formatPrice} from "../../helpers";
import SelectableBox from "../elements/SelectableBox";

export default function RadioButtons({
	changeOption,
	optionName,
	label,
	options,
	optionGroups,
	curruntPrice,
	product,
}) {
	function getChoices() {
		let choices = [];
		for (const group of optionGroups) {
			if (group.code === optionName) {
				for (const option of group.options) {
					/* Make a new array of options containing the hypothetical
					option which would be added, if the user clicks the <Choice>-Component
					Then used for calculationg the Price difference of this option */
					let hypotheticalOptions = JSON.parse(
						JSON.stringify(options)
					);

					let removeIndex = hypotheticalOptions.indexOf(
						hypotheticalOptions.find(el => el.group === optionName)
					);
					hypotheticalOptions.splice(removeIndex, 1);
					hypotheticalOptions.push({
						group: optionName,
						value: option.code,
					});
					let hypotheticalVariant = findVariant(
						product,
						hypotheticalOptions
					);
					if (!hypotheticalVariant) continue;
					let priceDiff =
						hypotheticalVariant.priceWithTax - curruntPrice;
					let prefix = "";
					if (priceDiff > 0) prefix = "+";
					choices.push({
						value: option.code,
						priceDiff: prefix + formatPrice(priceDiff),
						totalPrice: formatPrice(
							hypotheticalVariant.priceWithTax
						),
						...optionData[group.code][option.code],
						descAll: optionData[group.code].descAll,
					});
				}
			}
		}
		choices = choices.sort((a, b) => a.pos - b.pos);
		return choices;
	}

	if (!optionGroups || !product) return "No options";

	return (
		<Box>
			<Text fontWeight={"bold"} mb={3}>
				{label}
			</Text>
			<Flex flexDir={"column"} gap={5}>
				{getChoices().map((choice, i) => {
					let currentOption = options.find(o => {
						return o.group === optionName;
					});
					if (!currentOption) currentOption = {};
					return (
						<Choice
							choice={choice}
							key={optionName + i}
							selected={currentOption.value === choice.value}
							select={() => {
								changeOption(optionName, choice.value);
							}}
						/>
					);
				})}
			</Flex>
		</Box>
	);
}

function Choice({choice, select, selected}) {
	const [descOpen, setDescOpen] = useState(false);
	const handleToggle = e => {
		e.preventDefault();
		setDescOpen(!descOpen);
		return false;
	};

	return (
		<SelectableBox
			selected={selected}
			select={select}
			label={choice.label}
			listItems={[...choice.desc, ...(choice.descAll || [])]}
			pt={choice.lastMinute || choice.bestseller ? 5 : 3}>
			<Flex top={"-11px"} left={"10px"} position={"absolute"} gap={2}>
				{choice.bestseller && (
					<ChoiceBadge text={"Am beliebtesten"} color={"red.400"} />
				)}
				{choice.lastMinute && (
					<ChoiceBadge text={"Last-Minute"} color={"blue.400"} />
				)}
				{choice.eco && (
					<ChoiceBadge text={"Nachhaltig"} color={"green.500"} />
				)}
			</Flex>
			{(choice.lastMinute || choice.bestseller) && (
				<Abstand height={"10px"} />
			)}

			<Text size={"sm"} fontWeight={"normal"} color={"inherit"}>
				{choice.totalPrice}
			</Text>
		</SelectableBox>
	);
}

function ChoiceBadge({text, color}) {
	return (
		<Box
			bgColor={color}
			border={"2px solid white"}
			color={"white"}
			px={2}
			fontSize={"xs"}
			borderRadius={"xl"}>
			{text}
		</Box>
	);
}

function Abstand({height}) {
	return <Box height={height}></Box>;
}

const optionData = {
	zusatzleistungen: {
		once: {
			icon: "one",
			label: "Einmalig senden",
			desc: ["Versendung 1x zum gewählten Zeitpunkt"],
			pos: 1,
		},
		send24: {
			icon: "clock1",
			label: "Einen Tag lang stündlich senden",
			desc: ["Versendung 24x, beginnend mit Deinem gewählten Zeitpunkt"],
			pos: 2,
		},
		sendyearly: {
			icon: "calendar",
			label: "Jährlich senden",
			desc: ["Versendung jedes Jahr zum gleichen Zeitpunkt"],
			pos: 3,
		},
		send24andyearly: {
			icon: "calendar",
			label: "Jedes Jahr stündlich senden",
			desc: ["Versendung jedes Jahr 24x"],
			pos: 4,
		},
		descAll: ["Dashboard inkl."],
	},
	medium: {
		print: {
			label: "A4 Print",
			icon: "receivepackage",
			desc: [
				"Innerhalb von 3-4 Werktagen bei Dir",
				"Hochwertiges Zertifikatpapier",
				"Digitales Zertifikat inklusive",
			],
		},
		digital: {
			label: "Digital per Mail",
			icon: "letter",
			desc: [
				"Sofort verfügbare Datei zum Ausdrucken",
				"Höchste Auflösung (2879 x 9829 px)",
			],
			lastMinute: true,
			eco: true,
		},
	},
	size: {
		a4: {label: "Klein", pos: 1, desc: ["DIN A4, 21x30 cm"]},
		a3: {label: "Medium", pos: 2, desc: ["DIN A3, 30x42 cm"]},
		a2: {
			label: "Groß",
			pos: 3,
			desc: ["DIN A2, 42x60 cm"],
			bestseller: true,
		},
		a1: {label: "Riesig", pos: 4, desc: ["DIN A1, 60x84 cm"]},
		digital: {
			label: "Digital per Mail",
			pos: 5,
			desc: [
				"Sofort verfügbare Datei zum Ausdrucken",
				"Höchste Auflösung: 9933 x 7016 px!",
			],
			lastMinute: true,
			eco: true,
		},
	},
};
