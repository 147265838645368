import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Heading,
	Link,
	Text,
} from "@chakra-ui/react";
import React from "react";
import {formatPrice} from "../../helpers";
import {useStore} from "../../Store";
import {bgGradient} from "../../theme/foundations/colors";
import CartProduct from "../cart/CartProduct";
import NormalBox from "../elements/NormalBox";
import Seperator from "../layout/Seperator";
import ReviewPrompt from "../elements/ReviewPrompt";
import {useState} from "react";

export default function UserOrderOverview() {
	const user = useStore(state => state.user);
	console.log(user.orders);

	function getOrders() {
		const userOrders = user.orders?.items
			.filter(order => order.state !== "AddingItems")
			.map(order => <UserOrder key={order.code} order={order} />);
		if (userOrders.length === 0)
			return "Noch keine Bestellungen erfasst. 😕";
		return userOrders;
	}

	return (
		<Flex flexDir={"column"} gap={3}>
			<Heading>Deine Bestellungen</Heading>
			{getOrders()}
		</Flex>
	);
}

const stateTranslation = {
	PaymentSettled: "Zahlung erhalten, in Bearbeitung",
	PaymentAuthorized: "Zahlung authorisiert, warte auf Zahlungseingang",
	ArrangingPayment: "Warte auf Zahlungseingang",
	PartiallyShipped: "Teilweise versendet, teilweise in Bearbeitung",
	Shipped: "Versendet",
	Delivered: "Abgeschlossen",
	Cancelled: "Storniert",
};

function UserOrder({order}) {
	const user = useStore(state => state.user);
	const [reviewOpen, setReviewOpen] = useState(false);

	return (
		<Flex bgColor={"gray.100"} flexDir={"column"} gap={2} w={"100%"}>
			<Flex
				justifyContent={"space-between"}
				p={3}
				bgColor={"blue.500"}
				borderTopRadius={"lg"}
				color={"white"}>
				<GridItem>
					Bestellung #{order.code}
					<br />
					<Text fontWeight={"bold"}>
						Status: {stateTranslation[order.state] || order.state}
					</Text>
				</GridItem>
				<GridItem textAlign={"right"}>
					{formatPrice(order.totalWithTax)}
				</GridItem>
			</Flex>
			<Flex flexDir={"column"}>
				{order.lines.map(line => {
					console.log(line);
					let processingData = JSON.parse(
						order.customFields.processingData
					)?.find(d => {
						return d.lineId === Number(line.id);
					});
					return (
						<React.Fragment key={"item" + line.id}>
							<CartProduct line={line} minimal />
							<NormalBox flexDir={"column"} m={2}>
								<Heading as={"h3"} size={"md"}>
									Lieferstatus
								</Heading>
								{getFulfillmentStatus(order, line)}
								<Seperator />
								<Heading as={"h3"} size={"md"}>
									Aktionen
								</Heading>
								<Flex gap={2} flexDir={"column"} ml={5}>
									<Link
										onClick={() => {
											setReviewOpen(!reviewOpen);
										}}>
										Bewertung verfassen
									</Link>
									{reviewOpen && (
										<ReviewPrompt
											user={user}
											productId={
												line.productVariant.product.id
											}
										/>
									)}
									{getActions(processingData, line)}
								</Flex>
							</NormalBox>
						</React.Fragment>
					);
				})}
			</Flex>
		</Flex>
	);
}

const germanStates = {
	Created: "Erstellt",
	Pending: "Ausstehend",
	Shipped: "Verschickt",
	Delivered: "Geliefert",
	Cancelled: "Abgebrochen",
	// add your own custom states here, if needed
};

export function FulfillmentStatus({fulfillment}) {
	const state = fulfillment.state;
	const stateName = germanStates[state] || "Unbekannt";

	return (
		<Flex direction="column" align="flex-start" ml={5}>
			<Text fontWeight={"bold"}>Status: {stateName}</Text>
			{fulfillment.method === "post" && (
				<Text>
					{fulfillment.trackingCode ? (
						<>
							Tracking-Code:{" "}
							<Link
								href={`https://www.dhl.de/de/privatkunden/pakete-empfangen/verfolgen.html?piececode=${fulfillment.trackingCode}`}>
								{fulfillment.trackingCode}
							</Link>
						</>
					) : (
						"Kein Tracking-Code vorhanden"
					)}
				</Text>
			)}
			{!!fulfillment.trackingCode && (
				<Box>
					<Button
						size={"sm"}
						mt={4}
						onClick={() =>
							window.open(
								`https://www.dhl.de/de/privatkunden/pakete-empfangen/verfolgen.html?piececode=${fulfillment.trackingCode}`,
								"_blank"
							)
						}>
						Sendung verfolgen
					</Button>
				</Box>
			)}
		</Flex>
	);
}

function getFulfillmentStatus(order, line) {
	// Breaks in 2.x.x
	for (const fulfillment of order.fulfillments) {
		for (const fulfillmentLine of fulfillment.lines) {
			if (line.id === fulfillmentLine.orderLineId) {
				return <FulfillmentStatus fulfillment={fulfillment} />;
			}
		}
	}
}

function getActions(processingData, line) {
	if (processingData) {
		// Poster/Certificate
		if (processingData.fileName && !processingData.code) {
			return (
				<Link
					href={
						process.env.REACT_APP_DU_API +
						"/poster/" +
						processingData.fileName
					}
					target={"_blank"}
					rel={"noreferrer"}>
					Druckdatei herunterladen
				</Link>
			);
		}
		// Message
		if (processingData.code) {
			return (
				<>
					<Text>Dein Code lautet: {processingData.code}</Text>
					<Link
						href={
							"https://live.dearuniverse.de/?code=" +
							processingData.code
						}
						target={"_blank"}
						rel={"noreferrer"}>
						Nachricht verfolgen
					</Link>
				</>
			);
		}
	}

	return "";
}
