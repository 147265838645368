import React from "react";
import {getTotalFromLines, OrderSummaryItem} from "../cart/OrderSummary";
import {Box, Text} from "@chakra-ui/react";
import {mutateAndGetNewCart, formatPrice} from "../../helpers";
import {REMOVE_COUPON, REMOVE_GIFTCARD} from "../../queries";
import {useStore} from "../../Store";
import Seperator from "../layout/Seperator";
import Icon from "./Icon";

export default function GiftCardSummary({giftcards, coupons, cart}) {
	const setCart = useStore(state => state.setCart);

	if (giftcards.length === 0 && coupons.length === 0) return "";

	async function removeGiftCard(code) {
		setCart(await mutateAndGetNewCart(REMOVE_GIFTCARD, {code}));
	}
	async function removeCoupon(code) {
		setCart(await mutateAndGetNewCart(REMOVE_COUPON, {code}));
	}

	function getSummary() {
		return (
			<React.Fragment key={"summaryCoupons"}>
				<Seperator />

				<OrderSummaryItem label={"Ersparnis"}>
					<Text color={"green.400"}>
						<strong>
							{formatPrice(
								cart.totalWithTaxAfterGiftCard -
									getTotalFromLines(cart)
							)}
						</strong>
					</Text>
				</OrderSummaryItem>
			</React.Fragment>
		);
	}

	return [
		<Seperator key={"seperatorCoupons"} />,
		...giftcards.map(giftcard => {
			console.log(giftcard);
			return (
				<OrderSummaryItem label={"Gutschein"} key={giftcard.code}>
					<Text mr={"3"}>
						{giftcard.code} ({formatPrice(giftcard.balance)})
					</Text>
					<Box
						cursor={"pointer"}
						ml={2}
						onClick={() => {
							removeGiftCard(giftcard.code);
						}}>
						<Icon iconName={"trash"} />
					</Box>
				</OrderSummaryItem>
			);
		}),
		...coupons.map(coupon => {
			return (
				<OrderSummaryItem label={"Coupon"} key={coupon}>
					<Text mr={"3"}>{coupon}</Text>
					<Box
						cursor={"pointer"}
						ml={2}
						onClick={() => {
							removeCoupon(coupon);
						}}>
						<Icon iconName={"trash"} />
					</Box>
				</OrderSummaryItem>
			);
		}),
		getSummary(),
	];
}
