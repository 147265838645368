import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import React from "react";

export default function StepsElement({steps, direction = "column"}) {
	return (
		<Flex
			gap={direction === "column" ? 5 : 10}
			flexDir={["column", null, direction]}>
			{steps.map((step, index) => {
				if (typeof step === "string") {
					step = {text: step};
				}
				return (
					<Step
						heading={step.heading}
						text={step.text}
						direction={direction}
						index={index}
						key={"keystep" + index}
					/>
				);
			})}
		</Flex>
	);
}

function Step({heading, text, direction, index}) {
	function getText(text) {
		if (heading) return text;
		// Den Text am ersten Punkt aufteilen
		const [firstSentence, ...rest] = text.split(".");

		// Überprüfen, ob es überhaupt einen Text nach dem ersten Satz gibt
		const restText = rest.join(".").trim();

		return (
			<Text as="span">
				<span style={{fontWeight: "bold"}}>{firstSentence}.</span>
				{restText && <span> {restText}</span>}
			</Text>
		);
	}

	return (
		<Flex
			gap={5}
			direction={["column", null, direction === "row" ? "column" : "row"]}
			alignItems={"center"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				fontSize={"30px"}
				borderRadius={"full"}
				bgImage={"/images/stepbg.jpg"}
				bgSize="contain"
				bgRepeat="no-repeat"
				flexShrink={0}
				w={"50px"}
				h={"50px"}>
				<Text color={"white"} mb={"3px"} fontFamily={"Merriweather"}>
					{index + 1}
				</Text>
			</Box>
			{!!heading && (
				<Heading
					mt={3}
					textAlign={"center"}
					className="decorative"
					as={"h3"}>
					{heading}
				</Heading>
			)}
			<Text maxW={"xl"}>{getText(text)}</Text>
		</Flex>
	);
}
