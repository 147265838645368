import React from "react";
import {Box, Flex, Text} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import Icon from "../elements/Icon";

export default function ReviewBadge({reviews, link, oneline}) {
	const navigate = useNavigate();
	function getAverage() {
		if (reviews.items.length === 0) return 0;
		return (
			reviews.items.reduce((sum, review) => sum + review.rating, 0) /
			reviews.items.length
		);
	}

	const ratingAverage = getAverage();

	const stars = getRatingStars(ratingAverage);
	if (reviews.items.length === 0) return "";

	return (
		<Flex
			fontSize={"sm"}
			cursor={"pointer"}
			alignItems={"center"}
			onClick={() => {
				if (typeof link === "function") {
					link();
					return;
				}
				navigate(link);
			}}>
			{stars}
			<Text as={"span"} ml={1} mb={0} opacity={0.95}>
				{ratingAverage.toFixed(1)} ({reviews.items.length} Bewertungen)
			</Text>
		</Flex>
	);
}

export function getRatingStars(rating) {
	const stars = [];
	for (let i = 1; i <= 5; i++) {
		if (i <= rating) {
			// Full star
			stars.push(<Icon icon={"star"} iconStyle="filled" key={i} />);
		} else if (i - 0.5 <= rating) {
			// Half star
			stars.push(<Icon icon={"halfstar"} key={i} />);
		} else {
			// Empty star
			stars.push(<Icon icon={"star"} key={i} />);
		}
	}
	return (
		<Flex as={"span"} display={"inline-flex"} color={"yellow.500"}>
			{stars}
		</Flex>
	);
}
