import {Flex, Heading, Text} from "@chakra-ui/react";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Logo from "../layout/Logo";
import Icon from "../elements/Icon";

export default function CheckoutHeader() {
	const location = useLocation();
	const navigate = useNavigate();

	let steps = [
		{
			heading: "Infos",
			url: "general",
			icon: "account",
		},
		{
			heading: "Versand",
			url: "shipping",
			icon: "FastDelivery1",
		},
		{
			heading: "Zahlung",
			url: "payment",
			icon: "creditcard",
		},
		{
			heading: "Fertig",
			url: "confirmation",
			icon: "checkmark",
		},
	];

	function getCurrentStep() {
		let step = steps.find(s => {
			var re = new RegExp(s.url);
			return location.pathname.match(re);
		});
		return {i: steps.indexOf(step), ...step};
	}

	return (
		<Flex
			alignItems={"center"}
			justifyContent={"center"}
			width={"100%"}
			gap={10}
			mb={5}
			flexDir={"column"}>
			<Logo alignCenter horizontal={true} />
			<Flex>
				{steps.map((step, i) => {
					let isDone = i < getCurrentStep().i;
					let stepName = getCurrentStep().url;
					return (
						<React.Fragment key={"step" + i}>
							<Text
								my={0}
								mx={3}
								fontWeight={
									step.url === stepName ? "bold" : "normal"
								}
								as={"span"}
								color={"gray.800"}
								opacity={step.url === stepName ? "1" : "0.7"}
								/* onClick={() => {
									navigate("/checkout/" + step.url);
								}}
								cursor={"pointer"} */
							>
								{step.heading}
							</Text>
							{i + 1 !== steps.length ? (
								<Icon iconName={"arrowforward"} size="xs" />
							) : (
								""
							)}
						</React.Fragment>
					);
				})}
			</Flex>
			<Heading textAlign={"center"} as={"h1"} color={"blue.900"}>
				<Icon iconName={getCurrentStep().icon}>
					{getCurrentStep().heading}
				</Icon>
			</Heading>
		</Flex>
	);
}
