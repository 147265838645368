import colors from "../foundations/colors";
const activeLabelStyles = {
	transform: "scale(0.92) translateY(-19px)",
	opacity: "1",
	color: "#000000aa",
};
const Form = {
	variants: {
		floating: {
			container: {
				_focusWithin: {
					label: {
						...activeLabelStyles,
					},
				},
				"input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
					{
						...activeLabelStyles,
					},
				label: {
					top: 0,
					left: 0,
					zIndex: 2,
					position: "absolute",
					fontSize: "14px",
					backgroundColor: "#ffffff",
					pointerEvents: "none",
					mx: 3,
					px: 1,
					my: 2,
					transformOrigin: "left top",
					opacity: "0.78",
					borderRadius: 10,
					whiteSpace: "nowrap",
				},
			},
		},
	},
};

export default Form;
