import {Box} from "@chakra-ui/react";
import React from "react";
import {Navigate} from "react-router-dom";
import {useStore} from "../../Store";
import LoginForm from "../auth/LoginForm";
import Page from "../layout/Page";

export default function Login() {
	const user = useStore(state => state.user);

	if (user) return <Navigate to={"/user"} replace />;

	return (
		<Page heading={"Login"} footer>
			<Box py={12}>
				<LoginForm />
			</Box>
		</Page>
	);
}
