import {gql} from "@apollo/client";

export const ACTIVE_ORDER = gql`
	fragment ActiveOrder on Order {
		code
		state
		customer {
			emailAddress
			firstName
			lastName
			phoneNumber
			addresses {
				fullName
			}
		}
		shippingAddress {
			fullName
			streetLine1
			streetLine2
			postalCode
			city
			countryCode
			country
		}
		billingAddress {
			fullName
			streetLine1
			streetLine2
			postalCode
			city
			countryCode
			country
		}
		shipping
		couponCodes
		shippingWithTax
		shippingLines {
			shippingMethod {
				name
				id
			}
		}
		totalQuantity
		subTotal
		totalWithTax
		totalWithTaxAfterGiftCard
		giftCardsApplied {
			code
			balance
		}
		taxSummary {
			taxTotal
		}
		customFields {
			mollieMethod
		}
		lines {
			id
			quantity
			unitPrice
			linePrice
			linePriceWithTax
			customFields {
				userInputs
				relatedLine {
					id
					customFields {
						userInputs
					}
				}
			}
			productVariant {
				product {
					slug
					name
					id
					assets {
						source
					}
					customFields {
						standardProductPage
						cartDescription
					}
				}
				options {
					group {
						code
					}
					code
					name
				}
				id
				name
				price
				priceWithTax
				stockOnHand
				assets {
					source
				}
				customFields {
					downloadable
				}
			}
			giftCardInput {
				value
				senderName
				recipientEmailAddress
				message
				deliveryDate
				templateId
			}
		}
	}
`;
