import {Box, UnorderedList, ListItem, Heading, Text} from "@chakra-ui/react";
import StepsElement from "../elements/StepsElement";
import {useRef, useState} from "react";

function StepByStepProcess() {
	const [showCover, setShowCover] = useState(true);
	const videoRef = useRef(null);

	const handleCoverClick = () => {
		setShowCover(false);
		if (videoRef.current) {
			videoRef.current.play();
		}
	};
	return (
		<>
			<Heading mb={5} fontSize={["xl", null, "2xl"]}>
				Deine ewige Nachricht - <br></br>Für immer mit Deinem
				Schmuckstück verknüpft!
			</Heading>
			<Box
				h="50vh"
				display="flex"
				alignItems="center"
				justifyContent={["center", null, "flex-start"]}
				padding="4"
				overflow="hidden"
				position="relative" // Set the position to relative so the image can be positioned absolutely within it
			>
				{showCover && (
					<img
						src="/images/videocover.jpg"
						alt="Video Cover"
						style={{
							height: "100%",
							objectFit: "cover",
							cursor: "pointer",
						}}
						onClick={handleCoverClick}
					/>
				)}
				<video
					ref={videoRef}
					style={{
						display: showCover ? "none" : "block",
						objectFit: "contain",
						maxWidth: "100%",
						maxHeight: "100%",
					}}
					controls
					src="/videos/explain.mp4"
				/>
			</Box>
			<StepsElement
				direction={"column"}
				steps={[
					"Suche eine Farbe und Form aus und bestelle Deine Ewigkeitskette. Plastikfrei und liebevoll verpackt erhältst Du Dein Schmuckstück nach 2-3 Tagen per Post.",
					"Nach Erhalt tippst Du die Kette mit Deinem Smartphone an. Dein Smartphone erkennt den enthaltenen Chip und leitet Dich auf die Website weiter, wo Du Deine ewige Nachricht verfassen kannst. Wenn Du die Kette verschenken willst, kannst Du das auch mit der beschenkten Person zusammen machen.",
					"Gestalte Deinen persönlichen Funkspruch. Du kannst einen Text, ein Bild oder ein Video im All verewigen.",
					"Dann musst Du nur noch auf “Speichern und verknüpfen” drücken. Jetzt ist die Nachricht für immer mit Deiner Kette verknüpft. Scanne die Kette, um die Reise Deines Funkspruchs anzusehen!",
				]}
			/>
		</>
	);
}

export default StepByStepProcess;
