import {Box} from "@chakra-ui/react";
import React from "react";
import AlertDialog from "./AlertDialog";

export default function ConfirmableButton({
	title,
	body,
	confirmText,
	children,
	onConfirm,
	enabled = true,
}) {
	const [isOpen, setIsOpen] = React.useState(false);

	const onOpen = () => setIsOpen(true);
	const onClose = () => setIsOpen(false);

	if (!enabled) return <Box onClick={onConfirm}>{children}</Box>;

	return (
		<>
			<Box onClick={onOpen}>{children}</Box>

			<AlertDialog
				isOpen={isOpen}
				onClose={onClose}
				onConfirm={() => {
					onClose();
					onConfirm();
				}}
				title={title}
				body={body}
				confirmText={confirmText}
			/>
		</>
	);
}
